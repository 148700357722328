import React, { ReactElement, useEffect, useState } from 'react';
import { OwnershipEntity } from '../../models';
import { apiService } from '../../services/ApiService';
import { EditOwnershipEntityForm } from './EditOwnershipEntityForm';
import { CardPage } from '../../components/CardPage';

interface EditOwnershipEntityProps {
    ownershipEntityId: number;
}

export const EditOwnershipEntity = (props: EditOwnershipEntityProps): ReactElement => {
    const [ownershipEntity, setOwnershipEntity] = useState<OwnershipEntity>();

    useEffect(() => {
        try {
            apiService.ownershipEntitySearch(props.ownershipEntityId).then((ownershipEntity) => {
                setOwnershipEntity(ownershipEntity);
            });
        } catch (e) {
            setOwnershipEntity(undefined);
        }
    }, [props.ownershipEntityId]);

    return (
        <CardPage title={'Edit Ownership Entity'}>
            {ownershipEntity && (
                <EditOwnershipEntityForm
                    ownershipId={ownershipEntity.id}
                    manufacturerCode={ownershipEntity.manufacturerCode}
                    brandAttribute={ownershipEntity.brand}
                    companyAttribute={ownershipEntity.company ? ownershipEntity.company : undefined}
                />
            )}
        </CardPage>
    );
};
