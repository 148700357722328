import React, { ReactElement, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { AttributeName } from '../../../constants/attributeNames';
import { Attribute } from '../../../models';
import { apiService } from '../../../services/ApiService';
import { Enumerated } from '../fields/Enumerated';

export interface AttributeSubgroupProps {
    hierarchy: Record<AttributeName, Attribute>;
}

export const Hierarchical = ({ hierarchy: subgroup }: AttributeSubgroupProps): ReactElement => {
    const [hierarchy, setHierarchy] = useState(subgroup);
    const department = hierarchy[AttributeName.DEPARTMENT];
    const category = hierarchy[AttributeName.CATEGORY];
    const subcategory = hierarchy[AttributeName.SUBCATEGORY];

    const { getFieldProps, setFieldValue } = useFormikContext();
    const subcatValue = getFieldProps<string>('baseCodingAttribute.subcategory').value;
    const categoryValue = getFieldProps<string>('baseCodingAttribute.category').value;
    const departmentValue = getFieldProps<string>('baseCodingAttribute.department').value;
    useEffect(() => {
        const value: [AttributeName, string] =
            subcatValue.length > 0
                ? [AttributeName.SUBCATEGORY, subcatValue]
                : categoryValue.length > 0
                ? [AttributeName.CATEGORY, categoryValue]
                : [AttributeName.DEPARTMENT, departmentValue];
        apiService.getHierarchy(...value).then((r) => {
            const newHierarchy = {
                ...hierarchy,
                [AttributeName.DEPARTMENT]: {
                    ...hierarchy[AttributeName.DEPARTMENT],
                    options: r.data.departmentOptions,
                },
                [AttributeName.CATEGORY]: {
                    ...hierarchy[AttributeName.CATEGORY],
                    options: r.data.categoryOptions,
                },
                [AttributeName.SUBCATEGORY]: {
                    ...hierarchy[AttributeName.SUBCATEGORY],
                    options: r.data.subcategoryOptions,
                },
            };
            setHierarchy(newHierarchy);
            if (r.data.departmentOptions.length === 1) {
                setFieldValue('baseCodingAttribute.department', r.data.departmentOptions[0]);
            }
            if (r.data.categoryOptions.length === 1) {
                setFieldValue('baseCodingAttribute.category', r.data.categoryOptions[0]);
            }
        });
        // We don't want to include hierarchy as a dependency because we modify it.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryValue, departmentValue, setFieldValue, subcatValue]);

    return (
        <>
            <Enumerated
                name="baseCodingAttribute.department"
                attribute={department}
                clearFunction={() => {
                    setFieldValue('baseCodingAttribute.department', '');
                    setFieldValue('baseCodingAttribute.category', '');
                    setFieldValue('baseCodingAttribute.subcategory', '');
                }}
                className={'form-spacing'}
            />
            <Enumerated
                name="baseCodingAttribute.category"
                attribute={category}
                clearFunction={() => {
                    setFieldValue('baseCodingAttribute.category', '');
                    setFieldValue('baseCodingAttribute.subcategory', '');
                }}
                className={'form-spacing'}
            />
            <Enumerated
                name="baseCodingAttribute.subcategory"
                attribute={subcategory}
                className={'form-spacing'}
            />
        </>
    );
};
