import React, { ReactElement } from 'react';
import { AttributeGroupProps } from '../../models';
import { AttributeGroupSection } from './AttributeGroupSection';
import { Enumerated, NumericButtonGroup } from './fields';

type ProductFactsGroupProps = AttributeGroupProps;

export const ProductFacts = ({
    attributes,
    className,
    groupName,
}: ProductFactsGroupProps): ReactElement => {
    if (attributes === undefined || Object.keys(attributes).length === 0) {
        return <></>;
    }

    return (
        <AttributeGroupSection className={className} groupName={groupName}>
            {attributes['alcoholByVolume'] && (
                <NumericButtonGroup
                    name={'productFacts.alcoholByVolume'}
                    attribute={attributes['alcoholByVolume']}
                    step={0.1}
                    precision={1}
                    max={100}
                    options={['VARIETY', 'UNKNOWN']}
                    symbol={'%'}
                />
            )}
            {attributes['animalType'] && (
                <Enumerated
                    name="productFacts.animalType"
                    attribute={attributes['animalType']}
                    className={'form-spacing'}
                />
            )}
            {attributes['beerType'] && (
                <Enumerated
                    name="productFacts.beerType"
                    attribute={attributes['beerType']}
                    className={'form-spacing'}
                />
            )}
            {attributes['flavor'] && (
                <Enumerated
                    name="productFacts.flavor"
                    attribute={attributes['flavor']}
                    className={'form-spacing'}
                />
            )}
            {attributes['form'] && (
                <Enumerated
                    name="productFacts.form"
                    attribute={attributes['form']}
                    className={'form-spacing'}
                />
            )}
            {attributes['functionalIngredient'] && (
                <Enumerated
                    name="productFacts.functionalIngredient"
                    attribute={attributes['functionalIngredient']}
                    className={'form-spacing'}
                />
            )}
            {attributes['packagingTypePrimary'] && (
                <Enumerated
                    name="productFacts.packagingTypePrimary"
                    attribute={attributes['packagingTypePrimary']}
                    className={'form-spacing'}
                />
            )}
            {attributes['processingTreatment'] && (
                <Enumerated
                    name="productFacts.processingTreatment"
                    attribute={attributes['processingTreatment']}
                    className={'form-spacing'}
                />
            )}
            {attributes['scent'] && (
                <Enumerated
                    name="productFacts.scent"
                    attribute={attributes['scent']}
                    className={'form-spacing'}
                />
            )}
            {attributes['spfRating'] && (
                <NumericButtonGroup
                    name={'productFacts.spfRating'}
                    attribute={attributes['spfRating']}
                    step={1}
                    precision={0}
                    options={['NONE', 'UNKNOWN']}
                    symbol={''}
                />
            )}
            {attributes['storage'] && (
                <Enumerated
                    name="productFacts.storage"
                    attribute={attributes['storage']}
                    className={'form-spacing'}
                />
            )}
        </AttributeGroupSection>
    );
};
