import React, { KeyboardEvent, PropsWithChildren, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { fontStyles, Header1, Link as AmethystLink, Palette } from '@spins/amethyst';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from '../../components';

const Title = styled.div`
    margin-bottom: 16px;
`;

const TitledCard = ({ title, children }: PropsWithChildren<{ title: string }>) => (
    <Card style={{ height: '100%', margin: '20px', minWidth: '400px' }}>
        <Title>
            <Header1 as="span">{title}</Header1>
        </Title>
        {children}
    </Card>
);

const TableofContentsCard = styled(TitledCard)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 400px;
    h1 {
        text-transform: uppercase;
    }
`;

const Nav = styled.nav`
    border-top: 1px solid #ccc;
    padding-top: 32px;
`;

const NavigationList = styled.ul`
    list-style: none;
    padding-left: 0px;
    ul {
        padding-bottom: 16px;
        padding-top: 16px;
        > li {
            font: ${fontStyles.BodyLarge};
            list-style: initial;
            a {
                font: ${fontStyles.BodyLarge};
            }
        }
    }
    a {
        text-decoration: none;
    }
`;

const InlineLabel = styled.label`
    align-items: center;
    display: flex;
    width: 68%;
    justify-content: space-between;
    & > input {
        padding: 4px;
    }
`;

const UpcError = styled.div`
    color: ${Palette.red[80].hex};
`;

const SPINS_UPC = new RegExp('^\\d{2}-\\d{5}-\\d{5}$');
export const TableOfContents = (): ReactElement => {
    const [state, updateState] = useState<{ upc: string; valid?: boolean }>({
        upc: '',
    });
    const history = useHistory();
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const upc = event.target.value;
        updateState({ upc: upc, valid: SPINS_UPC.test(upc) });
    };

    const handleKeyPress = (event: KeyboardEvent): void => {
        if (event.key === 'Enter' && state.valid) {
            history.push(`/product/${state.upc}`);
        }
    };

    return (
        <TableofContentsCard title="Table of Contents">
            <Nav>
                <InlineLabel>
                    For UPC:
                    <input
                        onChange={onInputChange}
                        type={'text'}
                        value={state.upc}
                        onKeyPress={handleKeyPress}
                    />
                </InlineLabel>
                {state?.valid && (
                    <ul>
                        <li>
                            <AmethystLink as={Link} to={`/product/${state.upc}`}>
                                Edit UPC
                            </AmethystLink>
                        </li>
                        <li>
                            <AmethystLink as={Link} to={`/product/${state.upc}/source-info`}>
                                Source Info for UPC
                            </AmethystLink>
                        </li>
                    </ul>
                )}
                {state.valid !== undefined && !state.valid && (
                    <div>
                        <UpcError>Invalid UPC entered</UpcError>
                        <div>UPCs must look like 00-00000-00000</div>
                    </div>
                )}
                <NavigationList>
                    <Header1 as="li">
                        Bulk Edit
                        <ul>
                            <li>
                                <AmethystLink as={Link} to={'bulkedit'}>
                                    Bulk Edit Products
                                </AmethystLink>
                            </li>
                            <li>
                                <AmethystLink as={Link} to={'bulkadd'}>
                                    Bulk Add UPCs
                                </AmethystLink>
                            </li>
                            <li>
                                <AmethystLink as={Link} to={'bulkeditretailer'}>
                                    Bulk Edit Retailer Info
                                </AmethystLink>
                            </li>
                        </ul>
                    </Header1>
                    <Header1 as="li">
                        Search
                        <ul>
                            <li>
                                <AmethystLink as={Link} to={'manufacturercodesearch'}>
                                    Manufacturer Code Search
                                </AmethystLink>
                            </li>
                        </ul>
                    </Header1>
                    <Header1 as="li">
                        Audit
                        <ul>
                            <li>
                                <AmethystLink as={Link} to={'audit'}>
                                    Audit Upcs
                                </AmethystLink>
                            </li>
                            <li>
                                <AmethystLink as={Link} to={'mywork'}>
                                    My Work
                                </AmethystLink>
                            </li>
                        </ul>
                    </Header1>
                    <Header1 as="li">
                        Source Priorities
                        <ul>
                            <li>
                                <AmethystLink as={Link} to={'source-priorities'}>
                                    Sources
                                </AmethystLink>
                            </li>
                        </ul>
                    </Header1>
                </NavigationList>
            </Nav>
        </TableofContentsCard>
    );
};
