import React, { ComponentProps } from 'react';
import { TableInstance } from '@spins/amethyst';
import { SnapshotQcReportDetailRecord } from '../../../../models';
import { EditUPCFilterButton } from './EditUPCFilterButton';
import { EditColumnsButton } from './EditColumnsButton';

interface ButtonRowProps {
    tableInstance: TableInstance<SnapshotQcReportDetailRecord>;
    isLoading: boolean;
}

export const SnapshotQcDetailFilterButtons = ({
    isLoading,
    tableInstance,
}: ButtonRowProps): JSX.Element => {
    return (
        <div>
            <EditUPCFilterButton disabled={isLoading} tableInstance={tableInstance} />
            <EditColumnsButton
                columns={
                    tableInstance.allColumns as ComponentProps<typeof EditColumnsButton>['columns']
                }
                visibleColumns={
                    tableInstance.visibleColumns as ComponentProps<
                        typeof EditColumnsButton
                    >['visibleColumns']
                }
                disabled={isLoading}
                tableInstance={tableInstance}
            />
        </div>
    );
};
