import * as Papa from 'papaparse';

const parseCsvFile = <T>(file: File): Promise<Papa.ParseResult<T>> => {
    return new Promise<Papa.ParseResult<T>>((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: resolve,
            error: reject,
        });
    });
};

export default parseCsvFile;
