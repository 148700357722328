import {
    CellType,
    ChevronDown,
    ChevronRight,
    Column,
    Palette,
    Row,
    SimpleTable,
    useFilters,
    useFlexLayout,
    useTable,
} from '@spins/amethyst';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useExpanded } from 'react-table';
import styled from 'styled-components/macro';
import { Campaign } from '../../services/ApiService';
import { campaignFilter } from './filters';
import { MarkedCompleteCircle } from './MyWork';

export interface SummaryTableDatum {
    description: string;
    campaign: Campaign;
    count: number;
    completed: number;
    subRows?: SummaryTableDatum[];
}

interface MyWorkSummaryTableProps {
    user: string;
    campaign?: Campaign;
    manufacturerCode?: string;
    workData: SummaryTableDatum[];
}

const CellChevronRight = styled(ChevronRight)`
    margin-top: 0.875rem;
`;

const CellChevronDown = styled(ChevronDown)`
    margin-top: 0.875rem;
`;

const TableSummaryLink = styled.span<{ isSelected: boolean }>`
    font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
    a:link {
        color: ${Palette.blue[80].hex};
    }
    a:visited {
        color: ${Palette.blue[80].hex};
    }
    a:hover {
        color: ${Palette.blue[80].hex};
    }
`;

const NavLinkRowRow = styled(NavLink)`
    margin-left: 4rem;
`;

const BufferBox = styled.div`
    width: 20px;
`;

const StyledCell = styled.div`
    display: inline-flex;
`;

const StyledCount = styled.strong`
    margin-left: 8px;
`;

const SimpleTableContainer = styled.div<{ rows: Row<SummaryTableDatum>[] }>`
    ${(props) => {
        const cellBackgrounds = props.rows.map(
            (r, idx) =>
                `tr:nth-child(${idx + 1}) { 
                background-color: ${
                    r.subRows?.length === 0 ? Palette.neutral[5].hex : Palette.neutral[0].hex
                }
                }`,
        );
        return cellBackgrounds.join(`
        `);
    }};
    h3 {
        margin-top: 0;
    }
`;

export const WorkSummary = (props: MyWorkSummaryTableProps): JSX.Element => {
    const columns: Column<SummaryTableDatum>[] = useMemo(
        () => [
            {
                id: 'expander',
                width: 0.25,
                // eslint-disable-next-line react/display-name
                Cell: (cellProps: any): any => {
                    const { row } = cellProps;
                    const { period, bucket } = row.original.campaign;
                    const spanProps = {
                        ...row.getToggleRowExpandedProps({
                            title: `${
                                row.isExpanded ? 'expanded' : 'collapsed'
                            } ${period} ${bucket}`,
                        }),
                    };

                    return row.canExpand ? (
                        <>
                            <span {...spanProps} id={`${period} ${bucket}`}>
                                {row.isExpanded ? <CellChevronDown /> : <CellChevronRight />}
                            </span>
                        </>
                    ) : null;
                },
            },
            {
                Header: 'Campaign',
                accessor: 'description',
                width: 3,
                cellType: CellType.Text,
                // eslint-disable-next-line react/prop-types,react/display-name
                Cell: ({ cell, row }) => {
                    const { period, bucket } = row.original.campaign;
                    const link = `/mywork/${props.user}/${period}/${bucket}${
                        row.subRows?.length === 0 ? `/${row.original.description}` : ``
                    }`;

                    return row.subRows?.length > 0 ? (
                        <TableSummaryLink
                            isSelected={props.campaign === cell.value && !props.manufacturerCode}
                        >
                            <NavLink to={link} title={'link'}>
                                {cell.value}
                            </NavLink>
                        </TableSummaryLink>
                    ) : (
                        <TableSummaryLink isSelected={props.manufacturerCode === cell.value}>
                            <NavLinkRowRow to={link} title={'link'}>
                                {cell.value}
                            </NavLinkRowRow>
                        </TableSummaryLink>
                    );
                },
                filter: campaignFilter,
            },
            {
                Header: '# Complete/Total',
                width: 1,
                cellType: CellType.Text,
                accessor: (cellProps) => `${cellProps.completed} / ${cellProps.count}`,
                // eslint-disable-next-line react/prop-types,react/display-name
                Cell: ({ cell }) => {
                    const isCompleted =
                        cell.row.original['completed'] === cell.row.original['count'];
                    return (
                        <StyledCell>
                            {isCompleted ? <MarkedCompleteCircle /> : <BufferBox />}
                            <StyledCount>
                                {cell.row.original['completed']} / {cell.row.original['count']}
                            </StyledCount>
                        </StyledCell>
                    );
                },
                disableFilters: true,
            },
        ],
        [props.user, props.manufacturerCode, props.campaign],
    );

    const myWorkInstance = useTable(
        {
            data: props.workData,
            columns,
            defaultColumn: {},
            noDataMessage: 'No assignments available.',
            title: 'My Work',
        },
        useFlexLayout,
        useFilters,
        useExpanded,
    );

    return (
        <SimpleTableContainer rows={myWorkInstance.rows}>
            <SimpleTable instance={myWorkInstance} />
        </SimpleTableContainer>
    );
};
