import React, { ReactElement, useCallback } from 'react';
import styled from 'styled-components/macro';
import ReactGA from 'react-ga';
import { Redirect, Switch } from 'react-router-dom';
import { Header, NotificationType, Palette } from '@spins/amethyst';
import { useAuth0 } from './auth0';
import { PrivateRoute } from './components';
import { CustomCampaignPage } from './pages/CustomCampaign/CustomCampaignPage';
import { apiService } from './services/ApiService';
import { parseProducts, parseRetailers } from './services/BulkEditCsvParser';
import {
    Audit,
    BulkEditPage,
    BulkEditToast,
    DynamicMigrationCsvProtoPage,
    EditOwnershipEntity,
    GenerateNewItemsReturnedMonthlyFileContainer,
    HomePage,
    ManufacturerCodeSearch,
    MultiEditPage,
    MyWork,
    NirvanaTestPage,
    NotFoundPage,
    ProductPage,
    RestatementQcChecks,
    SnapshotQcDetail,
    SnapshotQcSummary,
    SourceInfo,
    SourcePriorityPage,
} from './pages';

import { DynamicMigrationSimpleFormProtoPage } from './pages/DynamicMigrations/DynamicMigrationSimpleFormProtoPage';
import { HeaderPopOverMenu } from './pages/HomePage/HeaderPopoverMenu';
import { AddReleaseNote } from './pages/HomePage/ReleaseNotes/AddReleaseNote';

ReactGA.initialize(window.GA_TRACKING_ID);

export const AppHeader = (): ReactElement => {
    const auth = useAuth0();
    const handleLogout = useCallback(() => {
        auth?.logout({ returnTo: window.location.origin });
    }, [auth]);

    return (
        <Header title={'Product Intelligence'} to={'/'}>
            {auth?.user && <HeaderPopOverMenu userName={auth.user.name} logout={handleLogout} />}
        </Header>
    );
};

export const bulkEditRestatementOnSuccess = async (): Promise<BulkEditToast> => {
    const result = await apiService.restatementQcChecks();
    const message = result.succeeded ? 'Finished Running Checks' : 'Running Checks Failed';
    return {
        type: result.succeeded ? NotificationType.Info : NotificationType.Error,
        message,
    };
};

const RedirectToMyWork = (): JSX.Element => {
    // If we get here, we're authed, so assert user
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { user } = useAuth0()!;
    return <Redirect to={`/mywork/${user.nickname}`} />;
};

const AppContainer = styled.div`
    background-color: ${Palette.blue.washed.hex};
    min-height: 100vh;

    * {
        box-sizing: border-box;
    }
`;

const App = (): ReactElement => (
    <AppContainer>
        <AppHeader />
        <Switch>
            <PrivateRoute
                requiredPermissions={['edit:release_notes']}
                path="/addReleaseNote"
                exact
                render={() => {
                    ReactGA.pageview('Add Release Note');
                    return <AddReleaseNote />;
                }}
            />
            <PrivateRoute
                requiredPermissions={['edit:release_notes']}
                path="/releaseNotes/:id"
                render={({ match }: { match: { params: { id: string } } }) => {
                    ReactGA.pageview('Edit Release Note');
                    return <AddReleaseNote releaseNoteId={+match.params.id} />;
                }}
            />
            <PrivateRoute
                path="/product/:upc/source-info"
                render={({ match }: { match: { params: { upc: string } } }) => {
                    ReactGA.pageview('Product Source Info');
                    return <SourceInfo upc={match.params.upc} />;
                }}
            />
            <PrivateRoute
                path="/product/:upc"
                render={({
                    match,
                }: {
                    location: { search: string };
                    match: {
                        params: {
                            upc: string;
                        };
                    };
                }) => {
                    ReactGA.pageview('Product');
                    return <ProductPage upc={match.params.upc} />;
                }}
            />
            <PrivateRoute
                render={() => {
                    ReactGA.pageview('Bulk Edit');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putProducts.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Edit UPCs"
                            timeTracking
                        />
                    );
                }}
                path="/bulkEdit"
            />
            <PrivateRoute
                render={() => {
                    ReactGA.pageview('Bulk Add');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.addProducts.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Add UPCs"
                        />
                    );
                }}
                path="/bulkAdd"
            />
            <PrivateRoute
                render={() => {
                    ReactGA.pageview('Bulk Edit Ignore Freeze');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putProductsIgnoreFreeze.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Edit Products Ignore Frozen Flags"
                        />
                    );
                }}
                path="/bulkEditIgnoreFreeze"
            />
            <PrivateRoute
                path="/bulkEditRetailer"
                render={() => {
                    ReactGA.pageview('Bulk Edit Retailer');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putProducts.bind(apiService)}
                            parseFunction={parseRetailers}
                            headerText="Bulk Edit Retailer Info"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/bulkEditRetailerIgnoreFreeze"
                render={() => {
                    ReactGA.pageview('Bulk Edit Retailer Ignore Freeze');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putProductsIgnoreFreeze.bind(apiService)}
                            parseFunction={parseRetailers}
                            headerText="Bulk Edit Retailer Info Ignore Frozen Flags"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/bulkEditLockedOverrides"
                render={() => {
                    ReactGA.pageview('Bulk Edit Locked Overrides');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.overrideLockedProducts.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Edit Locked Overrides"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/bulkEditPlu"
                render={() => {
                    ReactGA.pageview('Bulk Edit Plu');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putPluProducts.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Edit PLU Items"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/multi-edit"
                render={() => {
                    ReactGA.pageview('Multi-Edit');
                    return <MultiEditPage />;
                }}
            />
            <PrivateRoute
                path="/migrations/simpleformproto"
                render={() => {
                    ReactGA.pageview('Dynamic Migrations Single');
                    return <DynamicMigrationSimpleFormProtoPage />;
                }}
            />
            <PrivateRoute
                path="/migrations/csvproto"
                render={() => {
                    ReactGA.pageview('Dynamic Migrations CSV');
                    return <DynamicMigrationCsvProtoPage />;
                }}
            />
            <PrivateRoute
                path="/bulkEditRestatement"
                render={() => {
                    ReactGA.pageview('Bulk Edit Restatement');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.putProductsRestatement.bind(apiService)}
                            onSuccess={bulkEditRestatementOnSuccess}
                            parseFunction={parseProducts}
                            headerText="Bulk Edit Restatement"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/bulkRemoveRestatement"
                render={() => {
                    ReactGA.pageview('Bulk Remove Restatement');
                    return (
                        <BulkEditPage
                            apiFunction={apiService.deleteProductsRestatement.bind(apiService)}
                            parseFunction={parseProducts}
                            headerText="Bulk Remove Restatement"
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/createNirFile"
                render={() => {
                    ReactGA.pageview('Create NIR File');
                    return <GenerateNewItemsReturnedMonthlyFileContainer />;
                }}
            />
            <PrivateRoute
                path="/audit"
                render={() => {
                    ReactGA.pageview('Audit');

                    return <Audit />;
                }}
            />
            <PrivateRoute path="/mywork" exact={true} component={RedirectToMyWork} />
            <PrivateRoute
                path="/mywork/:user/:period/:bucket/:manufacturerCode"
                render={({
                    match,
                }: {
                    match: {
                        params: {
                            user: string;
                            period: string;
                            bucket: string;
                            manufacturerCode: string;
                        };
                    };
                }) => {
                    ReactGA.pageview('My Work');
                    return (
                        <MyWork
                            user={match.params.user}
                            period={match.params.period}
                            bucket={match.params.bucket}
                            manufacturerCode={match.params.manufacturerCode}
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/mywork/:user/:period/:bucket"
                render={({
                    match,
                }: {
                    match: { params: { user: string; bucket: string; period: string } };
                }) => {
                    ReactGA.pageview('My Work');
                    return (
                        <MyWork
                            user={match.params.user}
                            period={match.params.period}
                            bucket={match.params.bucket}
                        />
                    );
                }}
            />
            <PrivateRoute
                path="/mywork/:user"
                render={({ match }: { match: { params: { user: string } } }) => {
                    ReactGA.pageview('My Work');
                    return <MyWork user={match.params.user} />;
                }}
            />
            <PrivateRoute
                path="/source-priorities"
                render={() => {
                    ReactGA.pageview('Source Priorities');
                    return <SourcePriorityPage />;
                }}
            />
            <PrivateRoute
                path="/"
                exact
                render={() => {
                    ReactGA.pageview('Index');
                    return <HomePage />;
                }}
            />
            <PrivateRoute
                path="/manufacturerCodeSearch"
                exact
                render={() => {
                    ReactGA.pageview('Manufacturer Code Search');
                    return <ManufacturerCodeSearch />;
                }}
            />
            <PrivateRoute
                path="/editOwnershipEntity/:ownershipEntityId"
                exact
                render={({ match }: { match: { params: { ownershipEntityId: number } } }) => {
                    ReactGA.pageview('Edit Ownership Entity');
                    return (
                        <EditOwnershipEntity ownershipEntityId={match.params.ownershipEntityId} />
                    );
                }}
            />
            <PrivateRoute
                path="/restatementQcChecks"
                exact
                render={() => {
                    ReactGA.pageview('Restatement QC Checks');
                    return <RestatementQcChecks />;
                }}
            />
            <PrivateRoute
                path="/not-authorized"
                render={() => {
                    ReactGA.pageview('Not Authorized');
                    return <div style={{ textAlign: 'center', margin: 32 }}>NOT AUTHORIZED</div>;
                }}
            />
            <PrivateRoute
                path="/snapshotQc"
                exact
                render={() => {
                    ReactGA.pageview('Snapshot QC Detail');
                    return <SnapshotQcDetail />;
                }}
            />
            <PrivateRoute
                path="/snapshotQc/summary"
                exact
                render={() => {
                    ReactGA.pageview('Snapshot QC Summary');
                    return <SnapshotQcSummary />;
                }}
            />
            <PrivateRoute
                path="/campaign"
                exact
                render={() => {
                    ReactGA.pageview('Create Custom Campaign');
                    return <CustomCampaignPage />;
                }}
            />
            <PrivateRoute path="/nirvanaTest" render={() => <NirvanaTestPage />} />
            <PrivateRoute
                path="/*"
                render={() => {
                    ReactGA.pageview('Not Found');
                    return <NotFoundPage />;
                }}
            />
        </Switch>
    </AppContainer>
);

export default App;
