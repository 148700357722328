import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider, GlobalStyles } from '@spins/amethyst';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from './auth0';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { FeatureFlagsProvider } from './featureFlags';

ReactDOM.render(
    <BrowserRouter>
        <Auth0Provider>
            <FeatureFlagsProvider>
                <ToastProvider>
                    <GlobalStyles />
                    <App />
                </ToastProvider>
            </FeatureFlagsProvider>
        </Auth0Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

serviceWorker.unregister();
