import { Button } from '@spins/amethyst';
import FileSaver from 'file-saver';
import React, { ReactElement } from 'react';
import { BulkErrorListItem, RejectedUpcLabel, SlothImg, UpcLabel } from './fields';
import InspirationalSloth from './inspirational_sloth.jpg';
import { QcAggregateResult, QcResult } from '.';

export interface ErrorProps {
    qcResults: QcResult[];
    qcAggregateResults: QcAggregateResult[];
    isFailure: boolean;
    haveWarnings: boolean;
}

export const Errors = ({
    qcResults,
    qcAggregateResults,
    isFailure,
    haveWarnings,
}: ErrorProps): ReactElement => {
    const generateCsvString = (): void => {
        let csvOutput = 'upc, message\n';
        qcResults.forEach((result) => {
            result.warningMessages.forEach((warning) => {
                csvOutput += `${result.upc},${warning}\n`;
            });
            result.errorMessages.forEach((error) => {
                csvOutput += `${result.upc},${error}\n`;
            });
        });
        const blob = new Blob([csvOutput], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, 'restatement_errors.csv');
    };
    const generateAggregateErrors = (): ReactElement => {
        return (
            <div>
                <div id={'error-heading'}>ERRORS</div>
                {qcAggregateResults.map((result: QcAggregateResult) => {
                    return (
                        <BulkErrorListItem key={`reject_${result.message}`} id={'error-message'}>
                            <strong>{result.message}</strong> - COUNT: <b>{result.count}</b>
                        </BulkErrorListItem>
                    );
                })}
                <div className={'download-btn'}>
                    <Button onClick={() => generateCsvString()}>Download Error CSV</Button>
                </div>
            </div>
        );
    };

    const generateWarningUPCSection = (): ReactElement => {
        return (
            <>
                <UpcLabel>WARNINGS:</UpcLabel>
                {qcResults.map((qcResult: QcResult) => {
                    return (
                        qcResult.warningMessages &&
                        qcResult.warningMessages.map((message: string, index: number) => {
                            return (
                                <BulkErrorListItem key={`warning_${index}`}>
                                    <strong>{qcResult.upc}</strong> - {message}
                                </BulkErrorListItem>
                            );
                        })
                    );
                })}
            </>
        );
    };

    const generateRejectedUpcs = (): ReactElement[][] | ReactElement[] => {
        return qcResults.map((qcResult: QcResult) => {
            return (
                qcResult.errorMessages &&
                qcResult.errorMessages.map((message: string, index: number) => {
                    return (
                        <BulkErrorListItem key={`reject_${index}`}>
                            <strong>{qcResult.upc}</strong> - {message}
                        </BulkErrorListItem>
                    );
                })
            );
        });
    };

    const hasAggregateErrors = qcAggregateResults.length > 0;
    if (hasAggregateErrors) {
        return (
            <div id={'aggregate-errors'}>
                {hasAggregateErrors && generateAggregateErrors()}
                <SlothImg src={InspirationalSloth} />
            </div>
        );
    } else {
        return (
            <>
                {isFailure && (
                    <>
                        <RejectedUpcLabel>REJECTED:</RejectedUpcLabel>
                        {generateRejectedUpcs()}
                    </>
                )}
                {haveWarnings && generateWarningUPCSection()}
                <SlothImg src={InspirationalSloth} />
            </>
        );
    }
};
