import { useMemo } from 'react';
import { AttributeName } from '../../constants/attributeNames';
import { Attribute, AttributeType, Product, RetailerInfo } from '../../models';
import { RetailerError } from '../../services/ApiService';

type AttributePair = [
    // This is not strictly correct, but I don't want to fight it right now
    Record<string, string>[] | undefined,
    'description' | 'message',
];

type Prev = [
    never,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    ...0[]
];

type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${'' extends P ? '' : '.'}${P}`
        : never
    : never;

type ExtractArrayKeys<T> = {
    [K in keyof T]-?: K extends string | number ? `${K}` : never;
}[keyof T];

type ExtractObjectKeys<T, D extends number> = {
    [K in keyof T]-?: K extends string | number ? Join<K, Paths<T[K], Prev[D]>> : never;
}[keyof T];

type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : /* if T extends Array then extract the properties from array type */
    T extends undefined
    ? never
    : T extends Array<infer U>
    ? ExtractArrayKeys<U>
    : // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object
    ? ExtractObjectKeys<T, D>
    : '';

type ProductFormKeys = Paths<ProductFormValues, 2>;

export const attributeLookup: Record<ProductFormKeys, AttributeName> = {
    'baseCodingAttribute.description': AttributeName.DESCRIPTION,
    'baseCodingAttribute.brand': AttributeName.BRAND,
    'baseCodingAttribute.company': AttributeName.COMPANY,
    'baseCodingAttribute.size': AttributeName.SIZE,
    'baseCodingAttribute.unitOfMeasure': AttributeName.UNIT_OF_MEASURE,
    'baseCodingAttribute.department': AttributeName.DEPARTMENT,
    'baseCodingAttribute.category': AttributeName.CATEGORY,
    'baseCodingAttribute.subcategory': AttributeName.SUBCATEGORY,
    'baseCodingAttribute.productType': AttributeName.PRODUCT_TYPE,
    'baseCodingAttribute.packCount': AttributeName.PACK_COUNT,
    'baseCodingAttribute.privateLabelProprietary': AttributeName.PRIVATE_LABEL_PROPRIETARY,
    'baseCodingAttribute.privateLabelConventional': AttributeName.PRIVATE_LABEL_CONVENTIONAL,
    'productFacts.alcoholByVolume': AttributeName.ALCOHOL_BY_VOLUME,
    'productFacts.animalType': AttributeName.ANIMAL_TYPE,
    'productFacts.beerType': AttributeName.BEER_TYPE,
    'productFacts.flavor': AttributeName.FLAVOR,
    'productFacts.form': AttributeName.FORM,
    'productFacts.functionalIngredient': AttributeName.FUNCTIONAL_INGREDIENT,
    'productFacts.packagingTypePrimary': AttributeName.PACKAGING_TYPE_PRIMARY,
    'productFacts.processingTreatment': AttributeName.PROCESSING_TREATMENT,
    'productFacts.scent': AttributeName.SCENT,
    'productFacts.spfRating': AttributeName.SPF_RATING,
    'productFacts.storage': AttributeName.STORAGE,
    'positioning.age': AttributeName.AGE,
    'positioning.eatingOccasion': AttributeName.EATING_OCCASION,
    'positioning.gender': AttributeName.GENDER,
    'positioning.international': AttributeName.INTERNATIONAL,
    'positioning.paleo': AttributeName.PALEO,
    'positioning.plantBased': AttributeName.PLANT_BASED,
    'positioning.raw': AttributeName.RAW,
    'labelClaims.alcoholFree': AttributeName.ALCOHOL_FREE,
    'labelClaims.allergenFriendly': AttributeName.ALLERGEN_FRIENDLY,
    'labelClaims.aluminumChlorohydrateFree': AttributeName.ALUMINUM_CHLOROHYDRATE_FREE,
    'labelClaims.aluminumFree': AttributeName.ALUMINUM_FREE,
    'labelClaims.ancientGrain': AttributeName.ANCIENT_GRAIN,
    'labelClaims.animalWelfare': AttributeName.ANIMAL_WELFARE,
    'labelClaims.antiAging': AttributeName.ANTI_AGING,
    'labelClaims.noAntibioticsAdded': AttributeName.NO_ANTIBIOTICS_ADDED,
    'labelClaims.antioxidant': AttributeName.ANTIOXIDANT,
    'labelClaims.cageFree': AttributeName.CAGE_FREE,
    'labelClaims.coralReefSafe': AttributeName.CORAL_REEF_SAFE,
    'labelClaims.fairTrade': AttributeName.FAIR_TRADE,
    'labelClaims.fat': AttributeName.FAT,
    'labelClaims.fluorideFree': AttributeName.FLUORIDE_FREE,
    'labelClaims.fragranceFree': AttributeName.FRAGRANCE_FREE,
    'labelClaims.freeRange': AttributeName.FREE_RANGE,
    'labelClaims.glutenFree': AttributeName.GLUTEN_FREE,
    'labelClaims.grainFree': AttributeName.GRAIN_FREE,
    'labelClaims.grassFed': AttributeName.GRASS_FED,
    'labelClaims.nitrateFree': AttributeName.NITRATE_FREE,
    'labelClaims.noAddedHormones': AttributeName.NO_ADDED_HORMONES,
    'labelClaims.nonGmo': AttributeName.NON_GMO,
    'labelClaims.organic': AttributeName.ORGANIC,
    'labelClaims.parabenFree': AttributeName.PARABEN_FREE,
    'labelClaims.propyleneGlycolFree': AttributeName.PROPYLENE_GLYCOL_FREE,
    'labelClaims.sodiumLaurylSulfateFree': AttributeName.SODIUM_LAURYL_SULFATE_FREE,
    'labelClaims.sprouted': AttributeName.SPROUTED,
    'labelClaims.vegan': AttributeName.VEGAN,
    'advancedCoding.healthFocus': AttributeName.HEALTH_FOCUS,
    'advancedCoding.positioningGroup': AttributeName.POSITIONING_GROUP,
    'advancedCoding.brandPosition': AttributeName.BRAND_POSITION,
    'customRetailers.description': AttributeName.PRIVATE_LABEL_CUSTOM_DESCRIPTION,
    'customRetailers.brand': AttributeName.PRIVATE_LABEL_CUSTOM_BRAND,
    'customRetailers.tag': AttributeName.RETAILER_TAG,
    'channelFlags.proprietaryChannel': AttributeName.PROPRIETARY_CHANNEL,
    'channelFlags.conventionalChannel': AttributeName.CONVENTIONAL_CHANNEL,
    'certifications.americanHeartAssoc': AttributeName.AMERICAN_HEART_ASSOC,
    'certifications.bCorporation': AttributeName.B_CORPORATION,
    'certifications.demeterBiodynamic': AttributeName.DEMETER_BIODYNAMIC,
    'certifications.detoxProjectGlyphosateResidueFree':
        AttributeName.DETOX_PROJECT_GLYPHOSATE_RESIDUE_FREE,
    'certifications.fairForLife': AttributeName.FAIR_FOR_LIFE,
    'certifications.fairTradeUsa': AttributeName.FAIR_TRADE_USA,
    'certifications.fairTradeAmerica': AttributeName.FAIR_TRADE_AMERICA,
    'certifications.gfco': AttributeName.GFCO,
    'certifications.greenerWorldAnimalWelfare': AttributeName.GREENER_WORLD_ANIMAL_WELFARE,
    'certifications.greenerWorldGrassfed': AttributeName.GREENER_WORLD_GRASSFED,
    'certifications.greenerWorldNonGmo': AttributeName.GREENER_WORLD_NON_GMO,
    'certifications.howgoodRating': AttributeName.HOWGOOD_RATING,
    'certifications.marineStewardshipCouncil': AttributeName.MARINE_STEWARDSHIP_COUNCIL,
    'certifications.naturalProductsAssoc': AttributeName.NATURAL_PRODUCTS_ASSOC,
    'certifications.nonGmoProjectVerified': AttributeName.NON_GMO_PROJECT_VERIFIED,
    'certifications.certifiedPaleo': AttributeName.CERTIFIED_PALEO,
    'certifications.paleoFoundationGrainFree': AttributeName.PALEO_FOUNDATION_GRAIN_FREE,
    'certifications.paleoFriendly': AttributeName.PALEO_FRIENDLY,
    'certifications.paleoKeto': AttributeName.PALEO_KETO,
    'certifications.paleoVegan': AttributeName.PALEO_VEGAN,
    'certifications.plantBasedFoodsAssociation': AttributeName.PLANT_BASED_FOODS_ASSOCIATION,
    'certifications.wholeGrainsCouncil': AttributeName.WHOLE_GRAINS_COUNCIL,
};
type CustomRetailerFormValues = Unpacked<ProductFormValues['customRetailers']>;
type CustomRetailers = NonNullable<CustomRetailerFormValues>;
const findAndUpdateRetailers = (newInfo: CustomRetailers[]): RetailerInfo[] => {
    const infos = newInfo.filter((retailer) => retailer.tag !== undefined && retailer.tag !== '');
    return infos.reduce<RetailerInfo[]>((acc, info) => {
        const infooos = [
            ...acc,
            {
                attributes: [
                    {
                        name: attributeLookup['customRetailers.brand'],
                        type: AttributeType.RETAILER,
                        description: info.brand,
                    },
                    {
                        name: attributeLookup['customRetailers.description'],
                        type: AttributeType.RETAILER,
                        description: info.description,
                    },
                    {
                        name: attributeLookup['customRetailers.tag'],
                        type: AttributeType.RETAILER,
                        description: info.tag,
                    },
                ],
            },
        ];
        return infooos as RetailerInfo[];
    }, []);
};

export const mapValuesToProduct = (
    originalProduct: Product,
    formValues: ReturnType<typeof mapProductToValues>,
): Product => {
    const { attributes, ...oldProduct } = originalProduct;
    const newProduct = { ...oldProduct, initialAttributes: attributes };
    const newAttributes: Attribute[] = Object.keys(formValues).reduce<Attribute[]>((acc, k) => {
        const key = k as keyof typeof formValues;
        const value = formValues[key];
        if (value === undefined) {
            return acc;
        }
        // Special case for the array. Could probably abstract this, but we're not gonna worry about it right now
        if (Array.isArray(value) && key === 'customRetailers') {
            const newInfos = findAndUpdateRetailers(formValues[key]!);
            originalProduct.retailer.retailerInfo = newInfos;
            return acc;
        } else if (typeof value === 'object') {
            return [
                ...acc,
                ...Object.keys(value).map((i) => ({
                    ...attributes.find(
                        (attr) => attr.name === (attributeLookup as any)[`${key}.${i}`],
                    )!,
                    description: (value as any)[i],
                })),
            ];
        } else {
            throw new Error(`Unknown attribute: ${key}`);
        }
    }, []);
    return { ...newProduct, attributes: newAttributes };
};

const getCustomRetailers = (
    retailers: Optional<RetailerError[] | RetailerInfo[]>,
    property: 'description' | 'message',
) => {
    if (property === 'message') {
        return (retailers as Optional<RetailerError[]>)?.map((r) => ({
            // Don't do this at home, kids
            description: r.attributeErrors.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_CUSTOM_DESCRIPTION,
            )?.[property],
            brand: r.attributeErrors.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_CUSTOM_BRAND,
            )?.[property],
            tag: r.attributeErrors.find((attr) => attr.name === AttributeName.RETAILER_TAG)?.[
                property
            ],
        }));
    } else {
        return (retailers as Optional<RetailerInfo[]>)?.map((r) => ({
            // Don't do this at home, kids
            description: r.attributes.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_CUSTOM_DESCRIPTION,
            )?.[property],
            brand: r.attributes.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_CUSTOM_BRAND,
            )?.[property],
            tag: r.attributes.find((attr) => attr.name === AttributeName.RETAILER_TAG)?.[property],
        }));
    }
};

type ProductFormValues = ReturnType<typeof mapProductToValues>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapProductToValues = (
    product: Optional<Product>,
    type: 'values' | 'errors' = 'values',
) => {
    const retailerAttributes =
        type === 'errors' ? product?.retailerErrors : product?.retailer.retailerInfo;
    const [attributes, property]: AttributePair = (type === 'values'
        ? [product?.attributes, 'description']
        : [product?.attributeErrors, 'message']) as AttributePair;
    return {
        baseCodingAttribute: {
            description: attributes?.find((attr) => attr.name === AttributeName.DESCRIPTION)?.[
                property
            ],
            brand: attributes?.find((attr) => attr.name === AttributeName.BRAND)?.[property],
            company: attributes?.find((attr) => attr.name === AttributeName.COMPANY)?.[property],
            size: attributes?.find((attr) => attr.name === AttributeName.SIZE)?.[property],
            unitOfMeasure: attributes?.find(
                (attr) => attr.name === AttributeName.UNIT_OF_MEASURE,
            )?.[property],
            department: attributes?.find((attr) => attr.name === AttributeName.DEPARTMENT)?.[
                property
            ],
            category: attributes?.find((attr) => attr.name === AttributeName.CATEGORY)?.[property],
            subcategory: attributes?.find((attr) => attr.name === AttributeName.SUBCATEGORY)?.[
                property
            ],
            productType: attributes?.find((attr) => attr.name === AttributeName.PRODUCT_TYPE)?.[
                property
            ],
            packCount: attributes?.find((attr) => attr.name === AttributeName.PACK_COUNT)?.[
                property
            ],
            privateLabelProprietary: attributes?.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_PROPRIETARY,
            )?.[property],
            privateLabelConventional: attributes?.find(
                (attr) => attr.name === AttributeName.PRIVATE_LABEL_CONVENTIONAL,
            )?.[property],
        },
        productFacts: {
            alcoholByVolume: attributes?.find(
                (attr) => attr.name === AttributeName.ALCOHOL_BY_VOLUME,
            )?.[property],
            animalType: attributes?.find((attr) => attr.name === AttributeName.ANIMAL_TYPE)?.[
                property
            ],
            beerType: attributes?.find((attr) => attr.name === AttributeName.BEER_TYPE)?.[property],
            flavor: attributes?.find((attr) => attr.name === AttributeName.FLAVOR)?.[property],
            form: attributes?.find((attr) => attr.name === AttributeName.FORM)?.[property],
            functionalIngredient: attributes?.find(
                (attr) => attr.name === AttributeName.FUNCTIONAL_INGREDIENT,
            )?.[property],
            packagingTypePrimary: attributes?.find(
                (attr) => attr.name === AttributeName.PACKAGING_TYPE_PRIMARY,
            )?.[property],
            processingTreatment: attributes?.find(
                (attr) => attr.name === AttributeName.PROCESSING_TREATMENT,
            )?.[property],
            scent: attributes?.find((attr) => attr.name === AttributeName.SCENT)?.[property],
            spfRating: attributes?.find((attr) => attr.name === AttributeName.SPF_RATING)?.[
                property
            ],
            storage: attributes?.find((attr) => attr.name === AttributeName.STORAGE)?.[property],
        },
        positioning: {
            age: attributes?.find((attr) => attr.name === AttributeName.AGE)?.[property],
            eatingOccasion: attributes?.find(
                (attr) => attr.name === AttributeName.EATING_OCCASION,
            )?.[property],
            gender: attributes?.find((attr) => attr.name === AttributeName.GENDER)?.[property],
            international: attributes?.find((attr) => attr.name === AttributeName.INTERNATIONAL)?.[
                property
            ],
            paleo: attributes?.find((attr) => attr.name === AttributeName.PALEO)?.[property],
            plantBased: attributes?.find((attr) => attr.name === AttributeName.PLANT_BASED)?.[
                property
            ],
            raw: attributes?.find((attr) => attr.name === AttributeName.RAW)?.[property],
        },
        labelClaims: {
            alcoholFree: attributes?.find((attr) => attr.name === AttributeName.ALCOHOL_FREE)?.[
                property
            ],
            allergenFriendly: attributes?.find(
                (attr) => attr.name === AttributeName.ALLERGEN_FRIENDLY,
            )?.[property],
            aluminumChlorohydrateFree: attributes?.find(
                (attr) => attr.name === AttributeName.ALUMINUM_CHLOROHYDRATE_FREE,
            )?.[property],
            aluminumFree: attributes?.find((attr) => attr.name === AttributeName.ALUMINUM_FREE)?.[
                property
            ],
            ancientGrain: attributes?.find((attr) => attr.name === AttributeName.ANCIENT_GRAIN)?.[
                property
            ],
            animalWelfare: attributes?.find((attr) => attr.name === AttributeName.ANIMAL_WELFARE)?.[
                property
            ],
            antiAging: attributes?.find((attr) => attr.name === AttributeName.ANTI_AGING)?.[
                property
            ],
            noAntibioticsAdded: attributes?.find(
                (attr) => attr.name === AttributeName.NO_ANTIBIOTICS_ADDED,
            )?.[property],
            antioxidant: attributes?.find((attr) => attr.name === AttributeName.ANTIOXIDANT)?.[
                property
            ],
            cageFree: attributes?.find((attr) => attr.name === AttributeName.CAGE_FREE)?.[property],
            coralReefSafe: attributes?.find(
                (attr) => attr.name === AttributeName.CORAL_REEF_SAFE,
            )?.[property],
            fairTrade: attributes?.find((attr) => attr.name === AttributeName.FAIR_TRADE)?.[
                property
            ],
            fat: attributes?.find((attr) => attr.name === AttributeName.FAT)?.[property],
            fluorideFree: attributes?.find((attr) => attr.name === AttributeName.FLUORIDE_FREE)?.[
                property
            ],
            fragranceFree: attributes?.find((attr) => attr.name === AttributeName.FRAGRANCE_FREE)?.[
                property
            ],
            freeRange: attributes?.find((attr) => attr.name === AttributeName.FREE_RANGE)?.[
                property
            ],
            glutenFree: attributes?.find((attr) => attr.name === AttributeName.GLUTEN_FREE)?.[
                property
            ],
            grainFree: attributes?.find((attr) => attr.name === AttributeName.GRAIN_FREE)?.[
                property
            ],
            grassFed: attributes?.find((attr) => attr.name === AttributeName.GRASS_FED)?.[property],
            nitrateFree: attributes?.find((attr) => attr.name === AttributeName.NITRATE_FREE)?.[
                property
            ],
            noAddedHormones: attributes?.find(
                (attr) => attr.name === AttributeName.NO_ADDED_HORMONES,
            )?.[property],
            nonGmo: attributes?.find((attr) => attr.name === AttributeName.NON_GMO)?.[property],
            organic: attributes?.find((attr) => attr.name === AttributeName.ORGANIC)?.[property],
            parabenFree: attributes?.find((attr) => attr.name === AttributeName.PARABEN_FREE)?.[
                property
            ],
            propyleneGlycolFree: attributes?.find(
                (attr) => attr.name === AttributeName.PROPYLENE_GLYCOL_FREE,
            )?.[property],
            sodiumLaurylSulfateFree: attributes?.find(
                (attr) => attr.name === AttributeName.SODIUM_LAURYL_SULFATE_FREE,
            )?.[property],
            sprouted: attributes?.find((attr) => attr.name === AttributeName.SPROUTED)?.[property],
            vegan: attributes?.find((attr) => attr.name === AttributeName.VEGAN)?.[property],
        },
        certifications: {
            americanHeartAssoc: attributes?.find(
                (attr) => attr.name === AttributeName.AMERICAN_HEART_ASSOC,
            )?.[property],
            bCorporation: attributes?.find((attr) => attr.name === AttributeName.B_CORPORATION)?.[
                property
            ],
            demeterBiodynamic: attributes?.find(
                (attr) => attr.name === AttributeName.DEMETER_BIODYNAMIC,
            )?.[property],
            detoxProjectGlyphosateResidueFree: attributes?.find(
                (attr) => attr.name === AttributeName.DETOX_PROJECT_GLYPHOSATE_RESIDUE_FREE,
            )?.[property],
            fairForLife: attributes?.find((attr) => attr.name === AttributeName.FAIR_FOR_LIFE)?.[
                property
            ],
            fairTradeUsa: attributes?.find((attr) => attr.name === AttributeName.FAIR_TRADE_USA)?.[
                property
            ],
            fairTradeAmerica: attributes?.find(
                (attr) => attr.name === AttributeName.FAIR_TRADE_AMERICA,
            )?.[property],
            gfco: attributes?.find((attr) => attr.name === AttributeName.GFCO)?.[property],
            greenerWorldAnimalWelfare: attributes?.find(
                (attr) => attr.name === AttributeName.GREENER_WORLD_ANIMAL_WELFARE,
            )?.[property],
            greenerWorldGrassfed: attributes?.find(
                (attr) => attr.name === AttributeName.GREENER_WORLD_GRASSFED,
            )?.[property],
            greenerWorldNonGmo: attributes?.find(
                (attr) => attr.name === AttributeName.GREENER_WORLD_NON_GMO,
            )?.[property],
            howgoodRating: attributes?.find((attr) => attr.name === AttributeName.HOWGOOD_RATING)?.[
                property
            ],
            marineStewardshipCouncil: attributes?.find(
                (attr) => attr.name === AttributeName.MARINE_STEWARDSHIP_COUNCIL,
            )?.[property],
            naturalProductsAssoc: attributes?.find(
                (attr) => attr.name === AttributeName.NATURAL_PRODUCTS_ASSOC,
            )?.[property],
            nonGmoProjectVerified: attributes?.find(
                (attr) => attr.name === AttributeName.NON_GMO_PROJECT_VERIFIED,
            )?.[property],
            certifiedPaleo: attributes?.find(
                (attr) => attr.name === AttributeName.CERTIFIED_PALEO,
            )?.[property],
            paleoFoundationGrainFree: attributes?.find(
                (attr) => attr.name === AttributeName.PALEO_FOUNDATION_GRAIN_FREE,
            )?.[property],
            paleoFriendly: attributes?.find((attr) => attr.name === AttributeName.PALEO_FRIENDLY)?.[
                property
            ],
            paleoKeto: attributes?.find((attr) => attr.name === AttributeName.PALEO_KETO)?.[
                property
            ],
            paleoVegan: attributes?.find((attr) => attr.name === AttributeName.PALEO_VEGAN)?.[
                property
            ],
            plantBasedFoodsAssociation: attributes?.find(
                (attr) => attr.name === AttributeName.PLANT_BASED_FOODS_ASSOCIATION,
            )?.[property],
            wholeGrainsCouncil: attributes?.find(
                (attr) => attr.name === AttributeName.WHOLE_GRAINS_COUNCIL,
            )?.[property],
        },
        advancedCoding: {
            healthFocus: attributes?.find((attr) => attr.name === AttributeName.HEALTH_FOCUS)?.[
                property
            ],
            positioningGroup: attributes?.find(
                (attr) => attr.name === AttributeName.POSITIONING_GROUP,
            )?.[property],
            brandPosition: attributes?.find((attr) => attr.name === AttributeName.BRAND_POSITION)?.[
                property
            ],
        },
        customRetailers: getCustomRetailers(retailerAttributes, property),
        channelFlags: {
            proprietaryChannel: attributes?.find(
                (attr) => attr.name === AttributeName.PROPRIETARY_CHANNEL,
            )?.[property],
            conventionalChannel: attributes?.find(
                (attr) => attr.name === AttributeName.CONVENTIONAL_CHANNEL,
            )?.[property],
        },
    };
};

export const useInitial = (
    product: Optional<Product>,
): { values: ProductFormValues; errors: ProductFormValues } => {
    const values = useMemo(() => mapProductToValues(product), [product]);
    const errors = useMemo(() => mapProductToValues(product, 'errors'), [product]);
    return { values, errors };
};
