import { Button, Input, LoadingSpinner, NotificationType, useToasts } from '@spins/amethyst';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useHistory } from 'react-router';
import * as Showdown from 'showdown';
import { apiService } from '../../../services/ApiService';
import { CardPage } from '../../../components';
import 'react-mde/lib/styles/css/react-mde-all.css';

export const AddReleaseNote = ({
    releaseNoteId,
}: {
    releaseNoteId?: number;
}): ReactElement | null => {
    const { addToast } = useToasts();
    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>('write');
    const [releaseDate, setReleaseDate] = useState(moment().format('L'));
    const [releaseNotes, setReleaseNotes] = useState<string>(
        '**TITLE AND DATE**\n- note 1\n- note 2\n- note 3',
    );
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(!!releaseNoteId);
    const history = useHistory();

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    const handleSave = async (): Promise<void> => {
        setIsSaving(true);
        let message;
        let appearance;
        try {
            if (releaseNoteId) {
                await apiService.postReleaseNote({
                    id: releaseNoteId,
                    releaseDate: releaseDate,
                    notes: releaseNotes,
                });
                message = 'Success: Release Note Updated';
            } else {
                await apiService.putReleaseNote({ releaseDate: releaseDate, notes: releaseNotes });
                message = 'Success: New Release Note Added';
            }
            appearance = NotificationType.Info;
            history.push('/');
        } catch (e) {
            message = 'Failed to Save Post';
            appearance = NotificationType.Error;
        }
        addToast(message, {
            appearance,
            autoDismiss: true,
            autoDismissTimeout: 8000,
        });
    };

    useEffect(() => {
        if (releaseNoteId) {
            apiService.getReleaseNotes().then((notes) => {
                const note = notes.find((n) => n.id === releaseNoteId);
                if (note) {
                    setReleaseNotes(note.notes);
                    setReleaseDate(note.releaseDate);
                    setIsLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <CardPage title={`${releaseNoteId ? 'Edit' : 'New'} Release Note`}>
            <Input
                name="releaseDate"
                label={'Release Date'}
                type="text"
                value={releaseDate}
                onChange={(value: string) => {
                    setReleaseDate(value);
                }}
                helpText="Enter a date (dd/mm/yyyy)"
            />
            <ReactMde
                value={releaseNotes}
                onChange={(value) => {
                    setReleaseNotes(value);
                }}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
            />
            <Button
                size={'small'}
                type="button"
                buttonType={'primary'}
                onClick={handleSave}
                disabled={isSaving}
                {...{ style: { marginTop: '8px' } }}
            >
                {releaseNoteId ? 'Save Edits' : 'Add'}
            </Button>
        </CardPage>
    );
};
