import { Dispatch, useEffect, useReducer } from 'react';
import {
    Product,
    SubcategoryAttributeConfiguration,
    PositioningConfiguration,
} from '../../../../models';
import { apiService } from '../../../../services/ApiService';

type Action<T, P = undefined> = P extends undefined ? { type: T } : { type: T; payload: P };
export type Actions =
    | Action<'setProduct', Product>
    | Action<'setSubcategoryAttributeOptions', SubcategoryAttributeConfiguration>
    | Action<'setPositioningConfiguration', PositioningConfiguration>
    | Action<'setQcSuccess', boolean>
    | Action<'productNotFound'>;

export interface ProductState {
    productNotFound?: boolean;
    isQcSuccess: boolean;
    product?: Product;
    subcategoryAttributeOptions?: SubcategoryAttributeConfiguration;
    positioningConfiguration?: PositioningConfiguration;
}

export const useProductReducer = (upc: string): [ProductState, Dispatch<Actions>] => {
    const [state, dispatch] = useReducer(
        (state: ProductState, action: Actions) => {
            switch (action.type) {
                case 'setProduct': {
                    return {
                        ...state,
                        product: action.payload,
                    };
                }
                case 'setSubcategoryAttributeOptions': {
                    return { ...state, subcategoryAttributeOptions: action.payload };
                }
                case 'setPositioningConfiguration': {
                    return { ...state, positioningConfiguration: action.payload };
                }
                case 'productNotFound': {
                    return { ...state, productNotFound: true };
                }
                case 'setQcSuccess': {
                    return { ...state, isQcSuccess: action.payload };
                }
            }
        },
        { isQcSuccess: true },
    );

    useEffect(() => {
        apiService
            .getProduct(upc)
            .then((p) => dispatch({ type: 'setProduct', payload: p }))
            .catch(() => dispatch({ type: 'productNotFound' }));
        apiService
            .getSubcategoryAttributeConfiguration()
            .then((p) => dispatch({ type: 'setSubcategoryAttributeOptions', payload: p }));
        apiService
            .getPositioningConfiguration()
            .then((p) => dispatch({ type: 'setPositioningConfiguration', payload: p }));
    }, [dispatch, upc]);

    return [state, dispatch];
};
