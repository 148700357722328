import groupBy from 'lodash/groupBy';
import { BulkEditProduct } from '../models';
import parseCsvFile from '../utilities/FileUtils';

const shouldBlankOutAttributeValue = (description: string): boolean => {
    return description !== undefined && description.trim() === '()';
};

export const parseProducts = async (file: File): Promise<BulkEditProduct[]> => {
    const parseResult = await parseCsvFile(file);

    const data = parseResult.data as { [name: string]: string }[];
    if (data.length === 0) {
        return [];
    }
    const upcColumnName = Object.keys(data[0]).find((k) => k.toLowerCase() === 'upc') || '';

    return data
        .map((row) => ({
            upc: row[upcColumnName],
            attributes: Object.entries(row)
                .map((e) => ({ name: e[0], description: e[1] }))
                .filter(
                    (attr) => attr.name.toLowerCase() !== 'upc' && attr.description.trim() !== '',
                )
                .map((attr) =>
                    shouldBlankOutAttributeValue(attr.description)
                        ? { name: attr.name, description: '' }
                        : attr,
                ),
        }))
        .filter((item) => item.upc);
};

export const parseUpcs = async (file: File): Promise<string[]> => {
    const parseResult = await parseCsvFile(file);

    const data = parseResult.data as { [name: string]: string }[];
    if (data.length === 0) {
        return [];
    }
    const upcColumnName = Object.keys(data[0]).find((k) => k.toLowerCase() === 'upc') || '';
    return data.map((row) => row[upcColumnName]?.trim()).filter((upc) => upc);
};

export const parseRetailers = async (file: File): Promise<BulkEditProduct[]> => {
    const parseResult = await parseCsvFile(file);
    const data = parseResult.data as { [name: string]: string }[];

    if (data.length === 0) {
        return [];
    }

    const upcColumnName = Object.keys(data[0]).find((k) => k.toLowerCase() === 'upc') || '';

    const groupByUpc = groupBy(data, (r) => r[upcColumnName]);
    return Object.entries(groupByUpc)
        .filter((item) => item[0])
        .map(([upc, retailerRows]) => ({
            upc,
            attributes: [],
            retailer: {
                retailerInfo: retailerRows.map((retailerRow) => ({
                    attributes: Object.entries(retailerRow)
                        .map((e) => ({ name: e[0], description: e[1] }))
                        .filter(
                            (attr) => attr.name !== upcColumnName && attr.description.trim() !== '',
                        ),
                })),
            },
        }));
};
