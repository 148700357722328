import _ from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components/macro';
import { AttributeName } from '../../../constants/attributeNames';
import { Attribute, AttributeGroupProps } from '../../../models';
import { apiService } from '../../../services/ApiService';
import { AttributeGroupSection } from '../AttributeGroupSection';
import { Enumerated, FreeText, MultiColumnRow } from '../fields';
import { Numeric } from '../fields/Numeric/Numeric';
import { Hierarchical } from './Hierarchical';
import { MfrCodeTable } from './MfrCodeTable';

type BaseCodingGroupProps = AttributeGroupProps;

const getMfrCode = (attributes: BaseCodingGroupProps['attributes']): string | undefined => {
    return Object.values(attributes).find((attr) => attr.name === AttributeName.MANUFACTURER_CODE)
        ?.description;
};

const Description = styled.section`
    display: flex;
    max-width: 100%;
`;

export const BaseCodingGroup = ({
    attributes,
    className = '',
    groupName,
}: BaseCodingGroupProps): ReactElement => {
    const [subGroup, group] = useMemo(
        () =>
            _.partition(
                attributes,
                (attribute) =>
                    attribute.name === AttributeName.DEPARTMENT ||
                    attribute.name === AttributeName.CATEGORY ||
                    attribute.name === AttributeName.SUBCATEGORY,
            ),
        [attributes],
    );
    const hierarchy: Record<AttributeName, Attribute> = React.useMemo(
        () =>
            subGroup.reduce(
                (acc, g) => ({ ...acc, [g.name]: { ...g } }),
                {} as Record<AttributeName, Attribute>,
            ),
        [subGroup],
    );
    const mfrCode = useMemo(() => {
        return getMfrCode(attributes);
    }, [attributes]);

    return (
        // TODO: These defined assertions on attributes need to be undone
        <AttributeGroupSection className={className} groupName={groupName}>
            <Description>
                <FreeText
                    name="baseCodingAttribute.description"
                    attribute={group.find((i) => i.name === AttributeName.DESCRIPTION)!}
                    className={'form-spacing'}
                    enableCounter
                />
            </Description>
            <MultiColumnRow>
                <Enumerated
                    attribute={group.find((i) => i.name === AttributeName.BRAND)!}
                    creatable={true}
                    name="baseCodingAttribute.brand"
                    className={'form-spacing'}
                    loadOptions={apiService.brandSearchV2.bind(apiService)}
                />
                <Enumerated
                    name="baseCodingAttribute.company"
                    creatable={true}
                    attribute={group.find((i) => i.name === AttributeName.COMPANY)!}
                    className={'form-spacing'}
                    loadOptions={apiService.companySearchV2.bind(apiService)}
                />
            </MultiColumnRow>
            <MfrCodeTable mfrCode={mfrCode} />
            <MultiColumnRow>
                <Numeric
                    name="baseCodingAttribute.size"
                    attribute={group.find((i) => i.name === AttributeName.SIZE)!}
                    step={0.001}
                    precision={3}
                    className={'form-spacing'}
                />
                <Enumerated
                    attribute={group.find((i) => i.name === AttributeName.UNIT_OF_MEASURE)!}
                    className={'form-spacing'}
                    name="baseCodingAttribute.unitOfMeasure"
                />
            </MultiColumnRow>
            <Hierarchical hierarchy={hierarchy} />
            {group.find((i) => i.name === AttributeName.PRODUCT_TYPE) !== undefined && (
                <Enumerated
                    name="baseCodingAttribute.productType"
                    attribute={group.find((i) => i.name === AttributeName.PRODUCT_TYPE)!}
                    className={'form-spacing'}
                />
            )}
            {group.find((i) => i.name === AttributeName.PACK_COUNT) !== undefined && (
                <Numeric
                    name="baseCodingAttribute.packCount"
                    attribute={group.find((i) => i.name === AttributeName.PACK_COUNT)!}
                    className={'form-input form-spacing'}
                />
            )}
            <MultiColumnRow>
                <Enumerated
                    name="baseCodingAttribute.privateLabelProprietary"
                    attribute={
                        group.find((i) => i.name === AttributeName.PRIVATE_LABEL_PROPRIETARY)!
                    }
                    className={'form-spacing'}
                />
                <Enumerated
                    name="baseCodingAttribute.privateLabelConventional"
                    attribute={
                        group.find((i) => i.name === AttributeName.PRIVATE_LABEL_CONVENTIONAL)!
                    }
                    className={'form-spacing'}
                />
            </MultiColumnRow>
        </AttributeGroupSection>
    );
};
