import React from 'react';
import styled from 'styled-components/macro';
import HangOn from './hang_on.gif';

const Image = styled.img`
    max-width: 100%;
    max-height: 12.5rem;
`;
export const SlothSpinner = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
SlothSpinner.defaultProps = {
    children: <Image src={HangOn} alt="sloth loading image" />,
};
