import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { TableOfContents } from './TableOfContents';
import { ReleaseNotes } from './ReleaseNotes';

const ColumnWrap = styled.div`
    display: flex;
    flex-flow: row wrap;
`;
export const HomePage = (): ReactElement => {
    return (
        <ColumnWrap>
            <TableOfContents />
            <ReleaseNotes initialCount={3} />
        </ColumnWrap>
    );
};
