import { AttributeName } from '../constants/attributeNames';
import { Attribute } from './Attribute';
import { AttributeType } from './AttributeType';

export class BasicAttribute implements Attribute {
    public readonly description?: Readonly<string>;
    public readonly name: Readonly<AttributeName>;
    public readonly type: Readonly<AttributeType>;

    public constructor(
        name: Readonly<AttributeName>,
        description: Readonly<string> | undefined,
        type: Readonly<AttributeType>,
    ) {
        this.name = name;
        this.description = description;
        this.type = type;
    }
}
