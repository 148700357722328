import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components/macro';

export const RadioButtonContainer = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
        margin-right: 8px;
    }
    margin-top: 8px;
`;

export interface RadioButtonProps {
    onChange: (value: string) => void;
    value: string;
    isSelected: boolean;
    id?: string;
}

export const RadioButton: (props: PropsWithChildren<RadioButtonProps>) => ReactElement = (
    props: PropsWithChildren<RadioButtonProps>,
): ReactElement => {
    const handleChange = (): void => props.onChange(props.value);

    return (
        <RadioButtonContainer>
            <input
                type="radio"
                value={props.value}
                id={props.id}
                checked={props.isSelected}
                onChange={handleChange}
            />
            {props.children || <span>{props.value}</span>}
        </RadioButtonContainer>
    );
};
