import { AttributeName } from '../constants/attributeNames';
import { Attribute } from './Attribute';
import { AttributeType } from './AttributeType';
import { ObjectWithAttributes } from './ObjectWithAttributes';

export class RetailerInfo extends ObjectWithAttributes {
    public constructor(attributes: Attribute[]) {
        super(attributes);
    }
}

export const blankRetailerInfo = new RetailerInfo([
    {
        name: AttributeName.PRIVATE_LABEL_CUSTOM_DESCRIPTION,
        type: AttributeType.FREE_TEXT,
        description: '',
    },
    {
        name: AttributeName.PRIVATE_LABEL_CUSTOM_BRAND,
        type: AttributeType.FREE_TEXT,
        description: '',
    },
]);
