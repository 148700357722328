import {
    Button,
    Input,
    InputValidation,
    Select,
    SelectItem,
    ValidationType,
} from '@spins/amethyst';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { CardPage, FileUpload } from '../../components';
import { parseUpcs } from '../../services/BulkEditCsvParser';
import { FileUploadControls } from '../BulkEdit/fields';

const FormLabel = styled('label')`
    display: flex;
    flex-direction: column;
`;

const FormInputRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
`;

const FormInput = styled.span`
    margin-right: 32px;
    > div > div > div {
        margin-top: 4px;
    }
`;

export const CustomCampaignPage: FC = () => {
    const [fileName, setFileName] = useState<string>('');
    const [upcs, setUpcs] = useState<string[]>([]);
    const [campaignName, setCampaignName] = useState<string>('');
    const [campaignYear, setCampaignYear] = useState<number>(new Date().getFullYear());
    const [campaignPeriod, setCampaignPeriod] = useState<SelectItem<string>>({
        label: 'P01',
        value: 'P01',
    });
    const [yearValidation, setYearValidation] = useState<InputValidation>();

    const onYearChangeHandler = useCallback((text: number) => {
        setYearValidation(
            /\d{4,}/.test(text.toString())
                ? undefined
                : { type: ValidationType.Error, message: 'Invalid year' },
        );
        setCampaignYear(text);
    }, []);

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setFileName(file.name);
        parseUpcs(file).then(setUpcs);
    }

    function handleSave() {
        console.log('Save results -- not implemented yet.');
    }

    return (
        <CardPage title={'Create Custom Campaign'}>
            <FileUploadControls>
                <form style={{ display: 'grid', gap: '11px' }} onSubmit={handleSave}>
                    <div style={{ marginBottom: '21px' }}>
                        <FormLabel>
                            Upload UPC List
                            <FileUpload
                                name={'file-upload'}
                                error={
                                    upcs && upcs.length === 0 && fileName !== ''
                                        ? 'No UPC Data Found In Selected File'
                                        : ''
                                }
                                handleFileChange={handleFileChange}
                            />
                        </FormLabel>
                    </div>
                    <FormInputRow>
                        <FormInput>
                            <Input
                                name={'campaign-name'}
                                label={'Campaign Name'}
                                type={'text'}
                                onChange={(name: string) => setCampaignName(name)}
                                value={campaignName}
                            />
                        </FormInput>
                        <FormInput>
                            <Input
                                name={'campaign-year'}
                                label={'Campaign Year'}
                                type={'number'}
                                step={'1'}
                                pattern="\d{4,}"
                                onChange={onYearChangeHandler}
                                value={campaignYear}
                                validation={yearValidation}
                            />
                        </FormInput>
                        <FormInput>
                            <Select
                                name={'campaign-period'}
                                label={'Campaign Period'}
                                onChange={(item: SelectItem<string>) => setCampaignPeriod(item)}
                                value={campaignPeriod}
                                items={[
                                    { label: 'P01', value: 'P01' },
                                    { label: 'P02', value: 'P02' },
                                    { label: 'P03', value: 'P03' },
                                    { label: 'P04', value: 'P04' },
                                    { label: 'P05', value: 'P05' },
                                    { label: 'P06', value: 'P06' },
                                    { label: 'P07', value: 'P07' },
                                    { label: 'P08', value: 'P08' },
                                    { label: 'P09', value: 'P09' },
                                    { label: 'P10', value: 'P10' },
                                    { label: 'P11', value: 'P11' },
                                    { label: 'P12', value: 'P12' },
                                    { label: 'P13', value: 'P13' },
                                ]}
                            />
                        </FormInput>
                    </FormInputRow>
                    <div>
                        <Button type={'submit'}>Create Campaign</Button>
                    </div>
                </form>
            </FileUploadControls>
        </CardPage>
    );
};
