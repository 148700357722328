import _ from 'lodash';
import { SnapshotQcReportSummaryRecord } from '../../models';
import { SnapshotQcSummaryRow } from '../../models';

export const getSummaryRows = (
    records: SnapshotQcReportSummaryRecord[],
): SnapshotQcSummaryRow[] => {
    const periodReports = _(records)
        .groupBy((r) => r.periodName)
        .map((groupItems, periodName) => ({
            reportName: 'Total Potential Errors',
            periodName,
            potentialErrors: _.sumBy(groupItems, (i) => i.failureCount),
        }))
        .value();

    const attributeReports = _(records)
        .groupBy((r) => r.periodName + r.attribute)
        .map((groupItems) => ({
            reportName: `Total Potential Errors ${groupItems[0].attribute}`,
            periodName: groupItems[0].periodName,
            potentialErrors: _.sumBy(groupItems, (i) => i.failureCount),
        }))
        .value();

    const checkTypeReports = _(records)
        .groupBy((r) => r.periodName + r.checkType)
        .map((groupItems) => ({
            reportName: `Total Potential Errors ${groupItems[0].checkType}`,
            periodName: groupItems[0].periodName,
            potentialErrors: _.sumBy(groupItems, (i) => i.failureCount),
        }))
        .value();

    const attributeCheckTypeReports = records.map((r) => ({
        reportName: `Total Potential Errors ${r.attribute}: ${r.checkType}`,
        periodName: r.periodName,
        potentialErrors: r.failureCount,
    }));

    return [
        ...periodReports,
        ...attributeReports,
        ...checkTypeReports,
        ...attributeCheckTypeReports,
    ];
};
