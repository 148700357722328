import React, { ReactElement, useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Attribute } from '../../../../models';
import { AttributeError } from '../AttributeError';
import { Input } from '../shared';
import { AttributeHoverMenu } from '../../AttributeHoverMenu';
import { notANumber, hasInvalidCharacters, ensureMax, truncateToPrecision } from './utilities';
import { FormElement } from '../../../../components';
export * from './utilities';

interface NumericProps {
    attribute: Attribute;
    step?: number;
    precision?: number;
    max?: number;
    className?: string;
    id?: string;
    showName?: boolean;
    disabled?: boolean;
}

export const sanitizeChange = (
    newValue: string,
    previousValue?: string,
    precision?: number,
    max?: number,
): string | undefined =>
    notANumber(newValue) || hasInvalidCharacters(newValue)
        ? previousValue
        : ensureMax(truncateToPrecision(newValue, precision), max);

export const Numeric = ({
    attribute,
    precision,
    step = 1,
    max,
    id,
    showName = true,
    disabled = false,
    name,
}: NumericProps & { name: string }): ReactElement => {
    const numericRef = useRef<HTMLInputElement>(null);

    const { getFieldMeta, getFieldProps, setFieldValue } = useFormikContext();
    const { onBlur, onChange, value } = getFieldProps(name);
    const sanitizeOnBlur = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.value === '') return;
            const sanitizedValue = Math.max(0, Number(e.currentTarget.value)).toFixed(precision);
            if (sanitizedValue !== value) {
                setFieldValue(name, sanitizedValue);
            }
            onBlur(e);
        },
        [name, onBlur, precision, setFieldValue, value],
    );
    const filterKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) e.preventDefault();
    }, []);

    const filterWheel = useCallback(() => {
        numericRef.current?.blur();
    }, []);

    const { error } = getFieldMeta(name!);

    return (
        <FormElement>
            {showName && (
                <AttributeHoverMenu labelName={attribute.name} description={String(value)} />
            )}
            <Input
                ref={numericRef}
                id={id}
                type="number"
                value={value}
                hasError={Boolean(error)}
                disabled={disabled}
                min={0}
                max={max}
                name={name}
                step={step}
                onBlur={sanitizeOnBlur}
                onChange={onChange}
                onKeyDown={filterKeyDown}
                onWheel={filterWheel}
            />
            <AttributeError message={error!} />
        </FormElement>
    );
};
