import React, { FC } from 'react';
import { css } from 'styled-components/macro';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PopOverMenu, Copy } from '../../../components';

export interface HeaderCopyMenuProps {
    upc: string;
    upcEan13: string;
    mfrCode: string;
}

const customStyle = css`
    top: 102px;
`;

export const HeaderCopyMenu: FC<HeaderCopyMenuProps> = ({ upc, upcEan13, mfrCode }) => {
    return (
        <div style={{ display: 'flex', height: '32px', width: '40px' }}>
            <PopOverMenu
                menuTitle={<Copy width="24px" height="24px" color={'#808080'} />}
                showChevron={false}
                customStyle={customStyle}
            >
                <CopyToClipboard text={upc}>
                    <span className={'external-link'}>Copy UPC</span>
                </CopyToClipboard>
                <CopyToClipboard text={upcEan13}>
                    <span className={'external-link'}>Copy EAN 13</span>
                </CopyToClipboard>
                <CopyToClipboard text={mfrCode}>
                    <span className={'external-link'}>Copy MFR Code</span>
                </CopyToClipboard>
            </PopOverMenu>
        </div>
    );
};
