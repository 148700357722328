import React, { FC, ReactElement, useCallback, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components/macro';
import { ChevronDown, ChevronUp, fontStyles, Palette } from '@spins/amethyst';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import tinycolor from '@ctrl/tinycolor';

export const menuTransitionDuration = 300;

export const PopoverMenuContainer = styled.div`
    margin-left: auto;
    margin-right: 14px;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const PopoverMenuButton = styled.button`
    background: transparent;
    display: flex;
    border: none;
    font: ${fontStyles.Body};
    padding: 0;
    :focus {
        outline: none !important;
    }
`;

interface PopoverMenuIconProps {
    isOpen: boolean;
}

interface PopoverMenuItemsContainerProps {
    customStyle?: FlattenSimpleInterpolation;
}

export const PopoverMenuIcon = styled.div<PopoverMenuIconProps>`
    width: 17px;
    height: 16px;
    margin-left: 8px;
`;

const boxShadowColor1 = tinycolor(Palette.neutral[100].rgb).setAlpha(0.12).toRgbString();
const boxShadowColor2 = tinycolor(Palette.neutral[100].rgb).setAlpha(0.1).toRgbString();
const backgroundColor = tinycolor(Palette.neutral[80].rgb).setAlpha(0.1).toRgbString();
export const PopoverMenuItems = styled.div<PopoverMenuItemsContainerProps>`
    font: ${fontStyles.Body};
    z-index: 10;
    background-color: ${Palette.neutral[0].hex};
    position: absolute;
    ${(props) => (props.customStyle ? props.customStyle : '')}
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 ${boxShadowColor1}, 0 0 0 1px ${boxShadowColor2};
    > div,
    a,
    span {
        text-decoration: none;
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px;
        :hover {
            background-color: ${backgroundColor};
            color: ${Palette.neutral[100].hex};
        }
        cursor: pointer;
        color: ${Palette.neutral[100].hex};
    }

    &.fade-enter {
        opacity: 0;
    }
    &.fade-enter-active {
        opacity: 1;
        transition: opacity ${menuTransitionDuration}ms;
    }
    &.fade-exit {
        opacity: 1;
    }
    &.fade-exit-active {
        opacity: 0;
        transition: opacity ${menuTransitionDuration}ms;
    }
`;

export interface PopOverMenuProps {
    menuTitle: ReactElement;
    customStyle?: FlattenSimpleInterpolation;
    showChevron?: boolean;
}

export const PopOverMenu: FC<PopOverMenuProps> = ({
    showChevron = true,
    customStyle,
    menuTitle,
    children,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const clickOutsideRef = useOnclickOutside(() => {
        setIsMenuOpen(false);
    });

    const handleMenuToggle = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);
    }, [isMenuOpen]);

    return (
        <div ref={clickOutsideRef}>
            <PopoverMenuContainer>
                <PopoverMenuButton onClick={handleMenuToggle}>
                    {menuTitle}
                    {showChevron &&
                        (isMenuOpen ? (
                            <ChevronUp height={'16px'} />
                        ) : (
                            <ChevronDown height={'16px'} />
                        ))}
                </PopoverMenuButton>
                {isMenuOpen && (
                    <CSSTransition
                        timeout={menuTransitionDuration}
                        classNames={'fade'}
                        in={isMenuOpen}
                        unmountOnExit={true}
                    >
                        <PopoverMenuItems customStyle={customStyle} onClick={handleMenuToggle}>
                            {children}
                        </PopoverMenuItems>
                    </CSSTransition>
                )}
            </PopoverMenuContainer>
        </div>
    );
};
