import React, { ComponentProps, ReactElement, useEffect, useState } from 'react';
import { StylesConfig } from 'react-select';
import SelectAsync from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import { FormElement } from '../../components';
import { AttributeHoverMenu } from '../Product/AttributeHoverMenu';
import { AttributeError, reactSelectTheme, Enumerated } from '../Product/fields';

interface SelectWithSearchProps extends ComponentProps<typeof Enumerated> {
    onChange: (value: string) => void;
    error?: string;
}

export interface Option {
    label: string;
    value: string;
}
type RequiredLoadOptions = {
    loadOptions: NonNullable<ComponentProps<typeof Enumerated>['loadOptions']>;
};

const customStyles = (hasError: boolean): StylesConfig => ({
    control: (provided) => ({
        ...provided,
        paddingLeft: 8,
        border: hasError ? '1px solid red' : provided.border,
        borderRadius: '3px',
        '&:hover': {
            borderColor: hasError ? 'red' : provided.borderColor,
        },
    }),
});

// TODO: This needs to be killed and replaced with Enumerated
export const SelectWithSearch = ({
    attribute,
    loadOptions,
    tabIndex,
    onChange,
    error,
}: Omit<SelectWithSearchProps, 'loadOptions'> & RequiredLoadOptions): ReactElement => {
    const [description, setDescription] = useState<string>(attribute.description || '');
    useEffect(() => setDescription(attribute.description || ''), [attribute]);

    return (
        <FormElement>
            <AttributeHoverMenu labelName={attribute.name} description={description || ''} />
            <SelectAsync
                value={{ value: description, label: description }}
                tabIndex={tabIndex}
                onInputChange={(value) => {
                    if (value) {
                        setDescription(value);
                    }
                }}
                isClearable={true}
                escapeClearsValue={true}
                theme={reactSelectTheme}
                styles={customStyles(!!error)}
                onBlur={() => onChange(description)}
                onChange={(event: ValueType<Option>): void =>
                    setDescription(event ? (event as Option).value : '')
                }
                loadOptions={loadOptions}
                cacheOptions
            />
            {error && <AttributeError message={error} />}
        </FormElement>
    );
};
