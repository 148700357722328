import Axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { configure } from 'axios-hooks';
import FileSaver from 'file-saver';
import moment from 'moment';
import { getCurrentAccessToken, getCurrentUserEmail } from '../auth0';
import { AttributeName } from '../constants/attributeNames';
import { UpdateType } from '../constants/UpdateType';
import { CompletionEventType } from '../constants/CompletionEventType';
import {
    Attribute,
    AttributeBase,
    AttributeResponse,
    AttributeType,
    BasicAttribute,
    BulkEditProduct,
    BulkTime,
    createProduct,
    DynamicMigration,
    EnumeratedAttribute,
    Hierarchy,
    LookupValue,
    ManufacturerBrandCategories,
    OwnershipEntity,
    PositioningConfiguration,
    Product,
    ProductHierarchyRecord,
    ProductResponse,
    ReleaseNote,
    RelevantSubcategoryAttributeValues,
    RelevantValuesConfiguration,
    Retailer,
    RetailerInfo,
    RetailerInfoResponse,
    SnapshotQcReportDetailRecord,
    SnapshotQcReportSummaryRecord,
    SourcePriority,
    SubcategoryAttributeConfiguration,
    SubcategoryConfiguration,
} from '../models';
import { NetworkError } from '../NetworkError';
import { QcBulkResult } from '../pages';
import { filterRelevantAttributes } from '../pages/Product/utils';

export type FeatureFlagName = 'GET_DATASTORE_NAMES_FROM_PI_RETAILERS_API' | 'MULTI_EDIT_PAGE';

export interface ResponseHandler<T> {
    onSuccess: (result: T) => void;
    onError: (error: Error) => void;
}

export interface ApiResult {
    succeeded: boolean;
}

export interface AssignedUpc {
    upc: string;
    completed?: Date;
}

export class ApiService {
    private axios: AxiosInstance;

    public constructor() {
        this.axios = Axios.create({
            baseURL: window.REACT_APP_CURVBALL_API_URL,
            withCredentials: false,
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
        this.axios.interceptors.request.use((config) =>
            Object.assign(config, {
                headers: {
                    Authorization: 'Bearer ' + getCurrentAccessToken(),
                    ...config.headers,
                },
            }),
        );
        configure({ axios: this.axios }); // configuration to use axiosHooks with our axios instance
    }

    private static generateAttribute(attribute: AttributeResponse): Attribute {
        const { name, description, type } = attribute;
        if (type === AttributeType.ENUMERATED) {
            return new EnumeratedAttribute(name, description, attribute.options);
        } else if (type === AttributeType.FREE_TEXT) {
            return new BasicAttribute(name, description, AttributeType.FREE_TEXT);
        } else if (type === AttributeType.NUMERIC) {
            return new BasicAttribute(name, description, AttributeType.NUMERIC);
        } else if (type === AttributeType.READ_ONLY) {
            return new BasicAttribute(name, description, AttributeType.READ_ONLY);
        } else if (type === AttributeType.DERIVED) {
            return new BasicAttribute(name, description, AttributeType.DERIVED);
        } else if (type === AttributeType.RETAILER) {
            return new BasicAttribute(name, description, AttributeType.RETAILER);
        } else if (type === AttributeType.BRAND_NAME) {
            return new BasicAttribute(name, description, AttributeType.BRAND_NAME);
        } else if (type === AttributeType.COMPANY) {
            return new BasicAttribute(name, description, AttributeType.COMPANY);
        }
        throw new Error('Unexpected Type: ' + type);
    }

    private static generateRetailerInfo(retailerInfo: RetailerInfoResponse): RetailerInfo {
        return new RetailerInfo(
            retailerInfo.attributes &&
                retailerInfo.attributes.map((attribute) => ApiService.generateAttribute(attribute)),
        );
    }

    public putProduct(product: Product): Promise<UpdateResult> {
        const currentUserEmail = getCurrentUserEmail();
        return this.axios
            .put(`/product/${product.upc}?user=${currentUserEmail}`, {
                upc: product.upc,
                attributes: product.attributes,
                retailer: product.retailer,
            })
            .then((response) => ({
                isInitial: false,
                message: response.data.message,
                qcPassed: response.data.qcPassed,
                attributeErrors: response.data.attributeErrors,
                retailerErrors: response.data.retailerErrors,
                status: response.status,
            }))
            .catch((error) => ({
                isInitial: false,
                message: error.response.data.message,
                qcPassed: false,
                status: error.response.data.status,
                attributeErrors: [],
                retailerErrors: [],
            }));
    }

    public putProductV2(
        product: Product,
        subcategoryConfiguration: SubcategoryAttributeConfiguration,
    ): Promise<UpdateResult> {
        const relevantAttributes = filterRelevantAttributes(
            product.attributes,
            subcategoryConfiguration,
        );
        const changedAttributes = relevantAttributes.filter(
            (attribute) =>
                !!product.initialAttributes.find(
                    (attr) =>
                        attr.name === attribute.name && attr.description !== attribute.description,
                ),
        );
        const updatedProduct = {
            ...product,
            attributes: changedAttributes,
        };
        return this.putProduct(updatedProduct);
    }

    public getNewItemsReturnedMonthlyFile(
        week: number,
        additionalUpcs: string[],
        isQA: boolean,
    ): Promise<ApiResult> {
        const suffix = isQA ? '-preview' : '';
        return this.axios
            .post(
                `/create-nir-file${suffix}`,
                {
                    week: week,
                    'additional-upcs': additionalUpcs,
                },
                {
                    responseType: 'blob',
                    timeout: 1800000,
                },
            )
            .then(() => ({ succeeded: true }))
            .catch(() => ({ succeeded: false }));
    }

    public deleteRetailer(upc: string, retailerTag: string): Promise<Record<string, number>> {
        if (retailerTag === undefined || retailerTag === '') {
            return Promise.resolve({});
        }
        return this.axios
            .delete(`/product/${upc}/retailer/${retailerTag}`)
            .then((response) => ({
                status: response.status,
            }))
            .catch((error) => ({
                status: error.response.status,
            }));
    }

    public deleteRetailerV2(upc: string, retailerTag: string): void {
        this.deleteRetailer(upc, retailerTag);
    }

    public getRelevantSubcategoryAttributeValues(
        subcategory: string,
    ): Promise<RelevantSubcategoryAttributeValues> {
        return this.axios
            .get('/subcategory-attribute-relevant-values', {
                params: {
                    subcategory,
                },
            })
            .then(
                (response: AxiosResponse<RelevantSubcategoryAttributeValues>) =>
                    new Map(Object.entries(response.data)),
            );
    }

    public getRelevantSubcategoryAttributeValuesV2(
        subcategoryValue: string,
        { onSuccess }: ResponseHandler<RelevantValuesConfiguration>,
    ): void {
        this.getRelevantSubcategoryAttributeValues(subcategoryValue).then((result) =>
            onSuccess({ relevantValuesConfiguration: result }),
        );
    }

    public getSubcategoryAttributeConfiguration(): Promise<SubcategoryAttributeConfiguration> {
        return this.axios
            .get('/subcategory-attribute-configuration')
            .then(
                (response: AxiosResponse<SubcategoryAttributeConfiguration>) =>
                    new Map(Object.entries(response.data)),
            );
    }

    public getSubcategoryAttributeConfigurationV2({
        onSuccess,
    }: ResponseHandler<SubcategoryConfiguration>): void {
        this.getSubcategoryAttributeConfiguration().then((result) => {
            onSuccess({ subcategoryConfiguration: result });
        });
    }

    public brandSearch(searchtext: string): Promise<string[]> {
        return this.axios
            .get('/search/brand', { params: { searchtext } })
            .then((response: AxiosResponse<string[]>) => response.data);
    }

    public brandSearchV2(value: string): Promise<OptionPair[]> {
        return this.brandSearch(value).then((options) =>
            options.map((option) => ({ value: option, label: option })),
        );
    }

    public companySearch(searchtext: string): Promise<string[]> {
        return this.axios
            .get('/search/company', { params: { searchtext } })
            .then((response: AxiosResponse<string[]>) => response.data);
    }

    public companySearchV2(value: string): Promise<OptionPair[]> {
        return this.companySearch(value).then((options) =>
            options.map((option) => ({ value: option, label: option })),
        );
    }

    public manufacturerCodeSearch(manufacturerCode: string): Promise<ManufacturerBrandCategories> {
        return this.axios
            .get('/search/manufacturerCodeSearch', { params: { manufacturerCode } })
            .then((response: AxiosResponse<ManufacturerBrandCategories>) => response.data);
    }

    public ownershipEntitySearch(ownershipEntityId: number): Promise<OwnershipEntity> {
        return this.axios
            .get('/search/ownershipEntitySearch', { params: { ownershipEntityId } })
            .then((response: AxiosResponse<OwnershipEntity>) => response.data);
    }

    public restatementQcChecks(): Promise<ApiResult> {
        return this.axios
            .post('/run-restatement-qc-checks')
            .then(() => ({ succeeded: true }))
            .catch(() => ({ succeeded: false }));
    }

    public getHierarchy(
        attributeName: AttributeName,
        description: string,
    ): AxiosPromise<Hierarchy> {
        return this.axios.get('/hierarchy', { params: { description, attributeName } });
    }

    public getProduct(upcQs: string): Promise<Product> {
        return this.axios.get<ProductResponse>(`/product/${upcQs}`).then<Product>(
            (response: AxiosResponse<ProductResponse>) => {
                const {
                    upc,
                    attributes,
                    attributeErrors,
                    retailer,
                    retailerErrors,
                    isFrozen,
                } = response.data;
                return createProduct(
                    upc,
                    attributes.map((attribute: AttributeResponse) =>
                        ApiService.generateAttribute(attribute),
                    ),
                    new Retailer(
                        retailer.retailerInfo.map((retailerInfo) =>
                            ApiService.generateRetailerInfo(retailerInfo),
                        ),
                        retailer.retailerTags,
                    ),
                    attributeErrors,
                    retailerErrors,
                    isFrozen,
                );
            },
            (error) => {
                throw new NetworkError(error);
            },
        );
    }

    public async overrideLockedProducts(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const result = await this.axios.post(
            `/products/bulk/lockedExceptions?user=${getCurrentUserEmail()}`,
            products,
        );
        return result.data;
    }

    public async putProducts(
        products: BulkEditProduct[],
        estimatedTime?: number,
        updateType?: UpdateType,
    ): Promise<QcBulkResult> {
        const result = await this.axios.put(`/products/bulk`, products, {
            params: {
                user: getCurrentUserEmail(),
                estimatedTime,
                updateType,
            },
        });
        return result.data;
    }

    public async putProductsIgnoreFreeze(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const result = await this.axios.put(
            `/products/bulk/ignoreFreezes?user=${getCurrentUserEmail()}`,
            products,
        );
        return result.data;
    }

    public async putPluProducts(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const result = await this.axios.put(
            `/products/bulk/plu?user=${getCurrentUserEmail()}`,
            products,
        );
        return result.data;
    }

    public async addProducts(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const result = await this.axios.post(
            `/products/bulk/add?user=${getCurrentUserEmail()}`,
            products,
        );
        return result.data;
    }

    public async putProductsRestatement(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const result = await this.axios.put(`/products/bulk/restatement`, products);
        return result.data;
    }

    public async deleteProductsRestatement(products: BulkEditProduct[]): Promise<QcBulkResult> {
        const upcs = products.map((product) => product.upc);
        const result = await this.axios.delete(`/products/bulk/restatement`, {
            data: upcs,
        });
        return result.data;
    }

    public async getProductHierarchy(): Promise<ProductHierarchyRecord[]> {
        const response = await this.axios.get<ProductHierarchyRecord[]>('/product-hierarchy');
        return response.data;
    }

    public async getLookupValues(): Promise<LookupValue[]> {
        const response = await this.axios.get<LookupValue[]>('/lookup-values');
        return response.data;
    }

    public async addDynamicMigrations(migrations: DynamicMigration[]): Promise<void> {
        await this.axios.post('/migrations/bulk', migrations);
    }

    public async getSnapshotForUpcsAtDate(upcs: string[], date: Date): Promise<void> {
        await this.axios
            .post('/audit/passedQcAsOfDate', { upcs, passedQcAsOf: date.toISOString() })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, 'audit.csv');
            });
    }

    public async getPositioningConfiguration(): Promise<PositioningConfiguration> {
        const response = await this.axios.get<PositioningConfiguration>(
            '/brand-position-and-positioning-group-configuration',
        );
        return new Map(Object.entries(response.data));
    }

    public getPositioningConfigurationV2({
        onSuccess,
    }: ResponseHandler<PositioningConfiguration>): void {
        this.getPositioningConfiguration().then((result) => onSuccess(result));
    }

    public async getSnapshotForUpcsForTwoDates(
        upcs: string[],
        date1: Date,
        date2: Date,
    ): Promise<void> {
        await this.axios
            .post('/audit/compareQc', {
                upcs,
                date1: date1.toISOString(),
                date2: date2.toISOString(),
            })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, 'audit_compare.csv');
            });
    }

    public freezeNewItemsReturned(
        weekNumber: number,
        upcs: string[],
        freezeType: string,
    ): Promise<ApiResult> {
        return this.axios
            .post('/freezeNewItemsReturned', { weekNumber, upcs, freezeType })
            .then(() => {
                return { succeeded: true };
            })
            .catch(() => {
                return { succeeded: false };
            });
    }

    public freezeIriRefresh(): Promise<ApiResult> {
        return this.axios
            .post('/freezeIriRefresh')
            .then(() => {
                return { succeeded: true };
            })
            .catch(() => {
                return { succeeded: false };
            });
    }

    public unfreeze(freezeType: string): Promise<ApiResult> {
        return this.axios
            .post('/unfreeze', {}, { params: { freezeType } })
            .then(() => ({ succeeded: true }))
            .catch(() => ({ succeeded: false }));
    }

    public refreshIri(isPreview: boolean): Promise<ApiResult> {
        const url = isPreview ? '/refreshIriPreview' : '/refreshIri';
        return this.axios
            .get(url)
            .then(() => ({ succeeded: true }))
            .catch(() => ({ succeeded: false }));
    }

    public restatementIri(isPreview: boolean): Promise<ApiResult> {
        const url = isPreview ? '/restatementIriPreview' : '/restatementIri';
        return this.axios
            .get(url)
            .then(() => ({ succeeded: true }))
            .catch(() => ({ succeeded: false }));
    }

    public getImages(upc: string, { onSuccess }: { onSuccess: (val: string) => void }): void {
        this.axios.get(`/images/${upc}`).then((response: AxiosResponse<{ uri: string }>) => {
            onSuccess(response.data.uri);
        });
    }

    public async getSourcePriorities(): Promise<SourcePriority[]> {
        const result = await this.axios.get<SourcePriority[]>(`/source-priorities`);
        return result.data;
    }

    public async putOwnershipEntity(
        ownershipEntity: OwnershipEntity,
    ): Promise<OwnershipEntityUpdateResult> {
        const response = await this.axios.put(`/ownershipEntity`, ownershipEntity);
        return response.data;
    }

    public async checkOwnershipEntityMerge(
        ownershipEntity: OwnershipEntity,
    ): Promise<OwnershipEntityCheckMergeResult> {
        const response = await this.axios.post(`/ownershipEntityCheckMerge`, ownershipEntity);
        return response.data;
    }

    public async runMasterSnapshotQcReports(): Promise<string> {
        const response = await this.axios.post(`/snapshot-qc-reports/run`);
        return response.data;
    }

    public async getMasterSnapshotQcReportDetailData(): Promise<SnapshotQcReportDetailRecord[]> {
        const response = await this.axios.get(`/snapshot-qc-reports/detail`);
        return response.data;
    }

    public async getMasterSnapshotQcReportSummaryData(): Promise<SnapshotQcReportSummaryRecord[]> {
        const response = await this.axios.get(`/snapshot-qc-reports/summary`);
        return response.data;
    }

    public async putReleaseNote(releaseNote: ReleaseNote): Promise<[]> {
        const response = await this.axios.put(`/releaseNotes`, releaseNote);
        return response.data;
    }

    public async postReleaseNote(releaseNote: ReleaseNote): Promise<[]> {
        const response = await this.axios.post(`/releaseNotes`, releaseNote);
        return response.data;
    }

    public async getCampaigns(user: string, upc?: string): Promise<Campaign[]> {
        const response = await this.axios.get(`/workflow/assignments/${user}/campaigns`, {
            params: { upc },
        });
        return response.data;
    }

    public async postWorkflowAggregateTime(bulkTime: BulkTime): Promise<string> {
        const response = await this.axios.post('/workflow/time-tracking/aggregateTime', bulkTime);
        return response.data;
    }

    public async getReleaseNotes(): Promise<ReleaseNote[]> {
        const response = await this.axios.get(`/releaseNotes`);
        return response.data;
    }

    public async getMyWorkSummaryDataForUser(
        user: string,
        upc?: string,
    ): Promise<WorkflowCampaignSummary[]> {
        const path = `/workflow/assignments/${user}/summary`;
        const response = upc
            ? await this.axios.get(path, { params: { upc } })
            : await this.axios.get(path);
        return response.data;
    }

    public async getAssignedUpcsForUserWithCampaign(
        user: string,
        campaign: Campaign,
    ): Promise<AssignedUpc[]> {
        const response = await this.axios.get<AssignedUpc[]>(`/workflow/assignments/${user}/upcs`, {
            params: campaign,
        });
        return response.data;
    }

    public async getProductSummaries(
        upcs: string[],
        editorEmail: string,
    ): Promise<ProductLastEditedSummary[]> {
        const response = await this.axios.post<
            Array<{
                upc: string;
                description?: string;
                brand?: string;
                category?: string;
                lastEditedByUser?: number;
            }>
        >(`/product/summaries`, { upcs, editorEmail });

        return response.data.map((product) => ({
            ...product,
            lastEditedByUser: product.lastEditedByUser
                ? moment.unix(product.lastEditedByUser)
                : undefined,
        }));
    }

    public async getNextUpcForUserAndCampaign(
        user: string,
        campaign: Campaign,
        currentUpc: string,
    ): Promise<string | undefined> {
        const response = await this.axios.get<string | undefined>(
            `/workflow/assignments/${user}/next-upc`,
            {
                params: {
                    ...campaign,
                    currentUpc,
                },
            },
        );
        return response.status === 200 ? response.data : undefined;
    }

    public async getAssignmentStatus(user: string, upc: string): Promise<boolean> {
        const response = await this.axios.get<boolean>(
            `/workflow/assignments/${user}/assignment-status/${upc}`,
        );
        return response.status === 200 ? response.data : false;
    }

    public async insertStartEvent(
        upc: string,
        user: string,
    ): Promise<{ success: boolean; upc?: string; recordId: string | null }> {
        try {
            const response = await this.axios.post(
                `/workflow/time-tracking/startEvent?upc=${upc}&user=${user}`,
            );
            return {
                success: response.status === 200,
                recordId: response.data?.recordId,
            };
        } catch (e) {
            if (e.response?.status === 403) {
                return {
                    success: false,
                    upc: e.response?.data?.upcsInProgress?.[0],
                    recordId: e.response?.data?.recordId,
                };
            }
            throw e;
        }
    }

    public async insertPauseEvent(recordId: string | null): Promise<void> {
        await this.axios.post(`/workflow/time-tracking/pauseEvent?recordId=${recordId}`);
    }

    public async getCurrentEventStatus(
        upc: string,
        user: string,
    ): Promise<{ recordId: string | null }> {
        const response = await this.axios.get(
            `/workflow/time-tracking/currentStatus?upc=${upc}&user=${user}`,
        );
        return response.data;
    }

    public async testNirvanaApi(): Promise<string> {
        const response = await this.axios.get('/nirvana-test');
        return response.status.toString();
    }

    public async getEnabledFeatureFlags(): Promise<FeatureFlagName[]> {
        const response = await this.axios.get('/feature-flags/enabled');
        return response.data;
    }

    public async insertCompleteEvents(
        user: string,
        upcs: string[],
        campaign: Campaign,
        eventType: CompletionEventType,
    ): Promise<void> {
        await this.axios.post(`/workflow/time-tracking/completeEvents`, {
            user,
            upcs,
            campaign,
            eventType,
        });
    }

    public async deleteCompleteEvents(
        user: string,
        upcs: string[],
        campaign: Campaign,
    ): Promise<void> {
        await this.axios.delete(`/workflow/time-tracking/completeEvents`, {
            data: { user, upcs, campaign },
        });
    }
}

export interface Campaign {
    readonly period: string;
    readonly bucket: string;
}

export interface WorkflowCampaignSummary {
    readonly campaign: Campaign;
    readonly upcCount: number;
    readonly upcsCompleted: number;
    readonly manufacturerCodeSummaries: WorkflowManufacturerCodeSummary[];
}

export interface WorkflowManufacturerCodeSummary {
    readonly manufacturerCode: string;
    readonly upcCount: number;
    readonly upcsCompleted: number;
}

export interface ProductLastEditedSummary {
    readonly upc: string;
    readonly description?: string;
    readonly brand?: string;
    readonly category?: string;
    readonly lastEditedByUser?: import('moment').Moment;
    readonly completed?: Date;
    isSelected?: boolean;
}

export interface RetailerError {
    readonly retailerTag: string;
    readonly attributeErrors: AttributeError[];
}

export interface UpdateResult {
    qcPassed: boolean;
    message: string;
    status: number;
    attributeErrors: AttributeError[];
    retailerErrors: RetailerError[];
}

export const apiService = new ApiService();

export interface AttributeError extends AttributeBase {
    message: string;
}

export interface OptionPair {
    readonly value: string;
    readonly label: string;
}

export interface OwnershipEntityUpdateResult {
    ownershipEntityId: number;
    itemWarnings: [];
    upcsUpdated: number;
}

export interface OwnershipEntityCheckMergeResult {
    warning: boolean;
    message: string;
}
