import { AttributeGroup } from '../../../models';

export const camelCaseAttributeNameToGroup: Map<string, string> = new Map(
    Object.entries({
        positioningGroup: AttributeGroup.ADVANCED_CODING,
        healthFocus: AttributeGroup.ADVANCED_CODING,
        brandPosition: AttributeGroup.ADVANCED_CODING,
        maskedUpc: AttributeGroup.BASE_CODING,
        maskedBrand: AttributeGroup.BASE_CODING,
        upc: AttributeGroup.BASE_CODING,
        size: AttributeGroup.BASE_CODING,
        description: AttributeGroup.BASE_CODING,
        category: AttributeGroup.BASE_CODING,
        company: AttributeGroup.BASE_CODING,
        productType: AttributeGroup.BASE_CODING,
        manufacturerCode: AttributeGroup.BASE_CODING,
        privateLabelConventional: AttributeGroup.BASE_CODING,
        privateLabelProprietary: AttributeGroup.BASE_CODING,
        maskedDescription: AttributeGroup.BASE_CODING,
        upcEan13: AttributeGroup.BASE_CODING,
        unitOfMeasure: AttributeGroup.BASE_CODING,
        subcategory: AttributeGroup.BASE_CODING,
        department: AttributeGroup.BASE_CODING,
        brand: AttributeGroup.BASE_CODING,
        packCount: AttributeGroup.BASE_CODING,
        wholeGrainsCouncil: AttributeGroup.CERTIFICATIONS,
        americanHeartAssoc: AttributeGroup.CERTIFICATIONS,
        bCorporation: AttributeGroup.CERTIFICATIONS,
        certifiedPaleo: AttributeGroup.CERTIFICATIONS,
        demeterBiodynamic: AttributeGroup.CERTIFICATIONS,
        detoxProjectGlyphosateResidueFree: AttributeGroup.CERTIFICATIONS,
        fairForLife: AttributeGroup.CERTIFICATIONS,
        fairTradeUsa: AttributeGroup.CERTIFICATIONS,
        gfco: AttributeGroup.CERTIFICATIONS,
        greenerWorldAnimalWelfare: AttributeGroup.CERTIFICATIONS,
        greenerWorldGrassfed: AttributeGroup.CERTIFICATIONS,
        greenerWorldNonGmo: AttributeGroup.CERTIFICATIONS,
        howgoodRating: AttributeGroup.CERTIFICATIONS,
        fairTradeAmerica: AttributeGroup.CERTIFICATIONS,
        marineStewardshipCouncil: AttributeGroup.CERTIFICATIONS,
        naturalProductsAssoc: AttributeGroup.CERTIFICATIONS,
        nonGmoProjectVerified: AttributeGroup.CERTIFICATIONS,
        paleoFriendly: AttributeGroup.CERTIFICATIONS,
        paleoFoundationGrainFree: AttributeGroup.CERTIFICATIONS,
        paleoKeto: AttributeGroup.CERTIFICATIONS,
        paleoVegan: AttributeGroup.CERTIFICATIONS,
        plantBasedFoodsAssociation: AttributeGroup.CERTIFICATIONS,
        proprietaryChannel: AttributeGroup.CHANNEL_FLAGS,
        conventionalChannel: AttributeGroup.CHANNEL_FLAGS,
        retailerTag: AttributeGroup.CUSTOM_RETAILER_INFORMATION,
        privateLabelCustomBrand: AttributeGroup.CUSTOM_RETAILER_INFORMATION,
        retailerControlBrand: AttributeGroup.CUSTOM_RETAILER_INFORMATION,
        privateLabelCustomDescription: AttributeGroup.CUSTOM_RETAILER_INFORMATION,
        alcoholFree: AttributeGroup.LABEL_CLAIMS,
        organic: AttributeGroup.LABEL_CLAIMS,
        parabenFree: AttributeGroup.LABEL_CLAIMS,
        aluminumChlorohydrateFree: AttributeGroup.LABEL_CLAIMS,
        allergenFriendly: AttributeGroup.LABEL_CLAIMS,
        nitrateFree: AttributeGroup.LABEL_CLAIMS,
        noAddedHormones: AttributeGroup.LABEL_CLAIMS,
        noAntibioticsAdded: AttributeGroup.LABEL_CLAIMS,
        nonGmo: AttributeGroup.LABEL_CLAIMS,
        propyleneGlycolFree: AttributeGroup.LABEL_CLAIMS,
        sodiumLaurylSulfateFree: AttributeGroup.LABEL_CLAIMS,
        sprouted: AttributeGroup.LABEL_CLAIMS,
        freeRange: AttributeGroup.LABEL_CLAIMS,
        vegan: AttributeGroup.LABEL_CLAIMS,
        fragranceFree: AttributeGroup.LABEL_CLAIMS,
        fluorideFree: AttributeGroup.LABEL_CLAIMS,
        fairTrade: AttributeGroup.LABEL_CLAIMS,
        fat: AttributeGroup.LABEL_CLAIMS,
        coralReefSafe: AttributeGroup.LABEL_CLAIMS,
        cageFree: AttributeGroup.LABEL_CLAIMS,
        antioxidant: AttributeGroup.LABEL_CLAIMS,
        antiAging: AttributeGroup.LABEL_CLAIMS,
        glutenFree: AttributeGroup.LABEL_CLAIMS,
        grainFree: AttributeGroup.LABEL_CLAIMS,
        grassFed: AttributeGroup.LABEL_CLAIMS,
        animalWelfare: AttributeGroup.LABEL_CLAIMS,
        ancientGrain: AttributeGroup.LABEL_CLAIMS,
        aluminumFree: AttributeGroup.LABEL_CLAIMS,
        gender: AttributeGroup.POSITIONING,
        international: AttributeGroup.POSITIONING,
        age: AttributeGroup.POSITIONING,
        raw: AttributeGroup.POSITIONING,
        plantBased: AttributeGroup.POSITIONING,
        eatingOccasion: AttributeGroup.POSITIONING,
        paleo: AttributeGroup.POSITIONING,
        form: AttributeGroup.PRODUCT_FACTS,
        animalType: AttributeGroup.PRODUCT_FACTS,
        flavor: AttributeGroup.PRODUCT_FACTS,
        alcoholByVolume: AttributeGroup.PRODUCT_FACTS,
        functionalIngredient: AttributeGroup.PRODUCT_FACTS,
        packagingTypePrimary: AttributeGroup.PRODUCT_FACTS,
        scent: AttributeGroup.PRODUCT_FACTS,
        spfRating: AttributeGroup.PRODUCT_FACTS,
        storage: AttributeGroup.PRODUCT_FACTS,
        processingTreatment: AttributeGroup.PRODUCT_FACTS,
        beerType: AttributeGroup.PRODUCT_FACTS,
    }),
);
