import { tinycolor } from '@ctrl/tinycolor';
import { Palette, SmallChevronDown, SmallChevronUp, textStyles } from '@spins/amethyst';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

export const PopoverMenuContainer = styled.div`
    align-items: flex-end;
    display: flex;
    font-weight: 700;
    height: 100%;
    margin-left: auto;
    margin-right: 22px;
    > span {
        cursor: pointer;
        user-select: none;
    }
    ${textStyles.BodySmallHeavy};
    color: ${Palette.neutral[5].hex};
`;

const shadowOne = tinycolor(Palette.neutral[100].rgb).setAlpha(0.12).toRgbString();
const shadowTwo = tinycolor(Palette.neutral[100].rgb).setAlpha(0.1).toRgbString();
const backgroundColor = tinycolor(Palette.neutral[80].rgb).setAlpha(0.1).toRgbString();
export const PopOverMenuItemsContainer = styled.div`
    align-items: flex-start;
    background-color: ${Palette.neutral[0].hex};
    border-radius: 2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 0;
    position: absolute;
    right: 20px;
    top: 52px;
    z-index: 10;
    box-shadow: 0 1px 5px 0 ${shadowOne}, 0 0 0 1px ${shadowTwo};
    > span {
        cursor: pointer;
        user-select: none;
    }
    > div,
    a {
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 16px;
        :hover {
            background-color: ${backgroundColor};
            color: ${Palette.neutral[100].hex};
        }
        cursor: pointer;
        color: ${Palette.neutral[100].hex};
    }
`;

export interface HeaderPopOverMenuProps {
    userName: string;
    logout: () => void;
}

export const HeaderPopOverMenu: FC<HeaderPopOverMenuProps> = ({ userName, logout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    return (
        <PopoverMenuContainer role="button" onClick={toggleOpen}>
            <span>{userName}</span>
            <span style={{ marginLeft: '8px' }}>
                {isOpen ? (
                    <>
                        <SmallChevronUp />
                        <PopOverMenuItemsContainer>
                            <div role="button" onClick={logout}>
                                Log Out
                            </div>
                        </PopOverMenuItemsContainer>
                    </>
                ) : (
                    <SmallChevronDown />
                )}
            </span>
        </PopoverMenuContainer>
    );
};
