import { useEffect } from 'react';
import { NotificationType, useToasts } from '@spins/amethyst';

interface SimpleToastProps {
    type: NotificationType;
    message: string;
    autoDismiss?: boolean;
}

export const SimpleToast = ({ type, message, autoDismiss }: SimpleToastProps): null => {
    const isOpen = message !== '' && message !== undefined;
    const { addToast, removeAllToasts } = useToasts();

    useEffect(() => {
        if (isOpen) {
            addToast(message, {
                appearance: type,
                autoDismiss: autoDismiss === undefined ? true : autoDismiss,
                autoDismissTimeout: 8000,
            });
        }

        return () => {
            removeAllToasts();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, message, addToast, removeAllToasts]);
    return null;
};
