import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from './auth0';
import { apiService, FeatureFlagName } from './services/ApiService';

export type FeatureFlags = Record<FeatureFlagName, boolean | undefined>;
export const FeatureFlagsContext = createContext<FeatureFlags>({} as FeatureFlags);
export const useFeatureFlags = (): FeatureFlags => useContext(FeatureFlagsContext);
export const useFeatureFlag = (name: FeatureFlagName): boolean => {
    const flags = useFeatureFlags();
    return Boolean(flags[name]);
};

export const FeatureFlagsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({} as FeatureFlags);
    const auth = useAuth0();
    const isAuthenticated = auth?.isAuthenticated;
    useEffect(() => {
        if (isAuthenticated) {
            apiService.getEnabledFeatureFlags().then((r) => {
                const flags = r.reduce<FeatureFlags>(
                    (acc, flag) => ({ ...acc, [flag]: true }),
                    {} as FeatureFlags,
                );
                setFeatureFlags(flags);
            });
        }
    }, [isAuthenticated]);
    return (
        <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
    );
};
