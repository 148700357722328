import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import { MULTI_EDIT_STORAGE_KEY } from '../../constants/localStorageKeys';
import { useFeatureFlag } from '../../featureFlags';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { apiService, Campaign, ProductLastEditedSummary } from '../../services/ApiService';
import { Loader } from '../../components';
import { WorkDetails } from './WorkDetails';
import { SummaryTableDatum, WorkSummary } from './WorkSummary';
import { MyWorkWhitePanelBody } from './MyWorkWhitePanelBody';
import { CircleChecked, Palette } from '@spins/amethyst';

export type { SummaryTableDatum };

const Panel = styled.div`
    padding: 36px 0;
`;

const WhitePanel = styled.div`
    background-color: ${Palette.neutral[0].hex};
    padding-top: 32px;
    min-height: calc(100% - 50px);
`;

const WorkGrid = styled.div`
    display: grid;
    padding: 0 0 0 32px;
    columns: 2;
    gap: 20px;
    grid-template-columns: 2fr 3fr;
    min-height: calc(100% - 50px);
`;

export const MarkedCompleteCircle = styled(CircleChecked)`
    color: green;
    vertical-align: inherit;
`;
MarkedCompleteCircle.displayName = 'MarkedCompleteCircle';

interface MyWorkProps {
    user: string;
    period?: string;
    bucket?: string;
    manufacturerCode?: string;
}

const fetchDetailData = async (
    user: string,
    campaign: Campaign,
): Promise<ProductLastEditedSummary[]> => {
    const upcData = await apiService.getAssignedUpcsForUserWithCampaign(user, campaign);
    const upcs = upcData.map((item) => item.upc);
    const productSummaries = _.keyBy(
        await apiService.getProductSummaries(upcs, `${user}@spins.com`),
        'upc',
    );

    return upcData.map((item) => ({
        ...productSummaries[item.upc],
        completed: item.completed,
    }));
};

// noinspection JSIgnoredPromiseFromCall
export const MyWork = (props: MyWorkProps): JSX.Element => {
    const [workData, setWorkData] = useState<SummaryTableDatum[]>([]);
    const [workDetailData, setWorkDetailData] = useState<ProductLastEditedSummary[]>([]);

    const [multiEditEnabled, setMultiEditEnabled] = useState<boolean>(false);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);

    const { get: getLocalStorage, set: setLocalStorage } = useLocalStorage<Array<string>>();
    const withMultiEdit = useFeatureFlag('MULTI_EDIT_PAGE');

    const detailCampaign = useMemo<Campaign | undefined>(
        () =>
            props.bucket && props.period
                ? { period: props.period, bucket: props.bucket }
                : undefined,
        [props.period, props.bucket],
    );

    const updateLocalStorage = useMemo(
        () => (upc: string) => {
            const upcs: Array<string> = getLocalStorage(MULTI_EDIT_STORAGE_KEY) ?? [];
            console.log('upcs were: ', upcs);
            if (upcs.includes(upc)) {
                setLocalStorage(
                    MULTI_EDIT_STORAGE_KEY,
                    upcs.filter((i: string) => i !== upc),
                );
            } else {
                setLocalStorage(MULTI_EDIT_STORAGE_KEY, [...upcs, upc]);
            }
            console.log('added ', upc);
            console.log('upcs list is now ', getLocalStorage(MULTI_EDIT_STORAGE_KEY));
        },

        [getLocalStorage, setLocalStorage],
    );

    const onMultiEditSelect = useCallback(
        (upc: string) => {
            workDetailData.forEach((item) => {
                if (item.upc === upc) {
                    item.isSelected = !item.isSelected;
                    updateLocalStorage(upc);
                }
            });
            setMultiEditEnabled(workDetailData.some((item) => item.isSelected === true));
        },
        [updateLocalStorage, workDetailData],
    );

    useEffect(() => {
        setIsLoadingSummary(true);
        apiService.getMyWorkSummaryDataForUser(props.user).then((summaryData) => {
            setWorkData(
                summaryData.map((data) => {
                    return {
                        description: `${data.campaign.period} ${data.campaign.bucket}`,
                        campaign: data.campaign,
                        count: data.upcCount,
                        completed: data.upcsCompleted,
                        subRows: data.manufacturerCodeSummaries.map((mcs) => ({
                            campaign: data.campaign,
                            description: mcs.manufacturerCode,
                            count: mcs.upcCount,
                            completed: mcs.upcsCompleted,
                        })),
                    };
                }),
            );
            setIsLoadingSummary(false);
        });
    }, [props.user]);

    useEffect(() => {
        if (detailCampaign) {
            setIsLoadingDetail(true);
            fetchDetailData(props.user, detailCampaign).then((productSummaries) => {
                if (props.manufacturerCode) {
                    productSummaries = productSummaries.filter((d) => {
                        const manufacturerCode = d.upc.substring(1, 8).replace('-', '');
                        return manufacturerCode === props.manufacturerCode;
                    });
                }
                setWorkDetailData(productSummaries);
                if (withMultiEdit) setLocalStorage(MULTI_EDIT_STORAGE_KEY, []); // reset local storage on new details set
                setIsLoadingDetail(false);
            });
        }
    }, [props.user, detailCampaign, props.manufacturerCode, withMultiEdit, setLocalStorage]);
    return (
        <WorkGrid>
            <Panel>
                <Loader isLoading={isLoadingSummary}>
                    <WorkSummary
                        user={props.user}
                        workData={workData}
                        campaign={detailCampaign}
                        manufacturerCode={props.manufacturerCode}
                    />
                </Loader>
            </Panel>
            <WhitePanel>
                <MyWorkWhitePanelBody
                    campaign={detailCampaign}
                    manufacturerCode={props.manufacturerCode}
                    multiEditEnabled={multiEditEnabled}
                />
                <Loader isLoading={isLoadingDetail}>
                    <WorkDetails
                        workDetailData={workDetailData}
                        campaign={detailCampaign}
                        onSelect={onMultiEditSelect}
                    />
                </Loader>
            </WhitePanel>
        </WorkGrid>
    );
};
