import {
    Cell,
    CheckboxTableFilter,
    Column,
    OverflowType,
    useFilters,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { apiService } from '../../services/ApiService';
import { DownloadCsvButton } from './DownloadCsvButton';
import { SnapshotQcSummaryRow } from '../../models';
import { getSummaryRows } from './snapshotSummaryRowMapping';
import { selectFilter } from './Table/filters';
import { SnapshotQcTable } from './Table/SnapshotQcTable';
import { SlothSpinner } from '../../components';

const SnapshotQcSummaryContainer = styled.div`
    margin: 8px;
`;

const CheckboxTableFilterWithFuzzySearchOptions = <TData extends {}>(cell: Cell<TData>) => {
    return <CheckboxTableFilter {...cell} fuzzySearchOptions={{ distance: 1000 }} />;
};

const columns: Column<SnapshotQcSummaryRow>[] = [
    {
        Header: 'Report Name',
        accessor: 'reportName',
        Filter: CheckboxTableFilterWithFuzzySearchOptions,
        filter: selectFilter,
    },
    { Header: 'Potential Errors', accessor: 'potentialErrors', disableFilters: true },
    { Header: 'Period', accessor: 'periodName', Filter: CheckboxTableFilter, filter: selectFilter },
];

export const SnapshotQcSummary = (): ReactElement => {
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [data, setData] = useState<SnapshotQcSummaryRow[]>([]);
    const tableInstance = useTable(
        {
            columns,
            data,
            columnOverflow: OverflowType.Wrap,
        },
        useFilters,
        useFlexLayout,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        setIsRunning(true);
        apiService
            .getMasterSnapshotQcReportSummaryData()
            .then((foundRecords) => {
                setData(getSummaryRows(foundRecords));
                setIsRunning(false);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <SnapshotQcSummaryContainer>
            {isRunning && <SlothSpinner />}
            <h1>Product Intelligence Snapshot QC Reports - Summary</h1>
            <div style={{ display: 'flex' }}>
                <DownloadCsvButton
                    data={
                        tableInstance && tableInstance.filteredRows
                            ? tableInstance.filteredRows.map((row) => row.original)
                            : []
                    }
                />
            </div>
            {!isRunning && <SnapshotQcTable instance={tableInstance} />}
        </SnapshotQcSummaryContainer>
    );
};
