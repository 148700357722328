import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { BodyHeavy } from '@spins/amethyst';
import { Card } from '../../components';
import { AttributeGroupProps } from '../../models';
import { RowGroup } from './fields';
import { groupToTitle, toKebab } from './utils';

const AttributeCard = styled(Card)`
    margin-bottom: 32px;
    padding-bottom: 8px;
`;

const AttributeCardTitle = styled(BodyHeavy)`
    margin-bottom: 20px;
    display: inline-block;
`;
type AttributeGroupSectionProps = Omit<AttributeGroupProps, 'attributes'>;
export const AttributeGroupSection = ({
    className,
    groupName,
    titleAppend,
    children,
}: PropsWithChildren<AttributeGroupSectionProps> & { titleAppend?: JSX.Element }): JSX.Element => {
    return (
        <AttributeCard className={className} id={toKebab(groupName)}>
            <AttributeCardTitle className="title">{groupToTitle(groupName)}</AttributeCardTitle>
            {titleAppend}
            <RowGroup>{children}</RowGroup>
        </AttributeCard>
    );
};
