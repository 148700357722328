import { Info } from '@spins/amethyst';
import React, { useEffect, useState } from 'react';
import { generateUpcEan13GoogleSearchLink, generateGoogleSearchLink } from '../utils';
import { apiService } from '../../../services/ApiService';
import { ExternalLinkProps } from './ExternalLink';
import { HeaderLinkMenu } from './HeaderLinkMenu';

export interface ProductResourceMenuProps {
    upcEan13: string;
    upc: string;
    description: string;
}
export const ProductResourceMenu: React.FC<ProductResourceMenuProps> = ({
    upcEan13,
    upc,
    description,
}) => {
    const [imageUri, setImageUri] = useState<string | undefined>(undefined);

    useEffect(() => {
        apiService.getImages(upcEan13, { onSuccess: setImageUri });
    }, [upcEan13]);

    const items: ExternalLinkProps[] = [];
    items.push({ content: 'Source Info', url: `/product/${upc}/source-info` });
    if (imageUri) {
        items.push({ content: 'Aperture', url: imageUri });
    }
    items.push(
        { content: 'Google EAN 13', url: generateUpcEan13GoogleSearchLink(upcEan13) },
        { content: 'Google Description', url: generateGoogleSearchLink(description) },
    );
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '140px',
            }}
        >
            {imageUri && <Info aria-label="upc has image" width="16px" height="16px" />}
            <HeaderLinkMenu menuTitle={'Resources'} menuItems={items} />
        </div>
    );
};
