import { Button, Input, NotificationType, Select, SelectItem } from '@spins/amethyst';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAuth0 } from '../../auth0';
import { CardPage, FileUpload, SimpleToast, SlothSpinner } from '../../components';
import { CompletionEventType } from '../../constants/CompletionEventType';
import { UpdateType } from '../../constants/UpdateType';
import { BulkEditProduct, BulkTime } from '../../models';
import { apiService, Campaign } from '../../services/ApiService';
import { Errors } from './Errors';
import { BulkGrid, FileUploadControls } from './fields';

export interface BulkEditToast {
    type: NotificationType;
    message: string;
}

export interface QcResult {
    upc: string;
    warningMessages: string[];
    errorMessages: string[];
}

export interface QcAggregateResult {
    count: number;
    message: string;
}

export interface QcBulkResult {
    qcResults: QcResult[];
    qcAggregateResults: QcAggregateResult[];
}

export interface BulkEditPageProps {
    parseFunction: (file: File) => Promise<BulkEditProduct[]>;
    apiFunction: (
        products: BulkEditProduct[],
        estimatedTime?: number,
        updateType?: UpdateType,
    ) => Promise<QcBulkResult>;
    headerText: string;
    onSuccess?: () => Promise<BulkEditToast>;
    timeTracking?: boolean;
}

export const BulkEditPage = ({
    parseFunction,
    apiFunction,
    headerText,
    onSuccess,
    timeTracking,
}: BulkEditPageProps): ReactElement => {
    const user: string = useAuth0()!.user.nickname;
    const [toastStatus, setToastStatus] = useState<BulkEditToast[]>([]);
    const [qcResult, setQcResult] = useState<QcResult[]>([]);
    const [qcAggregateResult, setQcAggregateResult] = useState<QcAggregateResult[]>([]);
    const [parsedData, setParsedData] = useState<BulkEditProduct[] | null>(null);
    const [isProcessingBulkUpdate, setIsProcessingBulkUpdate] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState<number | undefined>(undefined);
    const [updateType, setUpdateType] = useState<UpdateType | undefined>(undefined);
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | undefined>();
    const [markComplete, setMarkComplete] = useState<boolean | undefined>();
    const [campaignOptions, setCampaignOptions] = useState<SelectItem<Campaign>[]>([]);

    useEffect(() => {
        apiService.getCampaigns(user).then((campaigns) => {
            setCampaignOptions(
                campaigns.map((campaign) => ({
                    label: `${campaign.period} ${campaign.bucket}`,
                    value: campaign,
                })),
            );
        });
    }, [user]);

    const haveWarnings = (): boolean => {
        return qcResult.some((r: QcResult) => {
            if (r.warningMessages) {
                return r.warningMessages && r.warningMessages.length > 0;
            } else {
                return false;
            }
        });
    };

    const isFailure = (): boolean => {
        return (
            qcResult.length > 0 &&
            qcResult.some((result: QcResult) => {
                return result.errorMessages && result.errorMessages.length > 0;
            })
        );
    };
    const generateFailureMessage = (): string => {
        const failedUPCs = qcResult
            .filter((r: QcResult) => {
                return r.errorMessages && r.errorMessages.length > 0;
            })
            .map((item: QcResult) => (item as QcResult).upc)
            .join(',');

        return `Failed UPCs: ${failedUPCs}`;
    };

    const getMessage = (): string => {
        if (isFailure()) {
            return generateFailureMessage();
        } else {
            return 'File Upload Successfully!';
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        if (!file) {
            return;
        }

        parseFunction(file).then(setParsedData);
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        setIsProcessingBulkUpdate(true);

        if (parsedData) {
            apiFunction(parsedData, estimatedTime, updateType)
                .then(async (result: QcBulkResult) => {
                    const qcResultsResponse = result.qcResults;
                    const qcAggregteResponse = result.qcAggregateResults;

                    const haveUPCErrors = (): boolean => {
                        return qcResultsResponse.some((r: QcResult) => {
                            return r.errorMessages.some((msg) => msg.includes('Invalid Spins UPC'));
                        });
                    };
                    const status = [
                        {
                            message: getMessage(),
                            type: NotificationType.Info,
                        },
                    ];
                    if (haveUPCErrors()) {
                        status.push({
                            message: 'Invalid Spins UPC detected',
                            type: NotificationType.Warning,
                        });
                    }
                    if (onSuccess) {
                        status.push(await onSuccess());
                    }
                    setToastStatus(status);
                    setIsProcessingBulkUpdate(false);
                    setQcResult(qcResultsResponse);
                    setQcAggregateResult(qcAggregteResponse);
                })
                .then(() => {
                    if (parsedData && estimatedTime) {
                        const bulkTime: BulkTime = {
                            upcs: parsedData.map((t) => t.upc),
                            totalTime: estimatedTime,
                            type: updateType
                                ? updateType?.replace(/ /g, '_').toUpperCase()
                                : 'NEW_ITEM_CODING',
                        };
                        apiService.postWorkflowAggregateTime(bulkTime);
                    }
                })
                .then(async () => {
                    if (updateType === UpdateType.NEW_ITEM_CODING && selectedCampaign) {
                        const upcs = parsedData.map((product) => product.upc);
                        await apiService.deleteCompleteEvents(user, upcs, selectedCampaign);
                        if (markComplete) {
                            await apiService.insertCompleteEvents(
                                user,
                                upcs,
                                selectedCampaign,
                                CompletionEventType.Bulk_Edit,
                            );
                        }
                    }
                })
                .catch((error) => {
                    setToastStatus([
                        {
                            message: error.response.data.toString(),
                            type: NotificationType.Error,
                        },
                    ]);
                    setIsProcessingBulkUpdate(false);
                });
        }
    };

    return (
        <CardPage title={headerText}>
            {toastStatus &&
                toastStatus.map(({ type, message }, i) => (
                    <SimpleToast key={i} type={type} message={message} autoDismiss={false} />
                ))}
            <FileUploadControls>
                <form style={{ display: 'grid', gap: '11px' }} onSubmit={handleSave}>
                    <div style={{ marginBottom: '21px' }}>
                        <FileUpload
                            handleFileChange={handleFileChange}
                            error={
                                parsedData && parsedData.length === 0
                                    ? 'No UPC Data Found In Selected File'
                                    : ''
                            }
                        />
                    </div>
                    {timeTracking && (
                        <>
                            <Input
                                name={'estimated-time'}
                                label={'Estimated time (minutes)'}
                                type={'number'}
                                min={'0'}
                                step={'1'}
                                pattern="\d+"
                                placeholder={'number of minutes'}
                                onChange={(minutes: number) => {
                                    setEstimatedTime(minutes !== 0 ? minutes : undefined);
                                }}
                                value={estimatedTime}
                            />
                            <Select
                                disabled={!estimatedTime}
                                name={'update-type'}
                                label={'Update type'}
                                placeholder={'select'}
                                items={[
                                    { label: 'New item coding', value: UpdateType.NEW_ITEM_CODING },
                                    {
                                        label: 'Specialized coding',
                                        value: UpdateType.SPECIALIZED_CODING,
                                    },
                                    { label: 'Audit', value: UpdateType.AUDIT },
                                    { label: 'QC', value: UpdateType.QC },
                                    { label: 'Other', value: UpdateType.OTHER },
                                ]}
                                onChange={(type) => {
                                    if (!type) {
                                        setUpdateType(undefined);
                                    } else {
                                        setUpdateType(type.value);
                                    }
                                }}
                            />
                            <Select
                                name={'select-campaign'}
                                items={campaignOptions}
                                label={'Select campaign'}
                                onChange={(campaignOption) =>
                                    setSelectedCampaign(campaignOption?.value)
                                }
                                disabled={updateType !== UpdateType.NEW_ITEM_CODING}
                            />
                            <div>
                                <input
                                    type="checkbox"
                                    checked={markComplete}
                                    id="mark_complete"
                                    name="mark_complete"
                                    disabled={updateType !== UpdateType.NEW_ITEM_CODING}
                                    onChange={() => {
                                        setMarkComplete(!markComplete);
                                    }}
                                />
                                <label htmlFor="mark_complete">Mark Complete</label>
                            </div>
                        </>
                    )}
                    <div>
                        <Button
                            type={'submit'}
                            disabled={
                                !parsedData ||
                                parsedData.length === 0 ||
                                isProcessingBulkUpdate ||
                                (!!timeTracking && !!estimatedTime && !updateType) ||
                                (updateType === UpdateType.NEW_ITEM_CODING &&
                                    selectedCampaign === undefined)
                            }
                        >
                            Upload
                        </Button>
                        {isProcessingBulkUpdate && <SlothSpinner />}
                    </div>
                </form>
                <div style={{ display: 'block' }}>
                    <BulkGrid>
                        {(isFailure() || haveWarnings() || qcAggregateResult.length > 0) && (
                            <Errors
                                qcResults={qcResult}
                                qcAggregateResults={qcAggregateResult}
                                haveWarnings={haveWarnings()}
                                isFailure={isFailure()}
                            />
                        )}
                    </BulkGrid>
                </div>
            </FileUploadControls>
        </CardPage>
    );
};
