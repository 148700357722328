import createAuth0Client, {
    GetTokenSilentlyOptions,
    LogoutOptions,
    RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import jwtDecode from 'jwt-decode';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export interface Auth0ContextType {
    isAuthenticated: boolean;
    user: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    loading: boolean;
    permissions: string[];

    loginWithRedirect(o: RedirectLoginOptions): Promise<void>;

    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;

    logout(o?: LogoutOptions): void;
}

const Auth0Context = React.createContext<Auth0ContextType | null>(null);

export const useAuth0 = (): Auth0ContextType | null => useContext(Auth0Context);

let currentUserEmail = 'UNKNOWN';
export const getCurrentUserEmail = (): string => currentUserEmail;

let currentAccessToken = '';
export const getCurrentAccessToken = (): string => currentAccessToken;

export const Auth0Provider = ({ children }: { children: React.ReactNode }): ReactElement => {
    const [auth0Client, setAuth0Client] = useState<Auth0Client>();
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        loading: true,
        permissions: [] as string[],
    });
    const history = useHistory();

    useEffect(() => {
        if (!window.REACT_APP_DONT_USE_AUTH0) {
            (async () => {
                const auth0Client = await createAuth0Client({
                    domain: window.REACT_APP_AUTH0_DOMAIN,
                    client_id: window.REACT_APP_AUTH0_CLIENT_ID,
                    redirect_uri: window.location.origin,
                    audience: window.REACT_APP_AUTH0_AUDIENCE,
                });
                setAuth0Client(auth0Client);

                if (window.location.search.includes('code=')) {
                    const {
                        appState: { targetUrl },
                    } = await auth0Client.handleRedirectCallback();
                    history.replace(targetUrl);
                }

                if (await auth0Client.isAuthenticated()) {
                    const user = await auth0Client.getUser();
                    currentUserEmail = user.email;

                    currentAccessToken = await auth0Client.getTokenSilently({
                        audience: window.REACT_APP_AUTH0_AUDIENCE,
                    });

                    const decodedToken = jwtDecode<{ permissions: string[] }>(currentAccessToken);
                    setAuthState({
                        user,
                        isAuthenticated: true,
                        loading: false,
                        permissions: decodedToken.permissions,
                    });
                } else {
                    setAuthState({
                        user: null,
                        isAuthenticated: false,
                        loading: false,
                        permissions: [],
                    });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Auth0Context.Provider
            value={
                auth0Client
                    ? {
                          ...authState,
                          loginWithRedirect: auth0Client.loginWithRedirect.bind(auth0Client),
                          getTokenSilently: auth0Client.getTokenSilently.bind(auth0Client),
                          logout: auth0Client.logout.bind(auth0Client),
                      }
                    : null
            }
        >
            {children}
        </Auth0Context.Provider>
    );
};
