import React, { ReactElement, useCallback, useState } from 'react';
import { Button, NotificationType } from '@spins/amethyst';
import { apiService } from '../services/ApiService';
import { CardPage, SlothSpinner, SimpleToast } from '../components/';

export const RestatementQcChecks = (): ReactElement => {
    const [isLoading, setLoading] = useState(false);
    const [result, setResult] = useState({ message: '', isSuccess: false });

    const run = useCallback(async () => {
        setResult({ isSuccess: false, message: '' });
        setLoading(true);
        const result = await apiService.restatementQcChecks();
        setLoading(false);
        setResult({
            isSuccess: result.succeeded,
            message: result.succeeded ? 'Finished Running Checks' : 'Running Checks Failed',
        });
    }, []);

    return (
        <CardPage title="Restatement QC Checks">
            <SimpleToast
                type={result.isSuccess ? NotificationType.Info : NotificationType.Error}
                message={result.message}
            />
            <Button
                disabled={isLoading}
                size={'small'}
                type="button"
                buttonType={'primary'}
                onClick={run}
            >
                Run
            </Button>
            {isLoading && <SlothSpinner />}
        </CardPage>
    );
};
