import React, { ReactElement } from 'react';
import { RejectedLabel } from '../pages/BulkEdit/fields';

interface Props
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
}

export const FileUpload = ({ handleFileChange, error, ...props }: Props): ReactElement => {
    return (
        <div style={{ display: 'inline-block' }}>
            <input
                style={{ display: 'block' }}
                type="file"
                accept="text/csv"
                onChange={handleFileChange}
                {...props}
            />
            {error && <RejectedLabel>{error}</RejectedLabel>}
        </div>
    );
};
