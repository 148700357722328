import { NotificationType, useToasts } from '@spins/amethyst';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth0 } from '../../../auth0';
import { apiService } from '../../../services/ApiService';

export const useWorkflowUri = (campaignSelected: boolean, upc: string): void => {
    const { user } = useAuth0()!;
    const { addToast, removeToast } = useToasts();
    const [multipleCampaignsToastId, setMultipleCampaignsToastId] = useState<string>();
    const history = useHistory();

    const addMultipleCampaignsToast = useCallback(() => {
        addToast(
            'This UPC is in multiple campaigns. Please select the campaign you are working on to proceed.',
            { appearance: NotificationType.Warning },
            setMultipleCampaignsToastId,
        );
    }, [addToast, setMultipleCampaignsToastId]);

    const removeMultipleCampaignsToast = useCallback(() => {
        if (multipleCampaignsToastId) removeToast(multipleCampaignsToastId);
    }, [removeToast, multipleCampaignsToastId]);

    useEffect(() => {
        if (!campaignSelected && multipleCampaignsToastId === undefined) {
            apiService.getCampaigns(user.nickname, upc).then((campaigns) => {
                if (campaigns.length === 1) {
                    const { period, bucket } = campaigns[0];
                    history.replace(`/product/${upc}?period=${period}&bucket=${bucket}`);
                }
                if (campaigns.length > 1) {
                    addMultipleCampaignsToast();
                }
            });
        }
        return removeMultipleCampaignsToast;
    }, [
        upc,
        campaignSelected,
        user.nickname,
        addMultipleCampaignsToast,
        removeMultipleCampaignsToast,
        multipleCampaignsToastId,
        history,
    ]);
};
