export enum AttributeType {
    FREE_TEXT = 'FREE_TEXT',
    ENUMERATED = 'ENUMERATED',
    NUMERIC = 'NUMERIC',
    READ_ONLY = 'READ_ONLY',
    DERIVED = 'DERIVED',
    RETAILER = 'RETAILER',
    BRAND_NAME = 'BRAND_NAME',
    COMPANY = 'COMPANY',
}
