import { AttributeName } from './attributeNames';
const confluenceUrl = 'http://wiki.spenceinfo.spins.com/display/PL/';

export const confluenceLinkMap: Map<AttributeName, string> = new Map([
    [AttributeName.BRAND, `${confluenceUrl}BRAND`],
    [AttributeName.COMPANY, `${confluenceUrl}COMPANY+CODING`],
    [AttributeName.DESCRIPTION, `${confluenceUrl}DESCRIPTION`],
    [AttributeName.UNIT_OF_MEASURE, `${confluenceUrl}UNIT+OF+MEASURE`],
    [AttributeName.DEPARTMENT, `${confluenceUrl}Hierarchy+Definitions`],
    [AttributeName.CATEGORY, `${confluenceUrl}Hierarchy+Definitions`],
    [AttributeName.SUBCATEGORY, `${confluenceUrl}Hierarchy+Definitions`],
    [AttributeName.PRODUCT_TYPE, `${confluenceUrl}Hierarchy+Definitions`],
    [AttributeName.PACK_COUNT, `${confluenceUrl}PACK+COUNT`],
    [AttributeName.ALCOHOL_BY_VOLUME, `${confluenceUrl}ALCOHOL+BY+VOLUME`],
    [AttributeName.ANIMAL_TYPE, `${confluenceUrl}ANIMAL+TYPE`],
    [AttributeName.BEER_TYPE, `${confluenceUrl}BEER+TYPE`],
    [AttributeName.FLAVOR, `${confluenceUrl}FLAVOR`],
    [AttributeName.FORM, `${confluenceUrl}FORM`],
    [AttributeName.FUNCTIONAL_INGREDIENT, `${confluenceUrl}FUNCTIONAL+INGREDIENT`],
    [AttributeName.PACKAGING_TYPE_PRIMARY, `${confluenceUrl}PACKAGING+TYPE+PRIMARY`],
    [AttributeName.PROCESSING_TREATMENT, `${confluenceUrl}PROCESSING+TREATMENT`],
    [AttributeName.SCENT, `${confluenceUrl}SCENT`],
    [AttributeName.SPF_RATING, `${confluenceUrl}SPF+RATING`],
    [AttributeName.STORAGE, `${confluenceUrl}STORAGE`],
    [AttributeName.AGE, `${confluenceUrl}AGE`],
    [AttributeName.EATING_OCCASION, `${confluenceUrl}EATING+OCCASION`],
    [AttributeName.GENDER, `${confluenceUrl}GENDER`],
    [AttributeName.INTERNATIONAL, `${confluenceUrl}INTERNATIONAL`],
    [AttributeName.PALEO, `${confluenceUrl}PALEO`],
    [AttributeName.PLANT_BASED, `${confluenceUrl}PLANT+BASED`],
    [AttributeName.RAW, `${confluenceUrl}RAW`],
    [AttributeName.ALCOHOL_FREE, `${confluenceUrl}ALCOHOL+FREE`],
    [AttributeName.ALLERGEN_FRIENDLY, `${confluenceUrl}ALLERGEN+FRIENDLY`],
    [AttributeName.ALUMINUM_CHLOROHYDRATE_FREE, `${confluenceUrl}ALUMINUM+CHLOROHYDRATE+FREE`],
    [AttributeName.ALUMINUM_FREE, `${confluenceUrl}ALUMINUM+FREE`],
    [AttributeName.ANCIENT_GRAIN, `${confluenceUrl}ANCIENT+GRAIN`],
    [AttributeName.ANIMAL_WELFARE, `${confluenceUrl}ANIMAL+WELFARE`],
    [AttributeName.ANTI_AGING, `${confluenceUrl}ANTI+AGING`],
    [AttributeName.NO_ANTIBIOTICS_ADDED, `${confluenceUrl}NO+ANTIBIOTICS+ADDED`],
    [AttributeName.ANTIOXIDANT, `${confluenceUrl}ANTIOXIDANT`],
    [AttributeName.CAGE_FREE, `${confluenceUrl}CAGE+FREE`],
    [AttributeName.CORAL_REEF_SAFE, `${confluenceUrl}CORAL+REEF+SAFE`],
    [AttributeName.FAIR_TRADE, `${confluenceUrl}FAIR+TRADE`],
    [AttributeName.FAT, `${confluenceUrl}FAT`],
    [AttributeName.FLUORIDE_FREE, `${confluenceUrl}FLUORIDE+FREE`],
    [AttributeName.FREE_RANGE, `${confluenceUrl}FREE+RANGE`],
    [AttributeName.GLUTEN_FREE, `${confluenceUrl}GLUTEN+FREE`],
    [AttributeName.GRAIN_FREE, `${confluenceUrl}GRAIN+FREE`],
    [AttributeName.GRASS_FED, `${confluenceUrl}GRASS+FED`],
    [AttributeName.NITRATE_FREE, `${confluenceUrl}NITRATE+FREE`],
    [AttributeName.NO_ADDED_HORMONES, `${confluenceUrl}NO+ADDED+HORMONES`],
    [AttributeName.NON_GMO, `${confluenceUrl}NON-GMO`],
    [AttributeName.ORGANIC, `${confluenceUrl}ORGANIC`],
    [AttributeName.PARABEN_FREE, `${confluenceUrl}PARABEN+FREE`],
    [AttributeName.PROPYLENE_GLYCOL_FREE, `${confluenceUrl}PROPYLENE+GLYCOL+FREE`],
    [AttributeName.SODIUM_LAURYL_SULFATE_FREE, `${confluenceUrl}SODIUM+LAURYL+SULFATE+FREE`],
    [AttributeName.SPROUTED, `${confluenceUrl}SPROUTED`],
    [AttributeName.VEGAN, `${confluenceUrl}VEGAN`],
]);
