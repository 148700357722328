import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
    Column,
    LargeFacet,
    SimpleTable,
    SmallFacet,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import { RawProductAttribute } from '../services/RawProductAttribute';
import { rawProductAttributesApiService } from '../services/RawProductAttributesApiService';
import { Card } from '../components';

interface SourceInfoProps {
    readonly upc: Readonly<string>;
}

export function filter(
    data: RawProductAttribute[],
    sourcesToFilter: string[],
    attributesToFilter: string[],
): RawProductAttribute[] {
    return data
        .filter(({ attribute }) => attributesToFilter.includes(attribute))
        .filter(({ source }) => sourcesToFilter.includes(source));
}

function toCheckboxItems(strings: string[]) {
    return strings.map((s) => ({ label: s, value: s }));
}

export function allAttributes(data: RawProductAttribute[]): string[] {
    const attrs = new Set(data.map(({ attribute }) => attribute));
    return Array.from(attrs);
}

export function allSources(data: RawProductAttribute[]): string[] {
    const sources = new Set(data.map(({ source }) => source));
    return Array.from(sources);
}

const Facet: typeof LargeFacet = (props) => {
    const Component = props.items.length > 7 ? LargeFacet : SmallFacet;
    return <Component {...props} />;
};
Facet.displayName = 'Facet';

const SourceInfoLayout = styled.div`
    display: grid;
    grid-template-columns: 230px auto;
    grid-gap: 10px;
`;

// This has to be declared out here to stop repeated regenerations
const columns: Column<RawProductAttribute>[] = [
    { Header: 'DATE', accessor: 'date' },
    { Header: 'SOURCE', accessor: 'source' },
    { Header: 'ATTRIBUTE', accessor: 'attribute' },
    { Header: 'VALUE', accessor: 'value' },
];

export const SourceInfo: FunctionComponent<SourceInfoProps> = ({
    upc,
}: SourceInfoProps): ReactElement => {
    const [sourcesToFilter, setSourcesToFilter] = useState<string[]>([]);
    const [attributesToFilter, setAttributesToFilter] = useState<string[]>([]);
    const [attributes, setAttributes] = useState<RawProductAttribute[]>([]);
    const [data, setData] = useState<RawProductAttribute[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const attributes: RawProductAttribute[] = await rawProductAttributesApiService.getRawProductAttributes(
                    upc,
                );
                setAttributes(attributes);
                setAttributesToFilter(allAttributes(attributes));
                setSourcesToFilter(allSources(attributes));
            } catch (e) {
                setAttributes([]);
            }
        })();
    }, [upc]);

    useEffect(() => {
        setData(filter(attributes, sourcesToFilter, attributesToFilter));
    }, [attributes, sourcesToFilter, attributesToFilter]);

    const tableInstance = useTable<RawProductAttribute>(
        {
            columns: columns,
            data,
            totalItems: data.length,
            itemDescriptor: 'Source Info',
            noDataMessage: 'No results found',
            initialState: {
                pageSize: 50,
            },
        },
        useFlexLayout,
        useSortBy,
        usePagination,
    );

    return (
        <SourceInfoLayout>
            <Card>
                <Facet
                    items={toCheckboxItems(allAttributes(attributes))}
                    value={attributesToFilter}
                    title="Attributes"
                    onChange={(attrs) => {
                        setAttributesToFilter(attrs);
                    }}
                />
                <Facet
                    items={toCheckboxItems(allSources(attributes))}
                    value={sourcesToFilter}
                    title="Sources"
                    onChange={(sources) => setSourcesToFilter(sources)}
                />
            </Card>
            <div>
                <SimpleTable instance={tableInstance} />
            </div>
        </SourceInfoLayout>
    );
};
