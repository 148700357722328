import { defaultTheme, Palette, getValidationBorderColor } from '@spins/amethyst';
import styled from 'styled-components';

export const DropUpComponent = styled.div.attrs((props) => ({
    borderColor: getValidationBorderColor(props, props.theme.neutrals.senary.hex),
    focusBorderColor: getValidationBorderColor(props, props.theme.colors.octonary.hex),
}))`
    height: 32px;
    * > [role='button'] {
        height: 32px;
    }
    * > [aria-expanded='true'] {
        border-radius: 0 0 0.1875rem 0.1875rem;
        border: solid 0.0625rem ${(props) => props.focusBorderColor};
        border-top: solid 0.0625rem ${Palette.neutral[40].hex};
        box-shadow: 0 0 0 0.0625rem ${(props) => props.focusBorderColor};
    }
    [role='listbox'] {
        transform: translateY(calc(-100% - 33px));
        background-color: ${Palette.neutral[0].hex};
        box-shadow: -1px -1px 0px 1px ${(props) => props.focusBorderColor},
            0px -1px 0px 1px ${(props) => props.focusBorderColor},
            1px -1px 0px 1px ${(props) => props.focusBorderColor};
        border-radius: 0.1875rem 0.1875rem 0 0;
        border-bottom: none;
    }
`;

DropUpComponent.defaultProps = {
    theme: defaultTheme,
};
