import React, { FC, useMemo, useState } from 'react';
import { Column, LoadingSpinner, SimpleTable, useFlexLayout, useTable } from '@spins/amethyst';
import { CellProps } from 'react-table';
import { AttributeName } from '../../constants/attributeNames';
import { MULTI_EDIT_STORAGE_KEY } from '../../constants/localStorageKeys';
import { useFeatureFlag } from '../../featureFlags';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { NotFoundPage } from '..';
import { Product } from '../../models';
import { useGetProductDetailsFromUpcs } from '../../services/ProductService';

type ProductStub = {
    // this is built to handle our WIP product stub and may need to be refactored when this product model becomes more complex
    upc?: string;
    manufacturerCode?: string;
    description?: string;
    brand?: string;
    department?: string;
    category?: string;
    subcategory?: string;
    size?: string;
    unitOfMeasure?: string;
};

const nullFiller = <TData extends Record<string, unknown>>(key: keyof TData) => (row: TData) =>
    row[key] ?? 'n/a';

const EditableCell: FC<CellProps<{}>> = ({ cell }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const setEditMode = (
        enabled: boolean,
    ): React.EventHandler<React.MouseEvent | React.KeyboardEvent | React.FocusEvent> => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditMode(enabled);
    };
    // const setEditModeAndSave = (e: React.KeyboardEvent | React.FocusEvent) => {
    //     e.target.value;
    // };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case 'Enter': // return setEditModeAndSave(e);
            case 'Escape':
                return setEditMode(false)(e);
            default:
                break;
        }
    };

    return (
        <span onClick={setEditMode(true)}>
            {!isEditMode ? (
                cell.value
            ) : (
                <input
                    defaultValue={cell.value}
                    onBlur={setEditMode(false)}
                    onKeyDown={handleKeyDown}
                />
            )}
        </span>
    );
};

export const MultiEditPage = (): JSX.Element => {
    const columns = useMemo<Column<ProductStub>[]>(
        // this is built to handle our WIP product stub and may need to be refactored when this product model becomes more complex
        () => [
            {
                Header: 'UPC',
                accessor: nullFiller('upc'),
            },
            { Header: 'MFR', accessor: nullFiller('manufacturerCode') },
            { Header: 'Description', accessor: nullFiller('description'), Cell: EditableCell },
            { Header: 'Brand', accessor: nullFiller('brand') },
            { Header: 'Department', accessor: nullFiller('department') },
            { Header: 'Category', accessor: nullFiller('category') },
            { Header: 'Subcategory', accessor: nullFiller('subcategory') },
            { Header: 'Size', accessor: nullFiller('size') },
            { Header: 'Unit of Measure', accessor: nullFiller('unitOfMeasure') },
        ],
        [],
    );
    const withMultiEdit = useFeatureFlag('MULTI_EDIT_PAGE');
    const localStorage = useLocalStorage<string[]>();

    const { data, loading } = useGetProductDetailsFromUpcs(
        localStorage.get(MULTI_EDIT_STORAGE_KEY) ?? [],
    );

    const mapProductToValues = (product: Optional<Product>): ProductStub => {
        // this is built to handle our WIP product stub and may need to be refactored when this product model becomes more complex
        // consider extracting Product Page mapping functionality into a shared module.
        return {
            upc: product?.upc,
            manufacturerCode: product?.attributes?.find(
                (attr) => attr.name === AttributeName.MANUFACTURER_CODE,
            )?.description,
            description: product?.attributes?.find(
                (attr) => attr.name === AttributeName.DESCRIPTION,
            )?.description,
            brand: product?.attributes?.find((attr) => attr.name === AttributeName.BRAND)
                ?.description,
            department: product?.attributes?.find((attr) => attr.name === AttributeName.DEPARTMENT)
                ?.description,
            category: product?.attributes?.find((attr) => attr.name === AttributeName.CATEGORY)
                ?.description,
            subcategory: product?.attributes?.find(
                (attr) => attr.name === AttributeName.SUBCATEGORY,
            )?.description,
            size: product?.attributes?.find((attr) => attr.name === AttributeName.SIZE)
                ?.description,
            unitOfMeasure: product?.attributes?.find(
                (attr) => attr.name === AttributeName.UNIT_OF_MEASURE,
            )?.description,
        };
    };

    const mappedData = data.map((product) => mapProductToValues(product));

    const noDataMessage = loading ? <LoadingSpinner /> : undefined;

    const tableInstance = useTable({ columns, data: mappedData, noDataMessage }, useFlexLayout);

    return withMultiEdit ? <SimpleTable instance={tableInstance} /> : <NotFoundPage />;
};
