import { AttributeName } from '../constants/attributeNames';

export enum DynamicMigrationTable {
    SUBCATEGORY_ALLOWED_VALUES = 'SUBCATEGORY_ALLOWED_VALUES',
}

export enum DynamicMigrationAction {
    add = 'add',
    update = 'update',
    retire = 'retire',
}

export interface AddDynamicMigration<TTable extends DynamicMigrationTable, TValues> {
    table: TTable;
    action: DynamicMigrationAction.add;
    newValues: TValues;
}

export interface RetireDynamicMigration<TTable extends DynamicMigrationTable, TValues> {
    table: TTable;
    action: DynamicMigrationAction.retire;
    oldValues: TValues;
}

export interface UpdateDynamicMigration<TTable extends DynamicMigrationTable, TValues> {
    table: TTable;
    action: DynamicMigrationAction.update;
    oldValues: TValues;
    newValues: TValues;
}

export interface SubcategoryAllowedValuesMigrationValues {
    subcategoryTag: string;
    attributeName: AttributeName;
    valueTag: string;
}

export type DynamicMigrationForTable<TTable extends DynamicMigrationTable, TValues> =
    | AddDynamicMigration<TTable, TValues>
    | RetireDynamicMigration<TTable, TValues>
    | UpdateDynamicMigration<TTable, TValues>;

export type DynamicMigration = DynamicMigrationForTable<
    DynamicMigrationTable.SUBCATEGORY_ALLOWED_VALUES,
    SubcategoryAllowedValuesMigrationValues
>;
