import useAxios from 'axios-hooks';
import { Product } from '../models';

const EMPTY_ARRAY: [] = [];
export const useGetProductDetailsFromUpcs = (
    upcs: Array<string>,
    defaultLoadingValue: string[] = EMPTY_ARRAY,
): { data: Product[]; loading: boolean } => {
    const [{ data, loading }] = useAxios({ url: '/product', method: 'POST', data: upcs });
    return { data: data ?? defaultLoadingValue, loading };
};
