import { Button, Link as AmethystLink } from '@spins/amethyst';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components/macro';
import { Card } from '../../components';
import { AttributeError, RetailerError } from '../../services/ApiService';
import { camelCaseAttributeNameToGroup } from './attributeFields';
import { groupOrder } from './AttributeGroups';
import flag from './flag.svg';
import { groupToTitle, toKebab } from './utils';

interface ProductNavProps {
    attributeErrors: AttributeError[];
    retailerErrors: RetailerError[];
    upc: string;
}

const Image = styled.img`
    width: 1rem;
    height: 1rem;
    margin-right: 8px;
    margin-left: 6px;
    vertical-align: middle;
`;
const SideNav: typeof Card = styled(Card)`
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    margin: 0;
    padding-top: 32px;
    padding: 32px 12px 0 32px;
    position: sticky;
    top: 0;
    width: 170px;
    ${Button} {
        margin-bottom: 40px;
        width: fit-content;
    }
`;

const NavLinks = styled.section`
    display: flex;
    flex-direction: column;
    ${AmethystLink} {
        margin-bottom: 20px;
        font-size: 12px;
    }
`;
// The types aren't picking up the as prop for some reason
// Not sure why. Asserting for sanity.
SideNav.defaultProps = { as: 'nav' } as any;

export const ProductNav = ({ attributeErrors, retailerErrors }: ProductNavProps): ReactElement => {
    const location = useLocation();
    const hasWarning = (group: string): boolean => {
        const allErrors = [
            ...attributeErrors,
            ...retailerErrors.flatMap((retailerError) => retailerError.attributeErrors),
        ];
        return !!allErrors.find(
            ({ name }) => camelCaseAttributeNameToGroup.get(_.camelCase(name)) === group,
        );
    };

    return (
        <SideNav>
            <Button type="submit" {...{ form: 'product-form' }}>
                Save UPC
            </Button>
            <NavLinks>
                {groupOrder.map((group) => (
                    <AmethystLink
                        key={group}
                        as={HashLink}
                        smooth
                        to={`${location.pathname}${location.search}#${toKebab(group)}`}
                    >
                        {groupToTitle(group)}
                        {hasWarning(group) && (
                            <Image src={flag} className={'warning'} alt="Warning" />
                        )}
                    </AmethystLink>
                ))}
            </NavLinks>
        </SideNav>
    );
};
