import { useCallback, useEffect, useState } from 'react';
import { CompletionEventType } from '../../../constants/CompletionEventType';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { apiService, Campaign } from '../../../services/ApiService';

const useUpcWorkflowIsStarted = (user: string, upc: string) => {
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [userUpcRecordId, setUserUpcRecordId] = useState<string | null>(null);

    const startEvent = useCallback(() => {
        return apiService.insertStartEvent(upc, user).then((result) => {
            if (result.success) {
                setIsStarted(true);
                setUserUpcRecordId(result.recordId);
            }
            return result;
        });
    }, [upc, user]);

    const pauseEvent = useCallback(() => {
        setIsStarted(false);
        setUserUpcRecordId(null);
        return apiService.insertPauseEvent(userUpcRecordId);
    }, [userUpcRecordId]);

    useEffect(() => {
        apiService
            .getCurrentEventStatus(upc, user)
            .then((result) => {
                setUserUpcRecordId(result.recordId);
                setIsStarted(!!result.recordId);
            })
            .catch((e) => {
                console.error('getCurrentEventStatus error', e);
                setIsStarted(false);
            });
    }, [upc, user]);
    return { isStarted, startEvent, pauseEvent };
};

const useUpcWorkflowIsAssigned = (user: string, upc: string) => {
    const [isAssigned, setIsAssigned] = useState(false);
    useEffect(() => {
        apiService
            .getAssignmentStatus(user, upc)
            .then((result) => {
                setIsAssigned(result);
            })
            .catch((e) => {
                console.error('getAssignmentStatus error', e);
                setIsAssigned(false);
            });
    }, [upc, user]);
    return isAssigned;
};

const useUpcWorkflowCompleted = (user: string, upc: string, campaign?: Campaign) => {
    const completeEvent = useCallback(() => {
        if (campaign)
            return apiService.insertCompleteEvents(
                user,
                [upc],
                campaign,
                CompletionEventType.Single_Item_Coding,
            );
        throw new Error('Period and Campaign must be set to mark UPC complete.');
    }, [user, upc, campaign]);
    const undoCompleteEvent = useCallback(() => {
        if (campaign) return apiService.deleteCompleteEvents(user, [upc], campaign);
        throw new Error('Period and Campaign must be set to unmark UPC complete.');
    }, [user, upc, campaign]);
    return { completeEvent, undoCompleteEvent };
};

const useGetNextUpc = (user: string, upc: string, campaign?: Campaign) => {
    const { get } = useLocalStorage<string[]>();
    const [nextUpc, setNextUpc] = useState<string | undefined>(undefined);
    useEffect(() => {
        const period = campaign?.period;
        const bucket = campaign?.bucket;
        const key = period && bucket && `${user} ${period} ${bucket}`;
        const upcs = key ? (get(key) as string[]) ?? [] : [];
        const currentIndex = upcs.indexOf(upc);
        setNextUpc(upcs[currentIndex + 1]);
    }, [user, upc, campaign, get]);
    return nextUpc;
};

export interface WorkflowStatus {
    isStarted: boolean;
    startEvent: () => Promise<{ success: boolean; upc?: string; recordId: string | null }>;
    pauseEvent: () => void;
    isAssigned: boolean;
    completeEvent: () => void;
    undoCompleteEvent: () => void;
    nextUpc: string | undefined;
}

export const useWorkflow = (user: string, upc: string, campaign?: Campaign): WorkflowStatus => {
    const { isStarted, startEvent, pauseEvent } = useUpcWorkflowIsStarted(user, upc);
    const isAssigned = useUpcWorkflowIsAssigned(user, upc);
    const { completeEvent, undoCompleteEvent } = useUpcWorkflowCompleted(user, upc, campaign);
    const nextUpc = useGetNextUpc(user, upc, campaign);

    return {
        isStarted,
        startEvent,
        pauseEvent,
        completeEvent,
        undoCompleteEvent,
        isAssigned,
        nextUpc,
    };
};
