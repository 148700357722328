import { Button } from '@spins/amethyst';
import React, { ReactElement, useCallback, useState } from 'react';
import { CSVLink } from 'react-csv';

export interface DownloadCsvButtonProps {
    data?: any[];
}

export const DownloadCsvButton = ({ data }: DownloadCsvButtonProps): ReactElement => {
    const [csvRecords, setCsvRecords] = useState<any[]>([]);

    const getTableFilteredData = useCallback(() => {
        if (data) {
            setCsvRecords(data);
        }
    }, [data]);
    return (
        <CSVLink
            data={csvRecords}
            filename="data.csv"
            className={'hidden'}
            onClick={getTableFilteredData}
            target="_blank"
        >
            <Button disabled={data && data.length === 0} buttonType={'secondary'}>
                Export Selected Data
            </Button>
        </CSVLink>
    );
};
