import { useField } from 'formik';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Attribute } from '../../models';
import { PositioningConfiguration } from '../../models';
import { Enumerated } from './fields/Enumerated';

interface BrandPositionAndPositioningGroupProps {
    positioningGroup: Attribute;
    brandPosition: Attribute;
    positioningConfiguration: PositioningConfiguration;
}

// This need to be abstracted and combined with Hierarchical as they share very similar functionality
export const BrandPositionAndPositioningGroup = ({
    positioningGroup,
    brandPosition,
    positioningConfiguration,
}: BrandPositionAndPositioningGroupProps): ReactElement => {
    const [{ value: brandPositioningValue }, , { setValue: setBrandPositionValue }] = useField<
        string | undefined
    >('advancedCoding.brandPosition');
    const [{ value: positioningGroupValue }, , { setValue: setPositioningGroupValue }] = useField<
        string | undefined
    >('advancedCoding.positioningGroup');
    const brandPositionOptions = useMemo(
        () =>
            positioningGroupValue
                ? positioningConfiguration.get(positioningGroupValue)
                : brandPosition.options,
        [positioningGroupValue, positioningConfiguration, brandPosition],
    );

    useEffect(() => {
        if (positioningGroupValue === undefined || positioningGroupValue === '') {
            // eslint-disable-next-line prefer-const
            let value = '';
            for (const pair of positioningConfiguration.entries()) {
                const [key, vals] = pair;
                const v = brandPositioningValue && vals.includes(brandPositioningValue);
                if (v) {
                    value = key;
                    break;
                }
            }
            setPositioningGroupValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandPositioningValue]);

    return (
        <>
            <Enumerated
                attribute={{
                    ...positioningGroup,
                    options: Array.from(positioningConfiguration.keys()).sort(),
                }}
                name="advancedCoding.positioningGroup"
                clearFunction={() => {
                    setPositioningGroupValue('');
                    setBrandPositionValue('');
                }}
                className={'form-spacing'}
            />
            <Enumerated
                attribute={{ ...brandPosition, options: brandPositionOptions }}
                name="advancedCoding.brandPosition"
                className={'form-spacing'}
            />
        </>
    );
};
