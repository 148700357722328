import { Palette } from '@spins/amethyst';
import { useFormikContext } from 'formik';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Select, { StylesConfig, Theme, ValueType } from 'react-select';
import SelectAsync from 'react-select/async';
import CreatableSelectAsync from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { FormElement } from '../../../components';
import { Attribute } from '../../../models';
import { OptionPair } from '../../../services/ApiService';
import { AttributeHoverMenu } from '../AttributeHoverMenu';
import { AttributeError } from './AttributeError';

interface EnumeratedProps {
    attribute: Attribute;
    clearFunction?: () => void;
    className?: string;
    creatable?: boolean;
    tabIndex?: string;
}

export const reactSelectTheme = (theme: Theme): Theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'rgb(0, 115, 108)',
        primary25: 'rgb(219, 250, 247)',
        neutral20: '#929292',
        primary50: 'rgb(108, 213, 207)',
    },
});

const customStyles = (hasError: boolean): StylesConfig => ({
    control: (provided) => ({
        ...provided,
        // This will match the styles of the selects to that of the inputs
        paddingLeft: 8,
        border: hasError ? `1px solid ${Palette.red[80].hex}` : provided.border,
        borderRadius: '3px',
        '&:hover': {
            borderColor: hasError ? `${Palette.red[80].hex}` : provided.borderColor,
        },
    }),
});

export const Enumerated = ({
    attribute,
    creatable = false,
    tabIndex,
    clearFunction,
    name,
    loadOptions,
    options,
}: EnumeratedProps & {
    name?: string;
    loadOptions?: (value: string) => Promise<OptionPair[]>;
    options?: OptionPair[];
}): ReactElement => {
    const { getFieldProps, setFieldValue, getFieldMeta } = useFormikContext();
    const { error } = getFieldMeta(name!);
    const { value } = getFieldProps(name);
    const { options: attributeOpts } = attribute;
    const attributeOptions = useMemo(
        () =>
            options ||
            attributeOpts?.map((option) => ({
                label: option,
                value: option,
            })),
        [options, attributeOpts],
    );
    useEffect(() => {
        if (
            value !== '' &&
            !creatable &&
            attributeOptions?.find((opt) => opt.value === value) === undefined
        ) {
            setFieldValue(name!, '');
        }
        // We only want to reset the field when the options change and the value no longer exists within them
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeOptions]);
    const onChange = useCallback(
        (option: ValueType<{ label: string; value: string }>, action) => {
            if (action.action === 'clear' && clearFunction) {
                clearFunction();
                return;
            }
            // react-select has poor types that don't enforce single select
            const val = (option as { label: string; value: string } | undefined)?.value || '';
            setFieldValue(name!, val);
        },
        [clearFunction, setFieldValue, name],
    );

    // TODO: Remove this type annotation
    const Component: any = loadOptions
        ? creatable
            ? CreatableSelectAsync
            : SelectAsync
        : creatable
        ? CreatableSelect
        : Select;
    const componentProps = useMemo(() => {
        const baseComponentProps = {
            escapeClearsValue: true,
            isClearable: Boolean(value),
            name: attribute.name,
            onChange: onChange,
            styles: customStyles(Boolean(error)),
            tabIndex: tabIndex,
            theme: reactSelectTheme,
            value: { label: value, value: value },
        };
        return loadOptions
            ? { ...baseComponentProps, loadOptions, cacheOptions: true }
            : { ...baseComponentProps, options: attributeOptions };
    }, [attribute.name, attributeOptions, error, loadOptions, onChange, tabIndex, value]);

    return (
        <FormElement>
            <AttributeHoverMenu labelName={attribute.name} description={value} />
            <Component {...componentProps} aria-label={attribute.name} />
            {error && <AttributeError message={error} />}
        </FormElement>
    );
};
