export enum AttributeName {
    AGE = 'AGE',
    ALCOHOL_BY_VOLUME = 'ALCOHOL BY VOLUME',
    ALCOHOL_FREE = 'ALCOHOL FREE',
    ALLERGEN_FRIENDLY = 'ALLERGEN FRIENDLY',
    ALTERNATIVE_SWEETENER_TYPE = 'ALTERNATIVE SWEETENER TYPE',
    ALUMINUM_CHLOROHYDRATE_FREE = 'ALUMINUM CHLOROHYDRATE FREE',
    ALUMINUM_FREE = 'ALUMINUM FREE',
    AMERICAN_HEART_ASSOC = 'AMERICAN HEART ASSOC',
    ANCIENT_GRAIN = 'ANCIENT GRAIN',
    ANIMAL_TYPE = 'ANIMAL TYPE',
    ANIMAL_WELFARE = 'ANIMAL WELFARE',
    ANTI_AGING = 'ANTI AGING',
    ANTIOXIDANT = 'ANTIOXIDANT',
    B_CORPORATION = 'B CORPORATION',
    BEER_TYPE = 'BEER TYPE',
    BENEFIT = 'BENEFIT',
    BIODEGRADABLE = 'BIODEGRADABLE',
    BODY_CARE_TYPE = 'BODY CARE TYPE',
    CAGE_FREE = 'CAGE FREE',
    CALORIE = 'CALORIE',
    CERTIFIED_PALEO = 'CERTIFIED PALEO',
    CONVENTIONAL_CHANNEL = 'CONVENTIONAL CHANNEL',
    CORAL_REEF_SAFE = 'CORAL REEF SAFE',
    DEMETER_BIODYNAMIC = 'DEMETER BIODYNAMIC',
    DETOX_PROJECT_GLYPHOSATE_RESIDUE_FREE = 'DETOX PROJECT - GLYPHOSATE RESIDUE FREE',
    EATING_OCCASION = 'EATING OCCASION',
    EQ_UNIT_SIZE = 'EQ UNIT SIZE',
    EQ_UNIT_OF_MEASURE = 'EQ UNIT OF MEASURE',
    FAIR_FOR_LIFE = 'FAIR FOR LIFE',
    FAIR_TRADE = 'FAIR TRADE',
    FAIR_TRADE_USA = 'FAIR TRADE USA',
    FAT = 'FAT',
    FLAVOR = 'FLAVOR',
    FLUORIDE_FREE = 'FLUORIDE FREE',
    FORM = 'FORM',
    FORTIFICATION = 'FORTIFICATION',
    FRAGRANCE_FREE = 'FRAGRANCE FREE',
    FREE_RANGE = 'FREE RANGE',
    FUNCTIONAL_INGREDIENT = 'FUNCTIONAL INGREDIENT',
    FUNCTIONAL_SHOT = 'FUNCTIONAL SHOT',
    GENDER = 'GENDER',
    GFCO = 'GFCO',
    GLUTEN_FREE = 'GLUTEN FREE',
    GRAB_N_GO = 'GRAB N GO',
    GRAIN_FREE = 'GRAIN FREE',
    GRASS_FED = 'GRASS FED',
    GREENER_WORLD_ANIMAL_WELFARE = 'GREENER WORLD - ANIMAL WELFARE',
    GREENER_WORLD_GRASSFED = 'GREENER WORLD - GRASSFED',
    GREENER_WORLD_NON_GMO = 'GREENER WORLD - NON GMO',
    HEALTH_FOCUS = 'HEALTH FOCUS',
    HIGH_EFFICIENCY = 'HIGH EFFICIENCY',
    HOWGOOD_RATING = 'HOWGOOD RATING',
    FAIR_TRADE_AMERICA = 'FAIR TRADE AMERICA',
    INTERNATIONAL = 'INTERNATIONAL',
    LACTOSE_FREE = 'LACTOSE FREE',
    MARINE_STEWARDSHIP_COUNCIL = 'MARINE STEWARDSHIP COUNCIL',
    MASKED_BRAND = 'MASKED BRAND',
    MASKED_DESCRIPTION = 'MASKED DESCRIPTION',
    MASKED_UPC = 'MASKED UPC',
    MASTER_PRODUCTS_ACTIVE = 'MASTER PRODUCTS ACTIVE',
    NATURAL_PRODUCTS_ASSOC = 'NATURAL PRODUCTS ASSOC',
    NIELSEN_HOMESCAN_INCLUDE = 'NIELSEN HOMESCAN INCLUDE',
    NITRATE_FREE = 'NITRATE FREE',
    NO_ADDED_HORMONES = 'NO ADDED HORMONES',
    NO_ANTIBIOTICS_ADDED = 'NO ANTIBIOTICS ADDED',
    NON_GMO = 'NON-GMO',
    NON_GMO_PROJECT_VERIFIED = 'NON-GMO PROJECT VERIFIED',
    ORGANIC = 'ORGANIC',
    PACK_COUNT = 'PACK COUNT',
    PACKAGING_TYPE_PRIMARY = 'PACKAGING TYPE PRIMARY',
    PALEO = 'PALEO',
    PALEO_FOUNDATION_GRAIN_FREE = 'PALEO FOUNDATION - GRAIN FREE',
    PALEO_FRIENDLY = 'PALEO FRIENDLY',
    PALEO_KETO = 'PALEO KETO',
    PALEO_VEGAN = 'PALEO VEGAN',
    PARABEN_FREE = 'PARABEN FREE',
    PLANT_BASED = 'PLANT BASED',
    PLANT_BASED_FOODS_ASSOCIATION = 'PLANT BASED FOODS ASSOCIATION',
    PRE_AND_PROBIOTICS = 'PRE AND PROBIOTICS',
    PRIVATE_LABEL_PROPRIETARY = 'PRIVATE LABEL PROPRIETARY',
    PRIVATE_LABEL_CONVENTIONAL = 'PRIVATE LABEL CONVENTIONAL',
    PROCESSING_TREATMENT = 'PROCESSING TREATMENT',
    PRODUCT_TYPE = 'PRODUCT TYPE',
    PRODUCT_USE = 'PRODUCT USE',
    PROPRIETARY_CHANNEL = 'PROPRIETARY CHANNEL',
    PROPYLENE_GLYCOL_FREE = 'PROPYLENE GLYCOL FREE',
    RAW = 'RAW',
    SCENT = 'SCENT',
    SODIUM = 'SODIUM',
    SODIUM_LAURYL_SULFATE_FREE = 'SODIUM LAURYL SULFATE FREE',
    SPF_RATING = 'SPF RATING',
    SPINS_ALTERNATIVE_DOLLARS = 'SPINS ALTERNATIVE DOLLARS',
    SPINS_ALTERNATIVE_UNITS = 'SPINS ALTERNATIVE UNITS',
    SPINS_GOURMET_DOLLARS = 'SPINS GOURMET DOLLARS',
    SPINS_GOURMET_UNITS = 'SPINS GOURMET UNITS',
    SPINS_NATURAL_DOLLARS = 'SPINS NATURAL DOLLARS',
    SPINS_NATURAL_UNITS = 'SPINS NATURAL UNITS',
    SPROUTED = 'SPROUTED',
    STORAGE = 'STORAGE',
    SUPPLEMENT_TYPE = 'SUPPLEMENT TYPE',
    upc = 'upc',
    UPC_EAN_13 = 'UPC EAN 13',
    VEGAN = 'VEGAN',
    VEGETARIAN_FED_DIET = 'VEGETARIAN FED DIET',
    WHOLE_GRAINS_COUNCIL = 'WHOLE GRAINS COUNCIL',
    MANUFACTURER_CODE = 'MANUFACTURER CODE',
    BRAND_POSITION = 'BRAND POSITION',
    DEPARTMENT = 'DEPARTMENT',
    CATEGORY = 'CATEGORY',
    COMPANY = 'COMPANY',
    DESCRIPTION = 'DESCRIPTION',
    POSITIONING_GROUP = 'POSITIONING GROUP',
    SIZE = 'SIZE',
    SUBCATEGORY = 'SUBCATEGORY',
    UNIT_OF_MEASURE = 'UNIT OF MEASURE',
    BRAND = 'BRAND',
    PRIVATE_LABEL_CUSTOM_DESCRIPTION = 'PRIVATE LABEL CUSTOM DESCRIPTION',
    PRIVATE_LABEL_CUSTOM_BRAND = 'PRIVATE LABEL CUSTOM BRAND',
    RETAILER_TAG = 'RETAILER TAG',
    OWNERSHIP_ENTITY_ID = 'OWNERSHIP ENTITY ID',
}
