import React, { ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import {
    Button,
    Column,
    LoadingSpinner,
    SimpleTable,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import { BrandCategoryResult } from '../models';
import { apiService } from '../services/ApiService';
import { CardPage } from '../components';
import { Input } from './Product/fields/shared';

const Search = styled.div`
    align-items: flex-end;
    display: flex;
    margin-bottom: 16px;
    width: 250px;
    ${Button} {
        margin-left: 8px;
        height: 38px;
    }
`;

// This has to be declared out here to stop repeated regenerations
const columns: Column<BrandCategoryResult>[] = [
    { Header: 'Brand', accessor: 'brand' },
    { Header: 'Category', accessor: 'category' },
    { Header: 'UPC Count', accessor: 'count' },
];
const EMPTY_ARR: BrandCategoryResult[] = [];
export const ManufacturerCodeSearch = (): ReactElement => {
    const [inputValue, setInputValue] = useState('');
    const [manufacturerCode, setManufacturerCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState<BrandCategoryResult[]>();

    const tableInstance = useTable<BrandCategoryResult>(
        {
            columns: columns,
            data: results || EMPTY_ARR,
            totalItems: results?.length || 0,
            itemDescriptor: 'Brand Categories',
            initialState: {
                pageSize: 15,
            },
        },
        useFlexLayout,
        useSortBy,
        usePagination,
    );

    async function doSearch(): Promise<void> {
        setManufacturerCode(inputValue);

        if (!inputValue) {
            setResults([]);
            return;
        }
        setIsLoading(true);
        try {
            const result = await apiService.manufacturerCodeSearch(inputValue);
            setResults(result.brandCategoryResults);
        } catch (e) {
            setResults([]);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CardPage title="Manufacturer Code Results" style={{ minHeight: '300px' }}>
            <Search>
                <label>
                    6-digit code
                    <Input
                        name="mfrCode"
                        type="number"
                        value={inputValue}
                        onChange={({ target: { value } }) => setInputValue(value.slice(0, 6))}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                                doSearch();
                            }
                        }}
                    />
                </label>
                <Button size="small" onClick={doSearch}>
                    Search
                </Button>
            </Search>
            {manufacturerCode && (
                <div
                    className="manufacturer-code"
                    style={{ marginBottom: '16px', marginTop: '16px' }}
                >
                    Manufacturer Code: {manufacturerCode}
                </div>
            )}
            {results && !isLoading ? (
                <SimpleTable instance={tableInstance} />
            ) : (
                isLoading && <LoadingSpinner />
            )}
        </CardPage>
    );
};
