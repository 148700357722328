import React, { FC } from 'react';
import { ExternalLink, ExternalLinkProps } from './ExternalLink';
import { Body } from '@spins/amethyst';
import { PopOverMenu } from '../../../components/PopOverMenu';
import { css } from 'styled-components/macro';

export interface HeaderPopOverMenuProps {
    menuTitle: string;
    menuItems: ExternalLinkProps[];
}

const customStyle = css`
    top: 104px;
`;

export const HeaderLinkMenu: FC<HeaderPopOverMenuProps> = ({ menuItems, menuTitle }) => {
    const menuTitleElement = <Body style={{ marginRight: '4px' }}>{menuTitle}</Body>;
    return (
        <PopOverMenu menuTitle={menuTitleElement} customStyle={customStyle}>
            {menuItems.map((item, index) => (
                <ExternalLink url={item.url} content={item.content} key={index} showIcons={false} />
            ))}
        </PopOverMenu>
    );
};
