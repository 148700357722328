import { Palette } from '@spins/amethyst';
import { useField } from 'formik';
import React, { ReactElement, useCallback } from 'react';
import styled from 'styled-components/macro';
import { FormElement } from '../../../components';
import { AttributeName } from '../../../constants/attributeNames';
import { Attribute } from '../../../models';
import { AttributeHoverMenu } from '../AttributeHoverMenu';
import { AttributeError as AttributeErrorComponent } from './AttributeError';
import { Input } from './shared';

interface FreeTextProps {
    attribute: Attribute;
    className?: string;
    enableCounter?: boolean;
}

const ValidatedCount = styled.span<{ valid: boolean }>`
    align-self: center;
    margin-left: 8px;
    ${(props) => (props.valid ? '' : `color: ${Palette.red[80].hex};`)}
`;

export function getInvalidCharactersPattern(attributeName: Readonly<string>): string {
    switch (attributeName.toUpperCase()) {
        case AttributeName.COMPANY:
            return '[,]';
        default:
            return "[`'@$~|]";
    }
}

export function combineRegexWithDefault(regexOne: string): RegExp {
    const pattern = `${regexOne}|${getInvalidCharactersPattern('DEFAULT')}`;

    return new RegExp(pattern, 'g');
}

export function getInvalidCharactersRegex(attributeName: Readonly<string>): RegExp {
    const regularExpressionByEvent = getInvalidCharactersPattern(attributeName);
    return combineRegexWithDefault(regularExpressionByEvent);
}

const validCountLength = 45;
export const FreeText = ({
    attribute,
    enableCounter = false,
    name,
}: FreeTextProps & { name: string }): ReactElement => {
    const [inputProps, metaProps] = useField(name!);
    const { error } = metaProps;
    const sanitizeOnBlur = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.target.value = event.target.value.replace(
                getInvalidCharactersRegex(attribute.name),
                '',
            );
            return inputProps.onChange(event);
        },
        [attribute.name, inputProps],
    );
    return (
        <>
            <FormElement>
                <AttributeHoverMenu
                    labelName={attribute.name}
                    description={inputProps.value || ''}
                />
                <Input
                    hasError={Boolean(error)}
                    {...inputProps}
                    onChange={inputProps.onChange}
                    onBlur={sanitizeOnBlur}
                />
                {error && <AttributeErrorComponent message={error} />}
            </FormElement>
            {enableCounter && (
                <ValidatedCount
                    valid={!(inputProps.value && inputProps.value.length > validCountLength)}
                >
                    {inputProps.value && inputProps.value.length}
                </ValidatedCount>
            )}
        </>
    );
};
