import styled from 'styled-components/macro';
import { Colors } from '../../constants/colors';

export const SlothImg = styled.img`
    margin-top: 40px;
    height: 300px;
`;

export const BulkEditContainer = styled.div`
    margin: 40px;
`;

export const BulkGrid = styled.div`
    margin-top: 20px;
    display: inline-grid;
    color: darkred;
`;

export const UpcLabel = styled.label`
    margin-top: 20px;
    display: inline-grid;
    color: darkorange;
    text-decoration: underline;
`;

export const RejectedUpcLabel = styled(UpcLabel)`
    color: ${Colors.Red80};
`;

export const RejectedLabel = styled.div`
    color: ${Colors.Red80};
    font-size: 10px;
`;

export const FileUploadControls = styled.div`
    margin-top: 32px;
`;

export const BulkErrorListItem = styled.li`
    list-style-type: none;
    color: ${Colors.Neutral100};
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: normal;
`;
