export const labelClaimsAttributes = [
    'alcoholFree',
    'allergenFriendly',
    'aluminumChlorohydrateFree',
    'aluminumFree',
    'ancientGrain',
    'animalWelfare',
    'antiAging',
    'noAntibioticsAdded',
    'antioxidant',
    'cageFree',
    'coralReefSafe',
    'fairTrade',
    'fat',
    'fluorideFree',
    'fragranceFree',
    'freeRange',
    'glutenFree',
    'grainFree',
    'grassFed',
    'nitrateFree',
    'noAddedHormones',
    'nonGmo',
    'organic',
    'parabenFree',
    'propyleneGlycolFree',
    'sodiumLaurylSulfateFree',
    'sprouted',
    'vegan',
];
