import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
    Button,
    ComboBox,
    FilterBoxContainer,
    Header1,
    Header2,
    InputValidation,
    NotificationType,
    SelectItem,
    ToastProvider,
    useToasts,
    ValidationType,
} from '@spins/amethyst';
import { Field, FieldProps, Form, Formik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { AttributeName } from '../../constants/attributeNames';
import { apiService } from '../../services/ApiService';
import { Loader } from '../../components';
import {
    LookupValue,
    ProductHierarchyRecord,
    DynamicMigrationAction,
    DynamicMigrationTable,
} from '../../models';

interface SubcategoryAllowedValuesFormValues {
    subcategoryTag: string;
    attributeName: AttributeName | null;
    valueTag: string;
}

const validationSchema = yup.object<SubcategoryAllowedValuesFormValues>({
    subcategoryTag: yup.string().nullable().required('Please select a subcategory'),
    attributeName: yup
        .mixed<AttributeName>()
        .oneOf(Object.values(AttributeName), 'Please select an attribute'),
    valueTag: yup.string().required('Please select a value'),
});

const initialValues: SubcategoryAllowedValuesFormValues = {
    subcategoryTag: '',
    attributeName: null,
    valueTag: '',
};

interface FormikComboBoxProps<TVal> extends FieldProps<TVal | undefined> {
    label?: string;
    items: Array<SelectItem<TVal>>;
    disabled?: boolean;
}

const FormikComboBox = <TVal,>(props: FormikComboBoxProps<TVal>): ReactElement | null => {
    const {
        form,
        field: { name, value },
        items,
        label,
        disabled,
    } = props;
    const touched = form.touched[name];
    const error = form.errors[name];

    const validation: InputValidation | undefined =
        touched && error
            ? {
                  type: ValidationType.Error,
                  message: error as string,
              }
            : undefined;

    return (
        <ComboBox
            items={items}
            name={name}
            label={label}
            defaultValue={items.find((item) => item.value === value)}
            onChange={(item: any) => {
                console.log(name, 'onChange', item);
                form.setFieldValue(name, item?.value);
            }}
            validation={validation}
            disabled={disabled}
        />
    );
};

const AttributeNameSelector: FC<
    FieldProps<AttributeName | undefined> & { label?: string; lookupValues: LookupValue[] }
> = ({ lookupValues, ...otherProps }) => {
    const attributeValues = useMemo(
        () =>
            _(lookupValues)
                .map((lv) => lv.name)
                .uniq()
                .orderBy((name) => name)
                .map((name) => ({ label: name, value: name }))
                .value(),
        [lookupValues],
    );

    return <FormikComboBox items={attributeValues} {...otherProps} />;
};

const LookupValueSelector: FC<
    FieldProps<string> & {
        label?: string;
        attributeName?: AttributeName;
        lookupValues: LookupValue[];
    }
> = ({ lookupValues, attributeName, ...innerProps }) => {
    const filteredLookupValues = useMemo(
        () =>
            lookupValues
                .filter((lv) => lv.name === attributeName)
                .map(({ tag, description }) => ({
                    value: tag,
                    label: description,
                })),
        [attributeName, lookupValues],
    );

    return (
        <FormikComboBox
            items={filteredLookupValues}
            disabled={filteredLookupValues.length === 0}
            {...innerProps}
        />
    );
};

const SubcategoryComboBoxContainer = styled.div`
    ${FilterBoxContainer} {
        max-width: 1000px;
    }
`;

const SubcategoryTagSelector: FC<
    FieldProps<string> & { label?: string; hierarchy: ProductHierarchyRecord[] }
> = ({ hierarchy, ...otherProps }) => {
    const subcategories = useMemo(
        () =>
            hierarchy.map(
                ({
                    departmentDescription,
                    categoryDescription,
                    subcategoryDescription,
                    subcategoryTag,
                }) => ({
                    value: subcategoryTag,
                    label: `${departmentDescription} > ${categoryDescription} > ${subcategoryDescription}`,
                }),
            ),
        [hierarchy],
    );

    return (
        <SubcategoryComboBoxContainer>
            <FormikComboBox items={subcategories} {...otherProps} />
        </SubcategoryComboBoxContainer>
    );
};

export const DynamicMigrationSimpleFormProtoPage: FC = () => {
    const [hierarchy, setHierarchy] = useState<ProductHierarchyRecord[]>([]);
    const [lookupValues, setLookupValues] = useState<LookupValue[]>([]);

    const { addToast } = useToasts();

    useEffect(() => {
        apiService.getProductHierarchy().then(setHierarchy);
        apiService.getLookupValues().then(setLookupValues);
    }, []);

    return (
        <ToastProvider>
            <div style={{ margin: '32px' }}>
                <div>
                    <Header1>Dynamic Migrations</Header1>
                </div>
                <Header2>Add Subcategory Allowed Value</Header2>
                <Loader isLoading={hierarchy.length === 0 || lookupValues.length === 0}>
                    <Formik<SubcategoryAllowedValuesFormValues>
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            console.log('onSubmit', values);
                            apiService
                                .addDynamicMigrations([
                                    {
                                        action: DynamicMigrationAction.add,
                                        table: DynamicMigrationTable.SUBCATEGORY_ALLOWED_VALUES,
                                        newValues: {
                                            subcategoryTag: values.subcategoryTag,
                                            attributeName: values.attributeName!,
                                            valueTag: values.valueTag,
                                        },
                                    },
                                ])
                                .then(
                                    () =>
                                        addToast('Submit successful', {
                                            appearance: NotificationType.Success,
                                        }),
                                    (err) =>
                                        addToast('Submit failed ' + err, {
                                            appearance: NotificationType.Error,
                                        }),
                                );

                            return;
                        }}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                    >
                        {(formikProps) => {
                            console.log('touched', formikProps.touched);
                            console.log('errors', formikProps.errors);
                            return (
                                <Form>
                                    <Field
                                        name="subcategoryTag"
                                        label={'Subcategory'}
                                        component={SubcategoryTagSelector}
                                        hierarchy={hierarchy}
                                    />
                                    <Field
                                        name="attributeName"
                                        label={'Attribute Name'}
                                        component={AttributeNameSelector}
                                        lookupValues={lookupValues}
                                    />
                                    <Field
                                        name="valueTag"
                                        label={'Value'}
                                        component={LookupValueSelector}
                                        lookupValues={lookupValues}
                                        attributeName={formikProps.values.attributeName}
                                    />
                                    <Button size={'large'}>Submit</Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </Loader>
            </div>
        </ToastProvider>
    );
};
