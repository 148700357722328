import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, ConfirmationModal, NotificationType, useToasts } from '@spins/amethyst';
import { AttributeName } from '../../constants/attributeNames';
import { Attribute, AttributeType } from '../../models';
import { apiService } from '../../services/ApiService';
import { SlothSpinner } from '../../components';
import { ReadOnly } from './ReadOnlyInput';
import { SelectWithSearch } from './SelectWithSearch';

interface EditOwnershipEntityFormProps {
    ownershipId: number;
    manufacturerCode: string;
    brandAttribute: Attribute;
    companyAttribute: Attribute | undefined;
}

export const EditOwnershipEntityForm = ({
    ownershipId,
    manufacturerCode,
    brandAttribute,
    companyAttribute,
}: EditOwnershipEntityFormProps): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [brandDescription, setBrandDescription] = useState<string>(
        brandAttribute.description || '',
    );
    const [companyDescription, setCompanyDescription] = useState<string>(
        (companyAttribute && companyAttribute.description) || '',
    );
    const [mergeWarningMessage, setMergeWarningMessage] = useState<string>('');
    const { addToast } = useToasts();
    const history = useHistory();

    const handleBrandChange = async (brandName: string): Promise<void> => {
        setBrandDescription(brandName);
        const results = await apiService.checkOwnershipEntityMerge({
            id: ownershipId,
            manufacturerCode: manufacturerCode,
            brand: {
                name: AttributeName.BRAND,
                description: brandName,
                type: AttributeType.BRAND_NAME,
            },
            company: {
                name: AttributeName.COMPANY,
                description: companyDescription,
                type: AttributeType.COMPANY,
            },
        });
        setMergeWarningMessage(results.message);
    };

    const handleCompanyChange = async (companyName: string): Promise<void> => {
        setCompanyDescription(companyName);
        const results = await apiService.checkOwnershipEntityMerge({
            id: ownershipId,
            manufacturerCode: manufacturerCode,
            brand: {
                name: AttributeName.BRAND,
                description: brandDescription,
                type: AttributeType.BRAND_NAME,
            },
            company: {
                name: AttributeName.COMPANY,
                description: companyName,
                type: AttributeType.COMPANY,
            },
        });
        setMergeWarningMessage(results.message);
    };

    const handleSubmit = async (): Promise<void> => {
        const ownershipEntity = {
            id: ownershipId,
            manufacturerCode: manufacturerCode,
            brand: {
                name: AttributeName.BRAND,
                description: brandDescription,
                type: AttributeType.BRAND_NAME,
            },
            company: {
                name: AttributeName.COMPANY,
                description: companyDescription,
                type: AttributeType.COMPANY,
            },
        };
        // clear out any merge warnings
        setMergeWarningMessage('');
        // TODO: possibly display error/warning messages in response object to screen
        try {
            setLoading(true);
            const responseObject = await apiService.putOwnershipEntity(ownershipEntity);
            history.push(`/editOwnershipEntity/${responseObject.ownershipEntityId}`);
            addToast('Save Successful', {
                appearance: NotificationType.Info,
                autoDismiss: true,
                autoDismissTimeout: 8000,
            });
            addToast(`${responseObject.upcsUpdated} UPCs have been updated`, {
                appearance: NotificationType.Success,
                autoDismiss: true,
                autoDismissTimeout: 8000,
            });
        } catch (_e) {
            addToast('Save Unsuccessful', {
                appearance: NotificationType.Error,
                autoDismiss: true,
                autoDismissTimeout: 8000,
            });
        }
        setLoading(false);
    };

    return (
        <div>
            <ReadOnly
                attribute={{
                    name: AttributeName.OWNERSHIP_ENTITY_ID,
                    description: ownershipId.toString(),
                    type: AttributeType.READ_ONLY,
                }}
            />
            <ReadOnly
                attribute={{
                    name: AttributeName.MANUFACTURER_CODE,
                    description: manufacturerCode,
                    type: AttributeType.READ_ONLY,
                }}
            />
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <SelectWithSearch
                attribute={{
                    name: AttributeName.BRAND,
                    description: brandDescription,
                    type: AttributeType.BRAND_NAME,
                }}
                className={'form-spacing'}
                loadOptions={apiService.brandSearchV2.bind(apiService)}
                onChange={handleBrandChange}
            />

            <SelectWithSearch
                attribute={{
                    name: AttributeName.COMPANY,
                    description: companyDescription,
                    type: AttributeType.COMPANY,
                }}
                className={'form-spacing'}
                loadOptions={apiService.companySearchV2.bind(apiService)}
                onChange={handleCompanyChange}
            />
            {mergeWarningMessage ? (
                <ConfirmationModal
                    onConfirm={handleSubmit}
                    onReject={jest.fn()}
                    message={`${mergeWarningMessage}\nAre you sure you want to do this?`}
                    title={'Confirm Merge'}
                    actuator={<Button>Save</Button>}
                />
            ) : (
                <Button disabled={loading} onClick={handleSubmit}>
                    Save
                </Button>
            )}

            {loading && <SlothSpinner />}
        </div>
    );
};
