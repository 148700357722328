import React, { ComponentProps, PropsWithChildren } from 'react';
import { Header1 } from '@spins/amethyst';
import styled from 'styled-components/macro';
import { Card } from './Card';
import { Page } from './Page';

const Title = styled(Header1)`
    margin-top: 0;
    margin-bottom: 16px;
`;

export const CardPage = ({
    title,
    className,
    children,
    ...props
}: PropsWithChildren<{
    className?: string;
    title: string;
}> &
    Partial<ComponentProps<typeof Page>>): JSX.Element => {
    return (
        <Page className={className} {...props}>
            <Card style={{ height: '100%' }}>
                <Title>{title}</Title>
                {children}
            </Card>
        </Page>
    );
};
