import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Palette } from '@spins/amethyst';
import { FormElement } from '../../components';
import { Attribute } from '../../models';
import { AttributeHoverMenu } from '../Product/AttributeHoverMenu';

interface ReadOnlyProps {
    attribute: Attribute;
    className?: string;
}

const ReadOnlyInput = styled.input`
    background-color: ${Palette.neutral[15].hex};
    border-radius: 3px;
    border: solid 1px ${Palette.neutral[50].hex};
    font-size: 14px;
    height: 38px;
    max-width: 50%;
    padding: 0 16px;
`;

export const ReadOnly = ({ attribute }: ReadOnlyProps): ReactElement => {
    return (
        <FormElement>
            <AttributeHoverMenu
                labelName={attribute.name}
                description={attribute.description || ''}
            />
            <ReadOnlyInput
                type="text"
                value={attribute.description}
                readOnly
                className={`read-only`}
            />
        </FormElement>
    );
};
