import { useCallback } from 'react';

type Setter<T extends unknown> = (key: string, value: T) => void;
type Getter<T extends unknown> = (key: string) => Optional<T> | Nullable<T>;

export interface UseLocalStorage<T extends unknown> {
    get: Getter<T>;
    set: Setter<T>;
}

export const useLocalStorage = <T extends unknown>(): UseLocalStorage<T> => {
    const set: Setter<T> = useCallback(
        (key, value) => localStorage.setItem(key, JSON.stringify(value)),
        [],
    );
    const get: Getter<T> = useCallback((key: string) => {
        const stringValue = localStorage.getItem(key);
        return stringValue === null ? stringValue : JSON.parse(stringValue);
    }, []);
    return { set, get };
};
