import { Input, Button, Times } from '@spins/amethyst';
import React, { ReactElement, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

export const AddUpcFilterDialogBackground = styled.div`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
`;

const AddUpcFilterDialogContainer = styled.div`
    background-color: white;
    border-radius: 0.1875rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    z-index: 1001;
    padding: 0 10px;
`;

export const AddUpcFilterDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 8px;
`;

export const AddUpcFilterDialogTextAreaContainer = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 21px;
`;

const AddUpcFilterDialogButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const SpacedButton = styled(Button)`
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 25px;
`;

export interface AddUpcFilterDialogProps {
    close: () => void;
    upcFilter: string;
    setUpcFilter: (value: string) => void;
}

// TODO: This needs to be replaced with a proper modal that supports a11y
export const EditUPCFilterPopup = ({
    close,
    upcFilter,
    setUpcFilter,
}: AddUpcFilterDialogProps): ReactElement => {
    const [value, setValue] = React.useState(upcFilter);

    const handleApplyFilter = useCallback(() => {
        setUpcFilter(value);
    }, [value, setUpcFilter]);

    return (
        <>
            {ReactDOM.createPortal(
                <AddUpcFilterDialogContainer aria-labelledby="upc-filter-label">
                    <AddUpcFilterDialogHeader>
                        <div id="upc-filter-label" style={{ lineHeight: '14px' }}>
                            UPC filter
                        </div>
                        <Button
                            type="button"
                            buttonType="icon"
                            aria-label="close dialog"
                            icon={Times}
                            onClick={close}
                        />
                    </AddUpcFilterDialogHeader>
                    <AddUpcFilterDialogTextAreaContainer>
                        <Input
                            name={'upcFilter'}
                            type={'textarea'}
                            onChange={setValue}
                            label={''}
                            placeholder={'Paste a list of UPCs to filter results'}
                            value={value}
                        />
                    </AddUpcFilterDialogTextAreaContainer>
                    <AddUpcFilterDialogButtonContainer>
                        <SpacedButton buttonType={'secondary'} onClick={close}>
                            Cancel
                        </SpacedButton>
                        <SpacedButton onClick={handleApplyFilter}>Apply filter</SpacedButton>
                    </AddUpcFilterDialogButtonContainer>
                </AddUpcFilterDialogContainer>,
                document.body,
            )}
            {ReactDOM.createPortal(<AddUpcFilterDialogBackground />, document.body)}
        </>
    );
};
EditUPCFilterPopup.displayName = 'EditUPCFilterPopup';
