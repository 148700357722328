import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
    Button,
    NotificationType,
    OverflowType,
    useFilters,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import { Row, useGlobalFilter } from 'react-table';
import styled from 'styled-components/macro';
import { SnapshotQcReportDetailRecord } from '../../models';
import { apiService } from '../../services/ApiService';
import { SlothSpinner } from '../../components/SlothSpinner';
import { SimpleToast } from '../../components/SimpleToast';
import { DownloadCsvButton } from './DownloadCsvButton';
import { SnapshotQcDetailFilterButtons } from './Table/ButtonRow/SnapshotQcDetailFilterButtons';
import { detailColumns, hiddenColumnKeys } from './Table/detailColumns';
import { globalFilter } from './Table/filters';
import { SnapshotQcTable } from './Table/SnapshotQcTable';
import { CardPage } from '../../components';
export { SnapshotQcSummary } from './SnapshotQcSummary';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    & > div {
        display: flex;
        gap: 8px;
    }
`;

export const SnapshotQcDetail = (): ReactElement => {
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<NotificationType>(
        NotificationType.Success,
    );
    const [toastMessage, setToastMessage] = useState<string>('');
    const [data, setData] = useState<SnapshotQcReportDetailRecord[]>([]);
    const tableInstance = useTable(
        {
            columns: detailColumns,
            data,
            columnOverflow: OverflowType.Wrap,
            // @ts-expect-error This needs to be fixed in Amethyst types and then can be removed
            globalFilter,
            initialState: {
                hiddenColumns: hiddenColumnKeys,
            },
        },
        useGlobalFilter,
        useFilters,
        useFlexLayout,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        setIsRunning(true);
        apiService
            .getMasterSnapshotQcReportDetailData()
            .then((foundRecords) => {
                setData(foundRecords);
                setIsRunning(false);
            })
            .catch((err) => console.error(err));
    }, []);

    const runReports = useCallback(async (): Promise<void> => {
        setIsRunning(true);
        try {
            await apiService.runMasterSnapshotQcReports();
            setToastMessage('QC Reports Finished');
            setData(await apiService.getMasterSnapshotQcReportDetailData());
        } catch (e) {
            setNotificationType(NotificationType.Error);
            setToastMessage(`Something went wrong: ${e.message}`);
        } finally {
            setIsRunning(false);
        }
    }, [setIsRunning, setToastMessage, setNotificationType]);

    return (
        <CardPage title={'Product Intelligence Snapshot QC Reports'}>
            {isRunning && <SlothSpinner />}
            <SimpleToast type={notificationType} message={toastMessage} />
            <ButtonContainer>
                <div>
                    <Button onClick={runReports} disabled={isRunning}>
                        Run Reports
                    </Button>
                    <DownloadCsvButton
                        data={
                            tableInstance && tableInstance.filteredRows
                                ? tableInstance.filteredRows.map((row: Row<any>) => row.original)
                                : []
                        }
                    />
                </div>
                <SnapshotQcDetailFilterButtons
                    isLoading={isRunning}
                    tableInstance={tableInstance}
                />
            </ButtonContainer>
            {!isRunning && <SnapshotQcTable instance={tableInstance} />}
        </CardPage>
    );
};
