import { Button, Link as AmethystLink, NotificationType, Select, useToasts } from '@spins/amethyst';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAuth0 } from '../../auth0';
import { DropUpComponent } from '../../components/DropUp';
import { apiService, Campaign } from '../../services/ApiService';
import { useWorkflow } from './hooks';

export interface TaskbarProps {
    upc: string;
    campaign?: Campaign;
}

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: black;
    opacity: 0.5;
`;

export const TaskbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 48px;
    position: sticky;
    bottom: 0;
    background: white;
    box-shadow: 0px -1px 0px rgba(37, 37, 37, 0.05), 0px 1px 3px rgba(37, 37, 37, 0.15);
    z-index: 3;
    justify-content: space-between;
    align-items: center;
`;

const TaskbarLeft = styled.div`
    display: flex;
    padding: 8px;
    margin-left: 10px;
`;

const TaskbarRight = styled.div`
    display: flex;
    padding: 8px;
    margin-right: 10px;
`;

const StyledLink = styled(Link)`
    all: unset;
    align-items: center;
    cursor: pointer;
    display: flex;
    text-decoration: none;
`;

export const LinkButton = styled(Button)
    .withConfig({
        shouldForwardProp: (prop, defaultValidatorFn) =>
            !['small'].includes(prop as string) && defaultValidatorFn(prop),
    })
    .attrs(() => ({ as: StyledLink }))``;

const getProductRoute = (upc: string, campaign?: Campaign) =>
    `/product/${upc}` + (campaign ? `?period=${campaign.period}&bucket=${campaign.bucket}` : '');

export const Taskbar: FC<TaskbarProps> = ({ upc, campaign }) => {
    // at this point we are guaranteed to be authenticated
    const {
        user: { nickname: user },
    } = useAuth0()!;
    const [statusChanging, setStatusChanging] = useState(false);
    const history = useHistory();

    const {
        isStarted,
        startEvent,
        pauseEvent,
        completeEvent,
        undoCompleteEvent,
        isAssigned,
        nextUpc,
    } = useWorkflow(user, upc, campaign);
    const { addToast } = useToasts();
    const [updateState, setUpdateState] = useState(true);
    const [campaignOptions, setCampaignOptions] = useState<{ label: string; value: Campaign }[]>(
        [],
    );
    const [currentCampaignIndex, setCurrentCampaignIndex] = useState<number>(-1);

    useEffect(() => {
        if (updateState) {
            setUpdateState(false);
            apiService.getMyWorkSummaryDataForUser(user, upc).then((summaries) => {
                const currentBucket = summaries.findIndex(
                    ({ campaign: summaryCampaign }) =>
                        summaryCampaign.period === campaign?.period &&
                        summaryCampaign.bucket === campaign.bucket,
                );
                setCampaignOptions(
                    summaries.map((summary) => ({
                        label: `${summary.campaign.period} ${summary.campaign.bucket} (${summary.upcsCompleted}/${summary.upcCount})`,
                        value: summary.campaign,
                    })),
                );
                setCurrentCampaignIndex(currentBucket);
            });
        }
    }, [user, upc, campaign, updateState]);

    const handleStartPauseClick = useCallback(async () => {
        setStatusChanging(true);
        setUpdateState(true);
        try {
            if (!isStarted) {
                undoCompleteEvent();
                const startAttempt = await startEvent();
                if (!startAttempt.success) {
                    addToast(
                        <>
                            You must pause UPC&nbsp;
                            <AmethystLink
                                as={Link}
                                style={{ fontSize: 'inherit' }}
                                to={`/product/${startAttempt.upc}`}
                            >
                                {startAttempt.upc}
                            </AmethystLink>
                            &nbsp;before starting a new time tracking session.
                        </>,
                        {
                            appearance: NotificationType.Error,
                            autoDismiss: true,
                            autoDismissTimeout: 8000,
                        },
                    );
                }
            } else {
                pauseEvent();
            }
        } finally {
            setStatusChanging(false);
        }
    }, [addToast, isStarted, pauseEvent, startEvent, undoCompleteEvent]);

    const handleMarkCompleteClick = () => {
        completeEvent();
        setUpdateState(true);
        pauseEvent();
    };

    const myAssignmentsLinkUrl = useMemo(() => {
        const campaignFragment = campaign ? `/${campaign.period}/${campaign.bucket}` : '';
        return `/mywork/${user}${campaignFragment}`;
    }, [user, campaign]);

    return (
        <>
            {isAssigned && !isStarted && <Overlay data-testid="taskbar-overlay" />}
            {isAssigned && (
                <TaskbarContainer>
                    <TaskbarLeft>
                        <LinkButton
                            to={myAssignmentsLinkUrl}
                            buttonType={'quaternary'}
                            size={'small'}
                        >
                            Back to assignments
                        </LinkButton>
                    </TaskbarLeft>
                    <DropUpComponent>
                        <Select
                            value={campaignOptions[currentCampaignIndex]}
                            items={campaignOptions}
                            name="campaigns"
                            onChange={(option) => {
                                history.push(getProductRoute(upc, option.value));
                            }}
                            aria-label={'select a campaign'}
                            placeholder={'Select a campaign'}
                            isClearable={false}
                            disabled={isStarted}
                        />
                    </DropUpComponent>
                    <TaskbarRight>
                        {!isStarted && nextUpc && (
                            <LinkButton
                                size={'small'}
                                disabled={!campaign}
                                to={getProductRoute(nextUpc, campaign)}
                            >
                                Next UPC
                            </LinkButton>
                        )}
                        {isStarted && (
                            <Button size={'small'} onClick={handleMarkCompleteClick}>
                                Mark Complete
                            </Button>
                        )}
                        <span style={{ marginLeft: '4px' }}>
                            <Button
                                onClick={() => handleStartPauseClick()}
                                buttonType={'primary'}
                                size={'small'}
                                disabled={statusChanging}
                            >
                                {isStarted ? 'Pause' : 'Start'}
                            </Button>
                        </span>
                    </TaskbarRight>
                </TaskbarContainer>
            )}
        </>
    );
};
