import React from 'react';
import { Attribute, AttributeGroupProps } from '../../models';
import { AttributeGroupSection } from './AttributeGroupSection';

import { Enumerated } from './fields';

export const EnumeratedSection = React.memo(
    ({
        attributeKeys,
        attributes,
        className = '',
        groupName,
        sectionName,
    }: {
        attributes: Record<string, Attribute | undefined>;
        attributeKeys: string[];
        sectionName: string;
    } & AttributeGroupProps): Nullable<JSX.Element> => {
        const fields =
            attributes !== undefined
                ? attributeKeys.map<[string, Attribute | undefined]>((key) => [
                      key,
                      attributes[key],
                  ])
                : [];
        return (
            <AttributeGroupSection className={className} groupName={groupName}>
                {fields.map(
                    ([fieldName, field]) =>
                        field && (
                            <Enumerated
                                key={fieldName}
                                attribute={field}
                                name={`${sectionName}.${fieldName}`}
                                className={'form-spacing'}
                            />
                        ),
                )}
            </AttributeGroupSection>
        );
    },
);
EnumeratedSection.displayName = 'EnumeratedSection';
