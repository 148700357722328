import { useCallback } from 'react';
import { NotificationType, useToasts } from '@spins/amethyst';

export const useShowToast = (): ((status: number, message: string, isSuccess: boolean) => void) => {
    const { addToast } = useToasts();
    const showToast = useCallback(
        (status: number, message: string, isSuccess: boolean) => {
            if (status === 200) {
                addToast('Saved', {
                    appearance: NotificationType.Info,
                    autoDismiss: true,
                    autoDismissTimeout: 8000,
                });
                const qcMessage = isSuccess ? 'QC - Passed' : `QC - ${message}`;
                addToast(qcMessage, {
                    appearance: isSuccess ? NotificationType.Success : NotificationType.Error,
                    autoDismiss: true,
                    autoDismissTimeout: 8000,
                });
            } else {
                const errorMessage = message ? `: ${message}` : '';
                addToast(`Save Unsuccessful${errorMessage}`, {
                    appearance: NotificationType.Error,
                    autoDismiss: true,
                    autoDismissTimeout: 8000,
                });
            }
        },
        [addToast],
    );
    return showToast;
};
