import { AttributeError, RetailerError } from '../services/ApiService';
import { Attribute } from './Attribute';
import { Retailer } from './Retailer';

export interface Product {
    upc: string;
    initialAttributes: Attribute[];
    attributeErrors: AttributeError[];
    retailerErrors: RetailerError[];
    attributes: Attribute[];
    retailer: Retailer;
    isFrozen: boolean;
    isLoading: boolean;
}

export const createProduct = (
    upc: string,
    attributes: Attribute[] = [],
    retailer: Retailer = {
        retailerInfo: [],
        retailerTags: [],
    },
    attributeErrors: AttributeError[] = [],
    retailerErrors: RetailerError[] = [],
    isFrozen = false,
    isLoading = false,
): Product => ({
    upc,
    attributes,
    retailer,
    initialAttributes: attributes,
    attributeErrors,
    retailerErrors,
    isFrozen,
    isLoading,
});
