import React, { FC, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Button, fontStyles, Header1, Input, NotificationType, useToasts } from '@spins/amethyst';
import { ApiResult, apiService } from '../services/ApiService';
import parseCsvFile from '../utilities/FileUtils';
import { CardPage, SlothSpinner, FileUpload } from '../components';

const INITIAL_UPCS: string[] = [];

const ButtonLabel = styled.label`
    display: block;
    margin-bottom: 8px;
    font: ${fontStyles.Header3};
`;

const ReportButton = styled(Button)`
    text-transform: uppercase;
`;

const ButtonGrid = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: min-content max-content;
`;

const AdditionalUpcs = styled.div`
    label {
        display: block;
        font-size: 0.875rem;
        margin: 2px 0 4px 0;
    }
`;

const SectionTitle = styled(Header1)`
    grid-column: 1/-1;
    margin-top: 32px;
`;
SectionTitle.defaultProps = { as: 'h2' };

// TODO: This page is bad and should feel bad.
// We need to have an accurate page title and sections
// so that we meet a11y standards for the headers
// and aren't just stupid heads
export const GenerateNewItemsReturnedMonthlyFileContainer: FC = (): ReactElement => {
    const [showLoader, setShowLoader] = useState(false);
    const [week, setWeek] = useState<number | null>(null);
    const { addToast } = useToasts();
    const [upcs, setAdditionalUpcs] = useState<string[]>(INITIAL_UPCS);
    const getNewItemsReturnedMonthlyFile = useCallback(
        async (isQA: boolean): Promise<void> => {
            setShowLoader(true);
            if (week !== null) {
                const response = await apiService.getNewItemsReturnedMonthlyFile(week, upcs, isQA);
                setShowLoader(false);
                const message = response.succeeded
                    ? '🎉🥂🍻🥂🎉'
                    : 'Something went horribly wrong, go bug Ben about it!';
                addToast(message, {
                    appearance: response.succeeded
                        ? NotificationType.Success
                        : NotificationType.Error,
                });
            }
        },
        [addToast, upcs, week],
    );

    const setToast = useCallback(
        (response: boolean | ApiResult, message?: string): void => {
            const succeeded = typeof response !== 'boolean' ? response.succeeded : false;
            const toastMessage = message || (succeeded ? 'Success!' : 'Failed!');
            addToast(toastMessage, {
                appearance: succeeded ? NotificationType.Success : NotificationType.Error,
            });
        },
        [addToast],
    );

    const freezeUpcs = useCallback(async (): Promise<void> => {
        setShowLoader(true);
        const response =
            week !== null && (await apiService.freezeNewItemsReturned(week, upcs, 'MONTHLY_NIR'));
        setShowLoader(false);
        setToast(response);
    }, [setToast, upcs, week]);

    const unfreezeUpcs = useCallback(
        async (type: string): Promise<void> => {
            setShowLoader(true);
            const response = await apiService.unfreeze(type);
            setShowLoader(false);
            setToast(response);
        },
        [setToast],
    );

    const refreshIri = useCallback(
        async (isPreview: boolean): Promise<void> => {
            setShowLoader(true);
            const response = await apiService.refreshIri(isPreview);
            const message = response.succeeded
                ? '🎉🥂🍻🥂🎉'
                : 'Something went horribly wrong, go bug Ben about it!';
            setShowLoader(false);

            setToast(response, message);
        },
        [setToast],
    );

    const restatementIri = useCallback(
        async (isPreview: boolean): Promise<void> => {
            setShowLoader(true);
            const response = await apiService.restatementIri(isPreview);
            const message = response.succeeded
                ? '🎉🥂🍻🥂🎉'
                : 'Something went horribly wrong, go bug Ben about it!';
            setShowLoader(false);

            setToast(response, message);
        },
        [setToast],
    );

    const freezeIriRefresh = useCallback(async (): Promise<void> => {
        setShowLoader(true);
        const response = await apiService.freezeIriRefresh();
        const message = response.succeeded
            ? '🎉🥂🍻🥂🎉'
            : 'Something went horribly wrong, go bug Ben about it!';
        setShowLoader(false);
        setToast(response, message);
    }, [setToast]);

    const handleFileChange = useCallback(async (files: FileList | null): Promise<void> => {
        if (!files) {
            return;
        }
        const file = files[0];
        if (!file) {
            return;
        }
        const parseResult = await parseCsvFile<{ upc: string } | { UPC: string }>(file);
        const typedUpcs: string[] = parseResult.data.map((datum) =>
            'upc' in datum ? datum.upc : datum.UPC,
        );
        setAdditionalUpcs(typedUpcs);
    }, []);

    return (
        <CardPage title="NIR">
            <ButtonGrid>
                <Input
                    name="weekNumberInput"
                    id="weekNumberInput"
                    type="number"
                    label="Week Number"
                    onChange={(value: number) => setWeek(value ? Number(value) : null)}
                />
                <AdditionalUpcs>
                    <label htmlFor="additional-upcs" className="iri-additional-upcs-label">
                        Additional Product UPCs
                    </label>
                    <FileUpload
                        handleFileChange={(event) => handleFileChange(event.target.files)}
                        error={
                            upcs !== INITIAL_UPCS && upcs.length === 0
                                ? 'No UPC Data Found In Selected File'
                                : ''
                        }
                        id="additional-upcs"
                    />
                </AdditionalUpcs>
                <div>
                    <ButtonLabel>Create NIR QA File</ButtonLabel>
                    <ReportButton
                        id="createNirPreview"
                        disabled={week === null}
                        onClick={() => getNewItemsReturnedMonthlyFile(true)}
                    >
                        Pull NIR Preview
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Freeze UPCs</ButtonLabel>
                    <ReportButton id="freezeUpcs" disabled={week === null} onClick={freezeUpcs}>
                        Freeze NIR UPCs
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Create NIR File</ButtonLabel>
                    <ReportButton
                        id="createNir"
                        disabled={week === null}
                        onClick={() => getNewItemsReturnedMonthlyFile(false)}
                    >
                        Pull NIR
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Unfreeze All Monthly NIR frozen UPCs</ButtonLabel>
                    <ReportButton id="unfreezeUpcs" onClick={() => unfreezeUpcs('MONTHLY_NIR')}>
                        Unfreeze NIR UPCs
                    </ReportButton>
                </div>

                <SectionTitle>IRI Refresh</SectionTitle>
                <div>
                    <ButtonLabel>Create IRI Refresh Preview File</ButtonLabel>
                    <ReportButton id="iriRefreshPreview" onClick={() => refreshIri(true)}>
                        Pull IRI Refresh Preview
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Freeze IRI Refresh UPCs</ButtonLabel>
                    <ReportButton id="freezeIriRefresh" onClick={() => freezeIriRefresh()}>
                        Freeze IRI Refresh UPCs
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Create IRI Refresh File</ButtonLabel>
                    <ReportButton id="iriRefresh" onClick={() => refreshIri(false)}>
                        Pull IRI Refresh
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Unfreeze All IRI Refresh frozen UPCs</ButtonLabel>
                    <ReportButton
                        id="unfreezeIriRefresh"
                        onClick={() => unfreezeUpcs('REFRESH_NIR')}
                    >
                        Unfreeze IRI Refresh UPCs
                    </ReportButton>
                </div>

                <SectionTitle>IRI Restatement</SectionTitle>
                <div>
                    <ButtonLabel>Create IRI Restatement Preview File</ButtonLabel>
                    <ReportButton id="iriRestatementPreview" onClick={() => restatementIri(true)}>
                        Pull IRI Restatement Preview
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Freeze UPCs</ButtonLabel>
                    <ReportButton id="freezeIriRestatement" onClick={() => freezeIriRefresh()}>
                        Freeze IRI Restatement UPCs
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Create IRI Restatement File</ButtonLabel>
                    <ReportButton id="iriRestatement" onClick={() => restatementIri(false)}>
                        Pull IRI Restatement
                    </ReportButton>
                </div>
                <div>
                    <ButtonLabel>Unfreeze All frozen UPCs</ButtonLabel>
                    <ReportButton
                        id="unfreezeIriRestatement"
                        onClick={() => unfreezeUpcs('REFRESH_NIR')}
                    >
                        Unfreeze IRI Restatement UPCs
                    </ReportButton>
                </div>
            </ButtonGrid>
            {showLoader && <SlothSpinner />}
        </CardPage>
    );
};
