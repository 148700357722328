import { Dispatch, useCallback } from 'react';
import { Product, SubcategoryAttributeConfiguration } from '../../../../models';
import { apiService } from '../../../../services/ApiService';
import { mapProductToValues, mapValuesToProduct } from '../../mappers';
import { useShowToast } from '../useShowToast';
import { Actions } from './useProductReducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormSubmit = (
    dispatch: Dispatch<Actions>,
    product: Product,
    subcategoryAttributeOptions: SubcategoryAttributeConfiguration,
) => {
    const showToast = useShowToast();

    return useCallback(
        (values, bag) => {
            const { upc } = product;
            apiService
                // Product is always defined when this is called, so we can assert
                .putProductV2(mapValuesToProduct(product!, values), subcategoryAttributeOptions!)
                .then((response) => {
                    dispatch({
                        type: 'setQcSuccess',
                        payload: response.qcPassed,
                    });
                    showToast(response.status, response.message, response.qcPassed);
                })
                .then(() =>
                    apiService.getProduct(upc).then((p) => {
                        dispatch({ type: 'setProduct', payload: p });
                        bag.setErrors(mapProductToValues(p, 'errors') as any);
                    }),
                );
        },
        [dispatch, product, showToast, subcategoryAttributeOptions],
    );
};
