import styled from 'styled-components/macro';
import { Palette } from '@spins/amethyst';

export const Card = styled.div`
    height: fit-content;
    padding: 20px;
    border-radius: 3px;
    background: ${Palette.neutral[0].hex};
    box-shadow: 0 0 0 1px rgba(37, 37, 37, 0.05), 0 1px 3px 0 rgba(37, 37, 37, 0.15);
`;
