import { Button, LargeFacet, TableInstance, Times } from '@spins/amethyst';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { SnapshotQcReportDetailRecord } from '../../../../models';
import { AddUpcFilterDialogBackground } from './EditUPCFilterPopup';

const AddUpcFilterDialogContainer = styled.div`
    background-color: white;
    border-radius: 0.1875rem;
    box-sizing: border-box;
    display: block;
    padding: 8px;
    position: absolute;
    overflow: auto;
    right: -250px;
    top: 173px;
    width: 216px;
    z-index: 100000;

    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
    @-webkit-keyframes slide {
        100% {
            right: 4px;
        }
    }

    @keyframes slide {
        100% {
            right: 4px;
        }
    }
`;
export const AddUpcFilterDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid grey;
    padding: 8px;
`;

const AbsoluteButton = styled(Button)`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const EditColumnsButton = ({
    disabled,
    columns,
    visibleColumns,
    tableInstance,
}: {
    disabled: boolean;
    columns: Array<{ Header: string; id: string }>;
    visibleColumns: Array<{ id: string }>;
    tableInstance: TableInstance<SnapshotQcReportDetailRecord>;
}): JSX.Element => {
    const items = useMemo(() => {
        return columns.filter((c) => c.id !== 'upc').map((c) => ({ label: c.Header, value: c.id }));
    }, [columns]);

    const selectedItems = useMemo(
        () => visibleColumns.filter((c) => c.id !== 'upc').map((c) => c.id),
        [visibleColumns],
    );

    const updateColumns = useCallback(
        (v: string[]) => {
            const hiddenCols = _.difference(
                columns.map((c) => c.id),
                // We need to always have one column visible, so we're using UPC
                ['upc', ...v],
            );
            tableInstance.setHiddenColumns(hiddenCols);
        },
        [columns, tableInstance],
    );
    const [showColumns, setShowColumns] = useState(false);
    return (
        <>
            <Button
                buttonType={'secondary'}
                disabled={disabled}
                onClick={() => setShowColumns(!showColumns)}
            >
                Edit Columns
            </Button>
            {showColumns &&
                ReactDOM.createPortal(
                    <>
                        <AddUpcFilterDialogContainer>
                            <AbsoluteButton
                                type="button"
                                buttonType="icon"
                                aria-label="close dialog"
                                icon={Times}
                                onClick={() => setShowColumns(false)}
                            />
                            <LargeFacet
                                config={{ bubble: false, sort: false }}
                                title="Columns"
                                items={items}
                                value={selectedItems}
                                onChange={updateColumns}
                            />
                        </AddUpcFilterDialogContainer>
                    </>,
                    document.body,
                )}
            {showColumns && ReactDOM.createPortal(<AddUpcFilterDialogBackground />, document.body)}
        </>
    );
};
EditColumnsButton.displayName = 'EditColumnsButton';
