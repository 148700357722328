import React, { ReactElement, ReactNode } from 'react';
import Link from './ExternalLink.png';

export interface ExternalLinkProps {
    url: string;
    content: ReactNode;
    className?: string;
    showIcons?: boolean;
}

export const ExternalLink = ({
    url,
    content,
    className,
    showIcons = true,
}: ExternalLinkProps): ReactElement => {
    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            className={`${className} external-link`}
        >
            {content}
            {showIcons && <img src={Link} alt="external link" />}
        </a>
    );
};
