import {
    Column,
    SimpleTable,
    useFilters,
    useFlexLayout,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import React, { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAuth0 } from '../../auth0';
import { useFeatureFlag } from '../../featureFlags';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Campaign, ProductLastEditedSummary } from '../../services/ApiService';
import { MarkedCompleteCircle } from './MyWork';

const DetailTable = styled.div`
    table {
        box-shadow: none;
    }
`;

const EditPageNavLink = styled(NavLink)`
    &:visited {
        color: purple;
    }
`;

interface MyWorkDetailTableProps {
    workDetailData: ProductLastEditedSummary[];
    campaign?: Campaign;
    onSelect: (upc: string) => void;
}

export const WorkDetails = ({
    workDetailData,
    campaign,
    onSelect,
}: MyWorkDetailTableProps): JSX.Element => {
    const {
        user: { nickname },
    } = useAuth0()!;
    const { set } = useLocalStorage<string[]>();
    const withMultiEdit = useFeatureFlag('MULTI_EDIT_PAGE');
    const handleSelect = useMemo(() => (upc: string) => onSelect(upc), [onSelect]);

    const checkboxColumn: Column<ProductLastEditedSummary> = useMemo(
        () => ({
            width: undefined,
            minWidth: undefined,
            Header: <input type={'checkbox'} key={'checkbox'} title={'select all checkbox'} />,
            // eslint-disable-next-line react/display-name
            Cell: ({ row: { index } }) => {
                const isSelected = workDetailData[index].isSelected;
                return (
                    <input
                        type={'checkbox'}
                        onClick={() => handleSelect(workDetailData[index].upc)}
                        defaultChecked={isSelected}
                    />
                );
            },
            accessor: 'multiEdit',
        }),
        [handleSelect, workDetailData],
    );

    const baseColumns: Column<ProductLastEditedSummary>[] = useMemo(
        () => [
            {
                Header: 'UPC',
                accessor: 'upc',
                width: 0.6,
                // eslint-disable-next-line react/prop-types,react/display-name
                Cell: ({ cell }) => (
                    <EditPageNavLink
                        to={`/product/${cell.value}${
                            campaign ? `?period=${campaign.period}&bucket=${campaign.bucket}` : ''
                        }`}
                    >
                        {cell.value}
                    </EditPageNavLink>
                ),
            },
            { Header: 'Description', accessor: 'description', width: 1.2 },
            { Header: 'Brand', accessor: 'brand', width: 0.8 },
            { Header: 'Category', accessor: 'category', width: 0.5 },
            {
                Header: 'Status',
                accessor: 'statusIcons',
                width: 0.1,
                // eslint-disable-next-line react/prop-types,react/display-name
                Cell: ({ row: { index } }) =>
                    workDetailData[index].completed ? <MarkedCompleteCircle /> : <></>,
            },
        ],
        [campaign, workDetailData],
    );

    const columns: Column<ProductLastEditedSummary>[] = useMemo(
        () => (withMultiEdit ? [checkboxColumn, ...baseColumns] : baseColumns),
        [baseColumns, checkboxColumn, withMultiEdit],
    );

    const campaignInstance = useTable(
        {
            data: workDetailData,
            columns,
            noDataMessage: 'Make a selection to see UPC data.',
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    useEffect(() => {
        const upcs = campaignInstance.rows.map((row) => row.original.upc);
        if (campaign?.period && campaign?.bucket)
            set(`${nickname} ${campaign.period} ${campaign.bucket}`, upcs);
    }, [nickname, campaign, campaignInstance.rows, set]);

    return (
        <DetailTable>
            <SimpleTable instance={campaignInstance} />
        </DetailTable>
    );
};
