import { useEffect, useRef } from 'react';

export const useScrollToHash = (isReady: boolean): void => {
    const runRef = useRef(false);
    useEffect(() => {
        if (!isReady || runRef.current) {
            return;
        }
        const { hash } = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }, 100);
                runRef.current = true;
            }
        }
    }, [isReady]);
};
