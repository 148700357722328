import { RetailerInfo } from './RetailerInfo';

export class Retailer {
    public retailerInfo: RetailerInfo[];
    public retailerTags: string[];

    public constructor(retailerInfo: RetailerInfo[], retailerTags: string[]) {
        this.retailerInfo = retailerInfo;
        this.retailerTags = retailerTags;
    }
}
