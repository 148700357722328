import React, { FC, ReactElement, useEffect, useState } from 'react';
import { apiService } from '../services/ApiService';

export const NirvanaTestPage: FC = (): ReactElement => {
    const [nirvanaStatus, setNirvanaStatus] = useState('All Apologies...');

    useEffect(() => {
        if (nirvanaStatus === 'All Apologies...') {
            apiService.testNirvanaApi().then((response: string) => {
                setNirvanaStatus(response);
            });
        }
    }, [nirvanaStatus]);

    return (
        <div className={'nirvana-test'}>
            <h1>Welcome to Nirvana</h1>
            <div>
                <span role="img" aria-label={'baby'}>
                    👶🏼
                </span>
                <span role="img" aria-label={'money'}>
                    💵
                </span>
                <p>Nirvana is status: {nirvanaStatus}</p>
            </div>
            {nirvanaStatus === '200' && (
                <div style={{ alignItems: 'center', width: '100%' }}>
                    <iframe
                        title="nirvana-breed-video"
                        src="https://player.vimeo.com/video/29992528?autoplay=1"
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                    />
                    <p>
                        {`Nirvana was an American rock band formed in Aberdeen, Washington, in 1987.
                            Founded by lead singer-songwriter and guitarist Kurt Cobain and bassist
                            Krist Novoselic, the band went through a succession of drummers before
                            recruiting Dave Grohl in 1990. Characterized by their punk aesthetic,
                            Nirvana's fusion of pop melodies with noise, combined with their themes of
                            abjection and social alienation, made them hugely popular during their short
                            tenure. Their music maintains a popular following and continues to influence
                            modern rock and roll culture. In the late 1980s, Nirvana established itself
                            as part of the Seattle grunge scene, releasing its first album, Bleach, for
                            the independent record label Sub Pop in 1989. They developed a sound that
                            relied on dynamic contrasts, often between quiet verses and loud, heavy
                            choruses. After signing to major label DGC Records in 1991, Nirvana found
                            unexpected mainstream success with "Smells Like Teen Spirit", the first
                            single from their landmark second album Nevermind (1991). A cultural
                            phenomenon of the 1990s, the album went on to be certified Diamond by the
                            RIAA.[1] Nirvana's sudden success popularized alternative rock and was
                            credited for ending the dominance of hair metal, while they were also often
                            referenced as the figurehead band of Generation X. Following extensive tours
                            and the 1992 compilation album Incesticide and EP Hormoaning, Nirvana
                            released their abrasive and less mainstream sounding third studio album, In
                            Utero (1993). The album topped both the US and UK album charts, and was
                            acclaimed by critics. Nirvana disbanded following Cobain's death in April
                            1994. Various posthumous releases have been overseen by Novoselic, Grohl,
                            and Cobain's widow Courtney Love. The posthumous live album MTV Unplugged in
                            New York (1994) won Best Alternative Music Performance at the 1996 Grammy
                            By 2009, Nirvana record sales were over 75 million worldwide, making them one 
                            of the best-selling bands of all time. During their three years as a mainstream 
                            act, Nirvana received an American Music Award, Brit Award and Grammy Award, as 
                            well as seven MTV Video Music Awards and two NME Awards. They achieved five 
                            number-one hits on the Billboard Alternative Songs chart and four number-one 
                            albums on the Billboard 200. In 2004, Rolling Stone named Nirvana among the 100 
                            greatest artists of all time. They were inducted into the Rock and Roll Hall of 
                            Fame in their first year of eligibility in 2014.`}
                    </p>
                </div>
            )}
        </div>
    );
};
