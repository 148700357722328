import { AttributeName } from '../constants/attributeNames';
import { AttributeType } from './AttributeType';
import { BasicAttribute } from './BasicAttribute';

export class EnumeratedAttribute extends BasicAttribute {
    public options: string[];

    public constructor(
        name: AttributeName,
        description: string | undefined,
        options: string[] = [],
    ) {
        super(name, description, AttributeType.ENUMERATED);
        this.options = options;
    }
}
