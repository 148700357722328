import { Palette } from '@spins/amethyst';
export const Colors = {
    Neutral0: Palette.neutral[0].hex,
    Neutral20: Palette.neutral[20].hex,
    Neutral50: Palette.neutral[50].hex,
    Neutral60: Palette.neutral[60].hex,
    Neutral80: Palette.neutral[80].hex,
    Neutral100: Palette.neutral[100].hex,
    ErrorBackgroundLiteRed: Palette.red[5].hex,
    ErrorRed: Palette.red[60].hex,
    Red80: Palette.red[80].hex,
    Green05: Palette.green[5].hex,
    Blue100: Palette.blue[100].hex,
    Blue80: Palette.blue[80].hex,
    Disabled: 'rgba(0,0,0,0.05)',
};
