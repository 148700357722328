import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components/macro';
import { BodyLarge, Header1, Palette } from '@spins/amethyst';
import CopyToClipboard from 'react-copy-to-clipboard';
import { HeaderCopyMenu } from './menus/HeaderCopyMenu';
import { AttributeName } from '../../constants/attributeNames';
import { Attribute, AttributeType } from '../../models';
import { ProductResourceMenu } from './menus/ProductResourceMenu';

export interface ProductOverviewProps {
    attributes: Attribute[];
    className?: string;
    upc: string;
}

export const InlineBlock = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 20px 0 24px;
`;

export const ProductDescription = styled(BodyLarge)`
    color: ${Palette.neutral[80].hex};
    // This is a magic number that needs to die
    line-height: 26px;
`;
ProductDescription.displayName = 'ProductDescription';

export const ProductOverviewDetails = ({
    attributes,
    upc,
    className,
}: ProductOverviewProps): ReactElement => {
    const descriptionAttribute = useMemo(
        () => attributes.find((attribute) => attribute.name === AttributeName.DESCRIPTION),
        [attributes],
    );

    const ean13Attribute = useMemo(
        () => attributes.find((attribute) => attribute.name === AttributeName.UPC_EAN_13),
        [attributes],
    );

    const mfrCode = useMemo(
        () =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            attributes.find((attribute) => attribute.name === AttributeName.MANUFACTURER_CODE)
                ?.description!,
        [attributes],
    );

    const upcAttribute: Attribute = useMemo(
        () => ({
            name: AttributeName.upc,
            description: upc,
            type: AttributeType.READ_ONLY,
        }),
        [upc],
    );

    return (
        <section className={className}>
            <InlineBlock>
                <CopyToClipboard text={upc}>
                    <Header1 style={{ margin: 0 }}>{upc}</Header1>
                </CopyToClipboard>
                <HeaderCopyMenu
                    mfrCode={mfrCode}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    upcEan13={ean13Attribute?.description!}
                    upc={upc}
                />
                <ProductDescription id={'description'}>
                    {descriptionAttribute && descriptionAttribute.description}
                </ProductDescription>
            </InlineBlock>
            {upcAttribute && ean13Attribute && (
                <ProductResourceMenu
                    upcEan13={ean13Attribute.description || ''}
                    upc={upcAttribute.description || ''}
                    description={descriptionAttribute?.description || ''}
                />
            )}
        </section>
    );
};

export const ProductOverview = styled(ProductOverviewDetails)`
    flex: 1;
    justify-content: space-between;
    display: flex;
`;
