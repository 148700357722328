import React, { useEffect, useState } from 'react';
import { Info } from '@spins/amethyst';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components/macro';
import { AttributeName } from '../../constants/attributeNames';
import { Colors } from '../../constants/colors';
import { confluenceLinkMap } from '../../constants/confluenceLinks';
import { Copy } from '../../components/Copy';

interface AttributeHoverMenuProps {
    description: string;
    labelName: AttributeName | undefined;
}

const HoverMenu = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    line-height: 20px;
    font-size: 14px;
    width: fit-content;
    > * {
        height: 20px;
    }
`;
HoverMenu.displayName = 'HoverMenu';

const HoverMenuItem = styled.div.attrs(() => ({ role: 'button' }))`
    color: #0c84d6;
    :hover {
        cursor: pointer;
    }
    &:active {
        color: ${Colors.Blue100};
    }
`;
HoverMenuItem.displayName = 'HoverMenuItem';

export const AttributeHoverMenu = ({
    description,
    labelName,
}: AttributeHoverMenuProps): JSX.Element => {
    // This should probably be done via CSS if possible
    const [isHovered, setIsHovered] = useState(false);
    const [helpLink, setHelpLink] = useState('');

    useEffect(() => {
        if (labelName) {
            const lookupValue = confluenceLinkMap.get(labelName);
            if (lookupValue) {
                setHelpLink(lookupValue);
            }
        }
    }, [labelName]);

    const onHoverIn = (): void => {
        setIsHovered(true);
    };

    const onHoverOut = (): void => {
        setIsHovered(false);
    };

    return (
        <HoverMenu onMouseEnter={onHoverIn} onMouseLeave={onHoverOut}>
            {labelName || ''}
            {isHovered && (
                <>
                    <CopyToClipboard text={description}>
                        <HoverMenuItem>
                            <Copy aria-label={`copy to clipboard`} />
                        </HoverMenuItem>
                    </CopyToClipboard>
                    {helpLink && (
                        <a
                            href={helpLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`help link`}
                        >
                            <HoverMenuItem>
                                <Info width={'16px'} />
                            </HoverMenuItem>
                        </a>
                    )}
                </>
            )}
        </HoverMenu>
    );
};
