import { Palette } from '@spins/amethyst';
import styled from 'styled-components/macro';

export interface AttributeErrorProps {
    message: string;
}

export const AttributeError = styled.div.attrs<AttributeErrorProps>(({ message, ...props }) => ({
    children: message,
    ...props,
}))<AttributeErrorProps>`
    color: ${Palette.red[80].hex};
    font-size: 12px;
    margin-top: 4px;
`;
