import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button, fontStyles, Link as AmethystLink, Palette } from '@spins/amethyst';
import { useAuth0 } from '../../../auth0';
import { Card, CardPage } from '../../../components';
import { ReleaseNote } from '../../../models';
import { apiService } from '../../../services/ApiService';

interface Props {
    initialCount: number;
}

const ReleaseNotesLayout = styled(CardPage)`
    flex: 1 1 400px;
    ${Card} {
        background-color: transparent;
        box-shadow: none;
    }
`;

const DateAndEdit = styled.div`
    color: #666666;
    display: flex;
    font: ${fontStyles.BodySmall};
    justify-content: space-between;
    margin-bottom: 4px;
    width: 90px;
    > a {
        font: ${fontStyles.BodySmall};
    }
`;
const ReactMarkdownCard = styled(ReactMarkdown)`
    height: fit-content;
    padding: 8px 20px;
    border-radius: 3px;
    background: ${Palette.neutral[0].hex};
    box-shadow: 0 0 0 1px rgba(37, 37, 37, 0.05), 0 1px 3px 0 rgba(37, 37, 37, 0.15);
`;
const ReleaseNoteInstance = ({ notes }: { notes: ReleaseNote }) => {
    const auth = useAuth0();

    return (
        <div style={{ marginBottom: '24px' }}>
            <DateAndEdit>
                {notes.releaseDate}
                {auth && auth.permissions.includes('edit:release_notes') && (
                    <AmethystLink as={Link} to={`/releaseNotes/${notes.id}`}>
                        Edit
                    </AmethystLink>
                )}
            </DateAndEdit>
            <ReactMarkdownCard source={notes.notes} />
        </div>
    );
};

export const ReleaseNotes = ({ initialCount }: Props): ReactElement => {
    const [releaseCount, setReleaseCount] = useState(initialCount);
    const showAll = useCallback(() => setReleaseCount(Infinity), []);
    const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);
    const hasMore = releaseCount !== Infinity && releaseNotes.length > releaseCount;
    useEffect(() => {
        apiService.getReleaseNotes().then((data) => setReleaseNotes(data));
    }, []);
    return (
        <ReleaseNotesLayout title="Release Notes">
            {releaseNotes.length > 0 ? (
                <>
                    {releaseNotes
                        .slice(0, releaseCount ? releaseCount : releaseNotes.length)
                        .map((notes) => (
                            <ReleaseNoteInstance key={notes.id} notes={notes} />
                        ))}
                    {hasMore && <Button onClick={showAll}>Show More</Button>}
                </>
            ) : (
                <div>No available release notes</div>
            )}
        </ReleaseNotesLayout>
    );
};
