import React, { ReactElement, useMemo } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import { SlothSpinner } from '../../components';
import { Campaign } from '../../services/ApiService';
import { useScrollToHash } from '../../hooks/useScrollToHash';
import { NotFoundPage } from '../NotFoundPage';
import { AttributeGroups } from './AttributeGroups';
import { useProductPage, useWorkflowUri } from './hooks';
import { ProductNav } from './ProductNav';
import { ProductOverview } from './ProductOverview';
import { useLocation } from 'react-router';

import { Taskbar } from './Taskbar';

interface ProductPageProps {
    upc: string;
}

const ProductPageLayout = styled.main`
    display: flex;
`;

const ProductDetails = styled.section`
    padding: 0 20px;
    width: calc(100% - 240px);
`;

const ProductHeader = styled.header`
    display: flex;
    width: 100%;
`;

const useQuery = () => new URLSearchParams(useLocation().search);

const useCampaign = (): Campaign | undefined => {
    const query = useQuery();
    const bucket = query.get('bucket');
    const period = query.get('period');

    return useMemo(() => {
        return bucket && period ? { bucket, period } : undefined;
    }, [bucket, period]);
};

export const ProductPage = (props: ProductPageProps): ReactElement => {
    const upc = props.upc;
    const campaign = useCampaign();
    useWorkflowUri(!!campaign, upc);

    const {
        productNotFound,
        product,
        subcategoryAttributeOptions,
        positioningConfiguration,
        isLoading,
        handleFormSubmit,
        shouldRenderForm,
        validationImage,
        initialValues,
        initialErrors,
    } = useProductPage(upc);

    useScrollToHash(!isLoading);

    if (productNotFound) {
        return <NotFoundPage />;
    }

    return (
        <>
            {isLoading && <SlothSpinner />}
            {shouldRenderForm && product !== undefined && positioningConfiguration !== undefined && (
                <>
                    <ProductPageLayout>
                        <Formik
                            initialValues={initialValues}
                            // TODO: Fix error type for custom retailer array
                            initialErrors={initialErrors as any}
                            validateOnBlur={false}
                            validateOnMount={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            onSubmit={handleFormSubmit}
                        >
                            {(props) => {
                                return (
                                    <>
                                        <ProductNav
                                            attributeErrors={product.attributeErrors}
                                            retailerErrors={product.retailerErrors}
                                            upc={upc}
                                        />
                                        <ProductDetails>
                                            <ProductHeader id={'product-header'}>
                                                <ProductOverview
                                                    attributes={product.attributes}
                                                    upc={upc}
                                                />
                                                <img
                                                    src={validationImage}
                                                    alt="The Best Sloth Possible"
                                                    id={'sloth-image'}
                                                    style={{ height: '76px', width: '76px' }}
                                                />
                                            </ProductHeader>
                                            <form id={'product-form'} onSubmit={props.handleSubmit}>
                                                <AttributeGroups
                                                    attributes={product.attributes}
                                                    subcategoryAttributeOptions={
                                                        subcategoryAttributeOptions!
                                                    }
                                                    positioningConfiguration={
                                                        positioningConfiguration
                                                    }
                                                    retailerTags={product.retailer.retailerTags}
                                                />
                                            </form>
                                        </ProductDetails>
                                    </>
                                );
                            }}
                        </Formik>
                    </ProductPageLayout>
                    <Taskbar upc={upc} campaign={campaign} />
                </>
            )}
        </>
    );
};
