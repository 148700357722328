import { useInitial } from '../../mappers';
import SadSloth from './SadSloth.png';
import Slothy from './Slothy.gif';
import { useFormSubmit } from './useFormSubmit';
import { useFrozenToast } from './useFrozenToast';
import { useProductReducer } from './useProductReducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProductPage = (upc: string) => {
    const [state, dispatch] = useProductReducer(upc);
    useFrozenToast();

    const handleFormSubmit = useFormSubmit(
        dispatch,
        // These should always be defined when we submit the form, so we'll assert and move on
        state.product!,
        state.subcategoryAttributeOptions!,
    );

    const isLoading = state.product === undefined;
    const { values: initialValues, errors: initialErrors } = useInitial(state.product);

    const validationImage = state.isQcSuccess ? Slothy : SadSloth;

    const shouldRenderForm =
        state.subcategoryAttributeOptions !== undefined &&
        state.subcategoryAttributeOptions.size > 0;

    return {
        ...state,
        handleFormSubmit,
        initialErrors,
        initialValues,
        isLoading,
        shouldRenderForm,
        validationImage,
    };
};
