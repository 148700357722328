import React from 'react';

export const Copy = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            id="FontAwesome-Icon-/-Small-/-Copy"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
                d="M15.6548013,5.54549219 L14.3809129,4.32950781 C14.1598892,4.11852903 13.8601161,4 13.5475402,4 L9.32142857,4 C8.67051339,4 8.14285714,4.50367188 8.14285714,5.125 L8.14285714,6.25 L6.17857143,6.25 C5.52765625,6.25 5,6.75367188 5,7.375 L5,14.875 C5,15.4963281 5.52765625,16 6.17857143,16 L11.6785714,16 C12.3294866,16 12.8571429,15.4963281 12.8571429,14.875 L12.8571429,13.75 L14.8214286,13.75 C15.4723438,13.75 16,13.2463281 16,12.625 L16,6.34098438 C16,6.04261642 15.8758267,5.75646941 15.6548013,5.54549219 Z M11.53125,14.875 L6.32589286,14.875 C6.24452948,14.875 6.17857143,14.81204 6.17857143,14.734375 L6.17857143,7.515625 C6.17857143,7.43795996 6.24452948,7.375 6.32589286,7.375 L8.14285714,7.375 L8.14285714,12.625 C8.14285714,13.2463281 8.67051339,13.75 9.32142857,13.75 L11.6785714,13.75 L11.6785714,14.734375 C11.6785714,14.81204 11.6126134,14.875 11.53125,14.875 Z M14.6741071,12.625 L9.46875,12.625 C9.38738662,12.625 9.32142857,12.56204 9.32142857,12.484375 L9.32142857,5.265625 C9.32142857,5.18795996 9.38738662,5.125 9.46875,5.125 L12.0714286,5.125 L12.0714286,7.1875 C12.0714286,7.49816406 12.3352567,7.75 12.6607143,7.75 L14.8214286,7.75 L14.8214286,12.484375 C14.8214286,12.56204 14.7554705,12.625 14.6741071,12.625 L14.6741071,12.625 Z M14.8214286,6.625 L13.25,6.625 L13.25,5.125 L13.4865,5.125 C13.5255647,5.125 13.5630335,5.1398125 13.5906808,5.16617969 L14.7782879,6.29980469 C14.805915,6.32618043 14.8214286,6.36195222 14.8214286,6.39925 L14.8214286,6.625 L14.8214286,6.625 Z"
                id="Shape"
                fill="currentColor"
            ></path>
        </g>
    </svg>
);
