import { Attribute, AttributeGroup } from '../../models';

export const toKebab = (group: string): string => group.toLowerCase().replace(/ /g, '-');

export const filterRelevantAttributes = (
    attributes: Attribute[],
    subcategoryAttributeConfiguration: ReadonlyMap<string, readonly string[]>,
): Attribute[] => {
    const subcategoryAttribute = attributes.find((attribute) => attribute.name === 'SUBCATEGORY');
    const subcategory =
        subcategoryAttribute && subcategoryAttribute.description
            ? subcategoryAttribute.description
            : '';
    const subcategoryConfiguration =
        subcategory !== '' ? subcategoryAttributeConfiguration.get(subcategory) : [];
    return subcategoryConfiguration && subcategoryConfiguration.length > 0
        ? attributes.filter((attribute) => subcategoryConfiguration.includes(attribute.name))
        : attributes;
};

export const groupToTitle = (group: string): string => {
    if (group === AttributeGroup.CUSTOM_RETAILER_INFORMATION) {
        return 'PVL Custom Retailer Info';
    } else if (group === AttributeGroup.BASE_CODING) {
        return 'Basic Attributes';
    } else {
        return group;
    }
};

export const generateUpcEan13GoogleSearchLink = (upcEanStr: string): string => {
    const upcEan = upcEanStr;
    const ean13ForGoogleSearch = upcEan.startsWith('0') ? upcEan.substr(1, upcEan.length) : upcEan;

    return generateGoogleSearchLink(ean13ForGoogleSearch);
};

export const generateGoogleSearchLink = (searchTerm: string): string =>
    `https://google.com/search?q=${encodeURIComponent(searchTerm)}`;
