import React, { ReactElement, useEffect, useState } from 'react';
import { CardPage } from '../components';
import { SourcePriority } from '../models';
import { apiService } from '../services/ApiService';

export const SourcePriorityPage = (): ReactElement => {
    const [sourcePriorities, setSourcePriorities] = useState<SourcePriority[]>();
    useEffect(() => {
        apiService
            .getSourcePriorities()
            .then((v) => {
                const priorities = v.sort((a, b) => a.priority - b.priority);
                setSourcePriorities(priorities);
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <CardPage title="Sources in priority order">
            <ol>
                {sourcePriorities?.map((sourcePriority) => (
                    <li key={`source-priority-${sourcePriority.name}`}>{sourcePriority.name}</li>
                ))}
            </ol>
        </CardPage>
    );
};
