import { SimpleTable } from '@spins/amethyst';
import React from 'react';
import styled from 'styled-components/macro';

const SimpleTableContainer = styled.div`
    table {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        overflow-x: auto;
        thead {
            overflow: initial;
        }
        tr {
            min-width: fit-content !important;
        }
    }
`;

export const SnapshotQcTable = ({ instance }: { instance: any }): JSX.Element => (
    <SimpleTableContainer>
        <SimpleTable instance={instance} />
    </SimpleTableContainer>
);
