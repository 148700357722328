import { NotificationType, useToasts } from '@spins/amethyst';
import { useEffect, useState } from 'react';

export const useFrozenToast = (isFrozen?: boolean): void => {
    const { addToast, removeToast } = useToasts();
    const [toastId, setToastId] = useState<string | undefined>();

    useEffect(() => {
        if (isFrozen) {
            addToast(
                'Frozen',
                {
                    appearance: NotificationType.Info,
                    autoDismiss: false,
                },
                setToastId,
            );
        }
        return () => {
            if (toastId) {
                removeToast(toastId);
            }
        };
    }, [addToast, isFrozen, removeToast, toastId]);
};
