import { Body, BodyHeavy, Button, Edit, Palette } from '@spins/amethyst';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useFeatureFlag } from '../../featureFlags';
import { Campaign } from '../../services/ApiService';

export const RightPanelSubheaders = styled.span`
    display: flex;
    padding-right: 20px;
`;

export const SubheaderSelection = styled(BodyHeavy)`
    margin-left: 6px;
`;

const PanelSubheaders = styled(Body)`
    display: flex;
    padding-bottom: 32px;
`;
const LeftSide = styled.div`
    display: flex;
    flex: 1;
    padding-left: 20px;
`;
const RightSide = styled.div`
    justify-content: right;
    padding-right: 20px;
`;

const MultiEditIcon = styled(Edit)`
    margin-right: 7px;
    color: ${Palette.teal[90].hex};
`;

interface MyWorkWhitePanelProps {
    campaign?: Campaign;
    manufacturerCode?: string;
    multiEditEnabled?: boolean;
}

export const MyWorkWhitePanelBody: FC<MyWorkWhitePanelProps> = ({
    campaign,
    manufacturerCode,
    multiEditEnabled,
}) => {
    const withMultiEdit = useFeatureFlag('MULTI_EDIT_PAGE');
    return (
        <PanelSubheaders>
            <LeftSide>
                <RightPanelSubheaders>
                    Campaign:
                    <SubheaderSelection>
                        {campaign ? `${campaign.period} ${campaign.bucket}` : 'None Selected'}
                    </SubheaderSelection>
                </RightPanelSubheaders>
                <RightPanelSubheaders>
                    Manufacturer Code:
                    <SubheaderSelection>
                        {campaign && !manufacturerCode
                            ? 'All'
                            : manufacturerCode || 'None Selected'}
                    </SubheaderSelection>
                </RightPanelSubheaders>
            </LeftSide>
            {withMultiEdit && (
                <RightSide>
                    <NavLink to={'/multi-edit'}>
                        <Button
                            type="button"
                            buttonType="quaternary"
                            aria-label="open menu"
                            icon={MultiEditIcon}
                            disabled={!multiEditEnabled}
                        >
                            Multi-Edit
                        </Button>
                    </NavLink>
                </RightSide>
            )}
        </PanelSubheaders>
    );
};
