import React, { useCallback, useRef, useState } from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';
import { Attribute } from '../../../models';
import { RadioTextInput } from './shared';
import { ensureMax, truncateToPrecision } from './Numeric/Numeric';
import { AttributeHoverMenu } from '../AttributeHoverMenu';
import { FormElement } from '../../../components';

interface RadioFreeTextProps {
    attribute: Attribute;
    step: number;
    precision: number;
    max?: number;
    options: string[];
    symbol?: string;
}

const RadioInput = styled.input`
    padding: 0 4px 0 8px;
    margin-top: 12px;
    margin-right: 8px;
`;

export const sanitizeValue = (
    newValue: string,
    precision?: number,
    max?: number,
): string | undefined => ensureMax(truncateToPrecision(newValue, precision), max);

export const NumericButtonGroup = ({
    attribute,
    step,
    precision,
    max,
    options,
    symbol,
    name,
}: RadioFreeTextProps & { name: string }): JSX.Element => {
    const [selectedIdx, setSelectedIdx] = useState<Optional<number>>();
    const [input, meta, helpers] = useField<Optional<string>>(name);
    const { value } = input;
    const { error } = meta;
    const { setValue } = helpers;

    const myRef = useRef<HTMLInputElement>(null);

    const sanitizeOnBlur = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const sanitizedValue = Number(e.currentTarget.value).toFixed(precision);
            if (sanitizedValue !== value) {
                helpers.setValue(sanitizedValue);
            }
            input.onBlur(e);
        },
        [helpers, input, precision, value],
    );

    const disabled = Boolean(selectedIdx !== 0);
    return (
        <FormElement>
            <AttributeHoverMenu
                labelName={attribute.name}
                description={attribute.description || ''}
            />
            <section>
                <input
                    name={name}
                    aria-label="use custom number"
                    type={'radio'}
                    checked={!disabled}
                    onChange={() => {
                        setSelectedIdx(0);
                        setValue('');
                    }}
                />
                <RadioTextInput
                    ref={myRef}
                    hasError={Boolean(error)}
                    disabled={disabled}
                    aria-label="custom number box"
                    min={0}
                    max={max}
                    name={name}
                    onBlur={sanitizeOnBlur}
                    onChange={input.onChange}
                    step={step}
                    type="number"
                    value={value}
                />
                {symbol}
            </section>
            {options.map((option, idx) => (
                <label key={option}>
                    <RadioInput
                        // We're not hooking this up to Formik other than the manual handler
                        name={attribute.name}
                        type={'radio'}
                        checked={value === option}
                        onChange={() => {
                            setSelectedIdx(idx + 1);
                            setValue(option);
                        }}
                    />
                    {option}
                </label>
            ))}
        </FormElement>
    );
};
