import React, { ReactElement, useEffect } from 'react';
import { RouteProps, useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../auth0';

interface PrivateRouteProps extends RouteProps {
    requiredPermissions?: string[];
}

const PrivateRouteComponent = ({
    requiredPermissions,
    path,
    render,
    exact,
    component,
}: PrivateRouteProps): ReactElement => {
    const auth = useAuth0();
    const history = useHistory();

    function hasRequiredPermissions(): boolean {
        if (!requiredPermissions) return true;
        return (
            !!auth &&
            requiredPermissions.every((permission) => auth.permissions.includes(permission))
        );
    }

    useEffect(() => {
        (async () => {
            if (auth && !auth.loading) {
                if (!auth.isAuthenticated) {
                    await auth.loginWithRedirect({
                        appState: { targetUrl: window.location.pathname },
                    });
                } else if (!hasRequiredPermissions()) {
                    history.replace('/not-authorized');
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const shouldRender = auth && auth.isAuthenticated && hasRequiredPermissions();
    const renderProps = {
        render: (shouldRender && render) || undefined,
        component: (shouldRender && component) || undefined,
    };
    return <Route path={path} exact={exact} {...renderProps} />;
};

const PrivateRoute = window.REACT_APP_DONT_USE_AUTH0 ? Route : PrivateRouteComponent;
export { PrivateRoute };
