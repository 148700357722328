import React, { useCallback, useState } from 'react';
import { TableInstance, Button } from '@spins/amethyst';
import { SnapshotQcReportDetailRecord } from '../../../../models';
import { EditUPCFilterPopup } from './EditUPCFilterPopup';

type GlobalFilterInstance = {
    tableInstance: TableInstance<SnapshotQcReportDetailRecord>;
    state: { globalFilter?: string };
};

const hasGlobalFilter = (tableInstance: any): tableInstance is GlobalFilterInstance =>
    ('globalFilter' in tableInstance.state && tableInstance.state.globalFilter) ||
    tableInstance.state.globalFilter;

export const EditUPCFilterButton = ({
    disabled,
    tableInstance,
}: {
    disabled: boolean;
    tableInstance: TableInstance<SnapshotQcReportDetailRecord>;
}): JSX.Element => {
    const tableIsFiltered = hasGlobalFilter(tableInstance);
    const [addUpcFilterDialogOpen, setAddUpcFilterDialogOpen] = useState<boolean>(false);
    const openAddUpcFilterDialog = useCallback(() => {
        setAddUpcFilterDialogOpen(true);
    }, []);

    const closeAddUpcFilterDialog = useCallback(() => {
        setAddUpcFilterDialogOpen(false);
    }, [setAddUpcFilterDialogOpen]);
    return (
        <>
            <Button onClick={openAddUpcFilterDialog} buttonType={'secondary'} disabled={disabled}>
                {!tableIsFiltered ? 'Add UPC filter' : 'Edit UPC filter'}
            </Button>
            {addUpcFilterDialogOpen && (
                <EditUPCFilterPopup
                    close={closeAddUpcFilterDialog}
                    // TODO: This needs to be fixed in Amethyst
                    upcFilter={(tableInstance.state as any).globalFilter}
                    setUpcFilter={(tableInstance as any).setGlobalFilter}
                />
            )}
        </>
    );
};
EditUPCFilterButton.displayName = 'EditUPCFilterButton';
