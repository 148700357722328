export const certificationsAttributes = [
    'americanHeartAssoc',
    'bCorporation',
    'demeterBiodynamic',
    'detoxProjectGlyphosateResidueFree',
    'fairForLife',
    'fairTradeUsa',
    'fairTradeAmerica',
    'gfco',
    'greenerWorldAnimalWelfare',
    'greenerWorldGrassfed',
    'greenerWorldNonGmo',
    'howgoodRating',
    'marineStewardshipCouncil',
    'naturalProductsAssoc',
    'nonGmoProjectVerified',
    'certifiedPaleo',
    'paleoFoundationGrainFree',
    'paleoFriendly',
    'paleoKeto',
    'paleoVegan',
    'plantBasedFoodsAssociation',
    'wholeGrainsCouncil',
];
