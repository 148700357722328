import { Row } from 'react-table';

export const campaignFilter = (
    rows: Row<any>[],
    columns: string[],
    filterValue: string,
): Row<any>[] => {
    return rows.filter((row) => {
        return `${row.original.campaign.period} ${row.original.campaign.bucket}`
            .toUpperCase()
            .includes(filterValue.toUpperCase());
    });
};
