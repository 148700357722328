import styled from 'styled-components/macro';
import { Row } from '../../../components';

export const MultiColumnRow = styled(Row)`
    max-width: calc(100% - 24px);
`;

export const RowGroup = styled.div`
    > * {
        flex: 1;
        max-width: calc(100% - 24px);
    }
`;
