import { useField } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
    Attribute,
    AttributeGroup,
    PositioningConfiguration,
    RelevantSubcategoryAttributeValues,
    SubcategoryAttributeConfiguration,
} from '../../models';
import { apiService } from '../../services/ApiService';
import { AdvancedCoding } from './AdvancedCoding';
import {
    camelCaseAttributeNameToGroup,
    certificationsAttributes,
    channelFlagsAttributes,
    labelClaimsAttributes,
} from './attributeFields';
import { BaseCodingGroup } from './BaseCoding';
import { CustomRetailerContainer } from './CustomRetailer';
import { EnumeratedSection } from './EnumeratedSection';
import { ProductFacts } from './ProductFacts';

interface AttributeGroups {
    [k: string]: Record<string, Attribute>;
}

export const groupOrder = [
    AttributeGroup.BASE_CODING,
    AttributeGroup.PRODUCT_FACTS,
    AttributeGroup.POSITIONING,
    AttributeGroup.LABEL_CLAIMS,
    AttributeGroup.ADVANCED_CODING,
    AttributeGroup.CERTIFICATIONS,
    AttributeGroup.CUSTOM_RETAILER_INFORMATION,
    AttributeGroup.CHANNEL_FLAGS,
];

export const StyledSection = styled.section`
    * {
        margin-bottom: 28px;
    }
`;

export const filterRelevantAttributes = (
    attributes: Attribute[],
    subcategoryAttributeConfiguration: Map<string, string[]>,
    subcategoryAttribute: Attribute['description'],
): Attribute[] => {
    const subcategory = subcategoryAttribute || '';
    const subcategoryConfiguration =
        subcategory !== '' ? subcategoryAttributeConfiguration.get(subcategory) : [];
    return subcategoryConfiguration && subcategoryConfiguration.length > 0
        ? attributes.filter((attribute) => subcategoryConfiguration.includes(attribute.name))
        : attributes;
};

const filteredRelevantAttributesAndAddRelevantValues = (
    subcategory: Attribute['description'],
    attributes: Attribute[],
    subcategoryAttributeConfiguration: Map<string, string[]>,
    relevantValuesConfiguration?: Map<string, string[]>,
): Attribute[] => {
    const filteredAttributes = filterRelevantAttributes(
        attributes,
        subcategoryAttributeConfiguration,
        subcategory,
    );

    return filteredAttributes.map((attribute) => {
        const relevantOptions = relevantValuesConfiguration?.get(attribute.name);
        const options = relevantOptions ? relevantOptions : attribute.options;
        return { ...attribute, options };
    });
};

const groupingToAttributes = (attrs: Attribute[] = []) => {
    return attrs.reduce((groups: AttributeGroups, attribute: Attribute) => {
        const attributeGroup = camelCaseAttributeNameToGroup.get(_.camelCase(attribute.name));
        if (attributeGroup) {
            const attributeMap = {
                ...(groups[attributeGroup] || {}),
            };
            (attributeMap as any)[_.camelCase(attribute.name)] = attribute;
            return {
                ...groups,
                [attributeGroup]: attributeMap,
            };
        }
        return groups;
    }, {});
};

export const AttributeGroups = ({
    attributes,
    subcategoryAttributeOptions,
    positioningConfiguration,
    retailerTags,
}: {
    attributes: Attribute[];
    subcategoryAttributeOptions: SubcategoryAttributeConfiguration;
    positioningConfiguration: PositioningConfiguration;
    retailerTags: string[];
}): JSX.Element => {
    const [relevantSubcategoryAttributeValues, setRelevantSubcategoryAttributeValues] = useState<
        RelevantSubcategoryAttributeValues | undefined
    >();
    const [inputProps] = useField('baseCodingAttribute.subcategory');
    const { value: subcategoryValue } = inputProps;
    const groupedAttributes: AttributeGroups = useMemo(
        () =>
            groupingToAttributes(
                filteredRelevantAttributesAndAddRelevantValues(
                    subcategoryValue,
                    attributes,
                    subcategoryAttributeOptions,
                    relevantSubcategoryAttributeValues,
                ),
            ),
        [
            attributes,
            subcategoryAttributeOptions,
            relevantSubcategoryAttributeValues,
            subcategoryValue,
        ],
    );
    useEffect(() => {
        apiService
            .getRelevantSubcategoryAttributeValues(subcategoryValue)
            .then((p) => setRelevantSubcategoryAttributeValues(p));
    }, [subcategoryValue]);

    return (
        <>
            <BaseCodingGroup
                attributes={groupedAttributes[AttributeGroup.BASE_CODING]}
                groupName={AttributeGroup.BASE_CODING}
            />
            <ProductFacts
                attributes={groupedAttributes[AttributeGroup.PRODUCT_FACTS]}
                groupName={AttributeGroup.PRODUCT_FACTS}
            />
            <EnumeratedSection
                attributes={groupedAttributes[AttributeGroup.POSITIONING]}
                attributeKeys={[
                    'age',
                    'eatingOccasion',
                    'gender',
                    'international',
                    'paleo',
                    'plantBased',
                    'raw',
                ]}
                groupName={AttributeGroup.POSITIONING}
                sectionName="positioning"
            />
            <EnumeratedSection
                attributes={groupedAttributes[AttributeGroup.LABEL_CLAIMS]}
                attributeKeys={labelClaimsAttributes}
                groupName={AttributeGroup.LABEL_CLAIMS}
                sectionName="labelClaims"
            />
            <AdvancedCoding
                attributes={groupedAttributes[AttributeGroup.ADVANCED_CODING]}
                groupName={AttributeGroup.ADVANCED_CODING}
                positioningConfiguration={positioningConfiguration}
            />
            <EnumeratedSection
                attributes={groupedAttributes[AttributeGroup.CERTIFICATIONS]}
                attributeKeys={certificationsAttributes}
                groupName={AttributeGroup.CERTIFICATIONS}
                sectionName="certifications"
            />
            <CustomRetailerContainer
                groupName={AttributeGroup.CUSTOM_RETAILER_INFORMATION}
                retailerTags={retailerTags}
            />
            <EnumeratedSection
                attributes={groupedAttributes[AttributeGroup.CHANNEL_FLAGS]}
                attributeKeys={channelFlagsAttributes}
                groupName={AttributeGroup.CHANNEL_FLAGS}
                sectionName="channelFlags"
            />
        </>
    );
};
