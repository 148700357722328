import { CheckboxTableFilter, Column } from '@spins/amethyst';
import { SnapshotQcReportDetailRecord } from '../../../models';
import { selectFilter } from './filters';

export const defaultVisibleColumns: Column<SnapshotQcReportDetailRecord>[] = [
    { Header: 'UPC', accessor: 'upc' },
    {
        Header: 'CHECK TYPE',
        accessor: 'checkType',
        Filter: CheckboxTableFilter,
        filter: selectFilter,
    },
    {
        Header: 'ATTRIBUTE',
        accessor: 'attribute',
        Filter: CheckboxTableFilter,
        filter: selectFilter,
    },
    { Header: 'DESCRIPTION', accessor: 'description' },
    { Header: 'GRP', accessor: 'grp', Filter: CheckboxTableFilter, filter: selectFilter },
    { Header: 'CATEGORY', accessor: 'category', Filter: CheckboxTableFilter, filter: selectFilter },
    {
        Header: 'SUBCATEGORY',
        accessor: 'subcategory',
        Filter: CheckboxTableFilter,
        filter: selectFilter,
    },
    { Header: 'COMPANY', accessor: 'company' },
    { Header: 'BRAND', accessor: 'brand' },
    { Header: 'UPC EAN 13', accessor: 'upcEan13' },
    { Header: 'INGREDIENT STATEMENT', accessor: 'ingredientStatement', width: 250 },
];

export const defaultHiddenColumns: Column<SnapshotQcReportDetailRecord>[] = [
    { Header: 'PERIOD', accessor: 'periodName', Filter: CheckboxTableFilter, filter: selectFilter },
    { Header: 'ANIMAL TYPE DESC', accessor: 'animalTypeDesc' },
    { Header: 'GRAIN FREE DESC', accessor: 'grainFreeDesc' },
    { Header: 'PALEO DESC', accessor: 'paleoDesc' },
    { Header: 'PLANT BASED DESC', accessor: 'plantBasedDesc' },
    { Header: 'VEGAN', accessor: 'vegan' },
    { Header: 'CERT PALEO - PALEO DESC', accessor: 'certpaleoPaleoDesc' },
    { Header: 'CERT PALEO - FRIENDLY DESC', accessor: 'certpaleoFriendlyDesc' },
    { Header: 'CERT PALEO - VEGAN DESC', accessor: 'certpaleoVeganDesc' },
    { Header: 'CERT PALEO - KETO DESC', accessor: 'certpaleoKetoDesc' },
    { Header: 'ING - SUGARS DESC', accessor: 'ingSugarsDesc' },
    { Header: 'ING - LACTOSE DESC', accessor: 'ingLactoseDesc' },
    { Header: 'ING - MSG DESC', accessor: 'ingMsgDesc' },
    { Header: 'ING - SULFITES DESC', accessor: 'ingSulfitesDesc' },
    { Header: 'ING - ANIMAL DERIVED DESC', accessor: 'ingAnimalDerivedDesc' },
    { Header: 'ING - CARRAGEENAN DESC', accessor: 'ingCarrageenanDesc' },
    { Header: 'ING - DAIRY DESC', accessor: 'ingDairyDesc' },
    { Header: 'ING - GELATIN DESC', accessor: 'ingGelatinDesc' },
    { Header: 'ING - GRAIN DESC', accessor: 'ingGrainDesc' },
    { Header: 'ING - HIGH FRUCTOSE CORN SYRUP DESC', accessor: 'ingHighFructoseCornSyrupDesc' },
    { Header: 'ING - AGAVE NECTAR DESC', accessor: 'ingAgaveNectarDesc' },
    { Header: 'ING - BROWN RICE SYRUP DESC', accessor: 'ingBrownRiceSyrupDesc' },
    { Header: 'ING - HONEY DESC', accessor: 'ingHoneyDesc' },
    { Header: 'NFP - CALORIES AMT', accessor: 'nfpCaloriesAmt' },
    { Header: 'NFP - SUGARS AMT', accessor: 'nfpSugarsAmt' },
    { Header: 'ING - EGGS FDA DESC', accessor: 'ingEggsFdaDesc' },
    { Header: 'ING - FISH ALLERGEN FDA DESC', accessor: 'ingFishAllergenFdaDesc' },
    { Header: 'ING - PEANUTS FDA DESC', accessor: 'ingPeanutsFdaDesc' },
    { Header: 'ING - SHELLFISH FDA DESC', accessor: 'ingShellfishFdaDesc' },
    { Header: 'ING - SOY FDA DESC', accessor: 'ingSoyFdaDesc' },
    { Header: 'ING - ARTIFICIAL SWEETENERS DESC', accessor: 'ingArtificialSweetenersDesc' },
    { Header: 'ING - CARAMEL COLOR DESC', accessor: 'ingCaramelColorDesc' },
    { Header: 'ING - COCHINEAL CARMINE DESC', accessor: 'ingCochinealCarmineDesc' },
    { Header: 'ING - ERYTHRITOL DESC', accessor: 'ingErythritolDesc' },
    { Header: 'ING - MANNITOL DESC', accessor: 'ingMannitolDesc' },
    { Header: 'ING - MILK FDA DESC', accessor: 'ingMilkFdaDesc' },
    { Header: 'ING - NATURAL SWEETENERS DESC', accessor: 'ingNaturalSweetenersDesc' },
    { Header: 'ING - SORBITOL DESC', accessor: 'ingSorbitolDesc' },
    { Header: 'ING - SUGAR ALCOHOLS DESC', accessor: 'ingSugarAlcoholsDesc' },
    { Header: 'NFP - DIETARY FIBER VALUE', accessor: 'nfpDietaryFiberValue' },
    { Header: 'NFP - SERVING SIZE METRIC VALUE', accessor: 'nfpServingSizeMetricValue' },
    { Header: 'NFP - SERVING SIZE METRIC UOM', accessor: 'nfpServingSizeMetricUom' },
    { Header: 'NFP - TOTAL CARBOHYDRATE VALUE', accessor: 'nfpTotalCarbohydrateValue' },
    { Header: 'ING - WHOLE PEAR DESC', accessor: 'ingWholePearDesc' },
    { Header: 'ING - ARTIFICIAL COLORS DESC', accessor: 'ingArtificialColorsDesc' },
    { Header: 'ING - ARTIFICIAL FLAVORS DESC', accessor: 'ingArtificialFlavorsDesc' },
    { Header: 'DIET - FODMAP DESC', accessor: 'dietFodmapDesc' },
    { Header: 'DIET - KETO DESC', accessor: 'dietKetoDesc' },
    { Header: 'DIET - PALEO DESC', accessor: 'dietPaleoDesc' },
    { Header: 'DIET - PESCATARIAN DESC', accessor: 'dietPescatarianDesc' },
    { Header: 'DIET - PLANT BASED DESC', accessor: 'dietPlantBasedDesc' },
    { Header: 'ING - PRESERVATIVES', accessor: 'ingPreservatives' },
    { Header: 'ING - ARTIFICIAL PRESERVATIVES DESC', accessor: 'ingArtificialPreservativesDesc' },
    { Header: 'DIET - VEGAN DESC', accessor: 'dietVeganDesc' },
    { Header: 'DIET - VEGETARIAN DESC', accessor: 'dietVegetarianDesc' },
    { Header: 'DIET - WHOLE 30 FRIENDLY DESC', accessor: 'dietWhole_30FriendlyDesc' },
    { Header: 'PRODUCT TYPE DESC', accessor: 'productTypeDesc' },
    { Header: 'ACTIVE XMP', accessor: 'activeXmp' },
    { Header: 'PRV LBL', accessor: 'prvLbl' },
    { Header: 'CALORIES VALUE', accessor: 'caloriesValue' },
    { Header: 'SUGARS VALUE', accessor: 'sugarsValue' },
    { Header: 'DIETARY FIBER VALUE', accessor: 'dietaryFiberValue' },
    { Header: 'TOTAL CARBOHYDRATE VALUE', accessor: 'totalCarbohydrateValue' },
    { Header: 'SUGAR ALCOHOL', accessor: 'sugarAlcohol' },
    { Header: 'SERVING SIZE VALUE', accessor: 'servingSizeValue' },
    { Header: 'SERVING SIZE UNIT OF MEASURE', accessor: 'servingSizeUnitOfMeasure' },
];

export const hiddenColumnKeys: string[] = defaultHiddenColumns
    .filter((col) => col.accessor && typeof col.accessor === 'string')
    // The table types in react-table/Amethyst need some love
    // Accessor has been filtered to only strings, so assert type of string
    .map((col) => col.accessor as string);

export const detailColumns: Column<SnapshotQcReportDetailRecord>[] = [
    ...defaultVisibleColumns,
    ...defaultHiddenColumns,
];
