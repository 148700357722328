import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import LostSloth from './LostSloth.jpg';

const NotFoundSloth = ({
    className,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): ReactElement => (
    <div className={className}>
        <h1>Oh No!</h1>
        <p>
            It looks like you&#39;ve <em style={{ marginRight: '1px' }}>sloth</em>st your way!
        </p>
        <img src={LostSloth} alt="a sloth you probably wouldn't get good directions from" />
    </div>
);

export const NotFoundPage = styled(NotFoundSloth)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
