import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Body,
    CellType,
    Column,
    Palette,
    SimpleTable,
    SmallChevronDown,
    SmallChevronUp,
    useFlexLayout,
    usePagination,
    useSortBy,
    useTable,
} from '@spins/amethyst';
import styled from 'styled-components/macro';
import { BrandCategoryResult } from '../../../models';
import { apiService } from '../../../services/ApiService';

interface MfrCodeProps {
    mfrCode: string | undefined;
}

const StyledMfrCodeHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledButton = styled.button`
    all: unset;
    margin-right: 8px;
    cursor: pointer;
`;

export const HeaderLine = styled.hr`
    color: ${Palette.neutral[40].hex};
    margin: 8px 24px 24px 0;
`;

const StyledTableContainer = styled.div`
    margin: 18px 24px 20px 0;
`;

export const MfrCodeTable: FC<MfrCodeProps> = ({ mfrCode }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [results, setResults] = useState<BrandCategoryResult[]>([]);

    const toggleIsExpanded = useCallback(
        (e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
        },
        [isExpanded],
    );
    const columns: Array<Column<BrandCategoryResult>> = useMemo(
        () => [
            { Header: 'Brand', accessor: 'brand' },
            { Header: 'Category', accessor: 'category' },
            {
                Header: 'UPC Count',
                id: 'count',
                accessor: 'count',
                cellType: CellType.Number,
            },
        ],
        [],
    );
    const tableInstance = useTable<BrandCategoryResult>(
        {
            columns,
            initialState: {
                pageSize: 5,
                sortBy: [
                    {
                        id: 'count',
                        desc: true,
                    },
                ],
            },
            data: results,
        },
        useFlexLayout,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        async function doSearch(): Promise<void> {
            if (!mfrCode) {
                setResults([]);
                return;
            }
            try {
                const result = await apiService.manufacturerCodeSearch(mfrCode);
                setResults(result.brandCategoryResults);
            } catch (e) {
                setResults([]);
            }
        }
        doSearch();
    }, [mfrCode]);

    return (
        <>
            <StyledMfrCodeHeader>
                <Body>MFR Code search results</Body>
                <StyledButton onClick={toggleIsExpanded}>
                    {isExpanded ? <SmallChevronUp /> : <SmallChevronDown />}
                </StyledButton>
            </StyledMfrCodeHeader>
            {isExpanded ? (
                <StyledTableContainer>
                    <SimpleTable instance={tableInstance} />
                </StyledTableContainer>
            ) : (
                <HeaderLine />
            )}
        </>
    );
};
