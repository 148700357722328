import { Attribute } from './Attribute';

export enum AttributeGroup {
    OTHER = 'Other',
    BASE_CODING = 'Base Coding',
    PRODUCT_FACTS = 'Product Facts',
    POSITIONING = 'Positioning',
    LABEL_CLAIMS = 'Label Claims',
    CERTIFICATIONS = 'Certifications',
    INGREDIENTS = 'Ingredients',
    ADVANCED_CODING = 'Advanced Coding',
    CUSTOM_RETAILER_INFORMATION = 'Custom Retailer Product Information',
    CHANNEL_FLAGS = 'Channel Flags',
}

export interface AttributeGroupProps {
    attributes: Record<string, Attribute>;
    className?: string;
    groupName: AttributeGroup;
}
