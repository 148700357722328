import { BodyHeavy, Button, TrashAlt } from '@spins/amethyst';
import { FieldArray, useFormikContext } from 'formik';
import React, { Fragment, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components/macro';
import { AttributeName } from '../../constants/attributeNames';
import { AttributeGroupProps, AttributeType } from '../../models';
import { apiService } from '../../services/ApiService';
import { AttributeGroupSection } from './AttributeGroupSection';
import { Enumerated, FreeText } from './fields';

export type RetailerValues = {
    [K in 'brand' | 'description' | 'tag']: string;
};

const DeleteButton = styled(Button)`
    :not(:disabled) {
        min-width: 42px;
        padding: 8px;
    }
    & > svg {
        margin-right: initial;
    }
`;
DeleteButton.defaultProps = {
    size: 'small',
    type: 'button',
    icon: TrashAlt,
    title: 'Delete Retailer Info',
    buttonType: 'destructive',
};

const StyledSection = styled.section`
    display: flex;
    align-items: center;
`;
export const CustomRetailerContainer = ({
    className = '',
    groupName,
    retailerTags,
}: {
    retailerTags: string[];
} & Omit<AttributeGroupProps, 'attributes'>): ReactElement => {
    const { getFieldProps } = useFormikContext();
    const { upc } = useParams<{ upc: string }>();
    const customRetailers: RetailerValues[] = getFieldProps<RetailerValues[]>('customRetailers')
        .value;

    const sortedRetailerTags = useMemo(() => {
        return retailerTags
            .sort((a, b) => Number(a) - Number(b))
            .map((tag) => ({
                value: tag,
                label: tag,
                isDisabled: customRetailers.some((cr) => cr.tag === tag),
            }));
    }, [retailerTags, customRetailers]);

    return (
        <FieldArray name="customRetailers">
            {({ remove, push }) => (
                <AttributeGroupSection
                    className={className}
                    groupName={groupName}
                    titleAppend={
                        <Button
                            onClick={() => {
                                push({ brand: '', description: '', tag: '' });
                            }}
                            type="button"
                            buttonType="primary"
                            size="small"
                            {...({
                                style: { marginLeft: '16px', display: 'inline-block' },
                            } as any)}
                        >
                            Add
                        </Button>
                    }
                >
                    {/* TODO This ideally needs to be insert instead of push */}
                    <Fragment>
                        {customRetailers.map((info, index) => {
                            return (
                                <>
                                    <StyledSection>
                                        <BodyHeavy style={{ flex: 1 }} className={'title'}>
                                            Custom Retailer Info {info.tag}
                                        </BodyHeavy>
                                        <DeleteButton
                                            onClick={() => {
                                                // This always returns 200, even if it doesn't exist, so we'll remove it
                                                apiService
                                                    .deleteRetailer(upc, info.tag)
                                                    .then(() => remove(index));
                                            }}
                                        />
                                    </StyledSection>
                                    <Enumerated
                                        attribute={{
                                            name: AttributeName.RETAILER_TAG,
                                            description: info.tag!,
                                            type: AttributeType.ENUMERATED,
                                        }}
                                        name={`customRetailers.${index}.tag`}
                                        className={'form-spacing'}
                                        options={sortedRetailerTags}
                                    />
                                    <FreeText
                                        attribute={{
                                            description: '',
                                            type: AttributeType.FREE_TEXT,
                                            name: AttributeName.PRIVATE_LABEL_CUSTOM_DESCRIPTION,
                                        }}
                                        name={`customRetailers.${index}.description`}
                                    />
                                    <FreeText
                                        attribute={{
                                            description: '',
                                            type: AttributeType.FREE_TEXT,
                                            name: AttributeName.PRIVATE_LABEL_CUSTOM_BRAND,
                                        }}
                                        name={`customRetailers.${index}.brand`}
                                    />
                                </>
                            );
                        })}
                    </Fragment>
                </AttributeGroupSection>
            )}
        </FieldArray>
    );
};
