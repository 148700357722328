import React, { ComponentProps, ReactElement } from 'react';
import { AttributeGroupProps } from '../../models';
import { AttributeGroupSection } from './AttributeGroupSection';
import { BrandPositionAndPositioningGroup } from './BrandPositionAndPositioningGroup';
import { Enumerated } from './fields/Enumerated';

interface AdvancedCodingProps extends AttributeGroupProps {
    positioningConfiguration: ComponentProps<
        typeof BrandPositionAndPositioningGroup
    >['positioningConfiguration'];
}

export const AdvancedCoding = ({
    attributes,
    className = '',
    groupName,
    positioningConfiguration,
}: AdvancedCodingProps): ReactElement => {
    if (attributes === undefined || Object.keys(attributes).length === 0) {
        return <></>;
    }
    const positioningGroup = attributes['positioningGroup'];
    const brandPosition = attributes['brandPosition'];
    const healthFocus = attributes['healthFocus'];
    return (
        <AttributeGroupSection className={className} groupName={groupName}>
            {healthFocus && (
                <Enumerated
                    attribute={healthFocus}
                    className={'form-spacing'}
                    name="advancedCoding.healthFocus"
                />
            )}
            {positioningGroup && brandPosition && (
                <BrandPositionAndPositioningGroup
                    positioningGroup={positioningGroup}
                    brandPosition={brandPosition}
                    positioningConfiguration={positioningConfiguration}
                />
            )}
        </AttributeGroupSection>
    );
};
