import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { getCurrentAccessToken } from '../auth0';
import { NetworkError } from '../NetworkError';
import { RawProductAttribute } from './RawProductAttribute';

class RawProductAttributesApiService {
    private axios: AxiosInstance;

    public constructor() {
        this.axios = Axios.create({
            baseURL: window.REACT_APP_CURVBALL_API_URL,
            withCredentials: false,
            headers: { 'Access-Control-Allow-Origin': '*' },
        });
        this.axios.interceptors.request.use((config) =>
            Object.assign(config, {
                headers: {
                    Authorization: 'Bearer ' + getCurrentAccessToken(),
                    ...config.headers,
                },
            }),
        );
    }

    public getRawProductAttributes(upc: string): Promise<RawProductAttribute[]> {
        return this.axios
            .get<RawProductAttribute[]>(`/product/${upc}/raw-attributes`)
            .then((response: AxiosResponse<RawProductAttribute[]>) => {
                return response.data;
            })
            .catch((error) => {
                throw new NetworkError(error);
            });
    }
}

export const rawProductAttributesApiService = new RawProductAttributesApiService();
