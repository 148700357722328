import { Button } from '@spins/amethyst';
import React, { Component, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';
import { CardPage } from '../../components/CardPage';
import { apiService } from '../../services/ApiService';
import { parseUpcs } from '../../services/BulkEditCsvParser';
import { RadioButton } from './RadioButton';

export interface AuditState {
    asOfDate?: Date;
    compareDate1?: Date;
    compareDate2?: Date;
    upcs: string[];
    isAsOfSelected: boolean;
}

export interface AuditProps {
    readonly asOfDate?: Date;
}

export const Block = styled.div`
    display: block;
    margin-bottom: 16px;
`;

export const ContainerBottom32px = styled.div`
    margin-bottom: 32px;
`;

export const ContainerBottom24px = styled.div`
    margin-bottom: 24px;
`;

export const ContainerLabelBottom8px = styled.div`
    margin-bottom: 8px;
`;

const AuditInputContainer = styled.div`
    margin-top: 36px;
`;

export class Audit extends Component<AuditProps, AuditState> {
    public static defaultProps = {
        asOfDate: new Date(),
    };

    private constructor(props: AuditProps) {
        super(props);
        this.state = {
            upcs: [],
            asOfDate: props.asOfDate,
            compareDate1: undefined,
            compareDate2: undefined,
            isAsOfSelected: true,
        };
    }

    public render(): ReactElement {
        return (
            <CardPage title={'Download Audit File'}>
                <AuditInputContainer>
                    <ContainerBottom32px>
                        <input
                            name="fileInput"
                            type="file"
                            accept="text/csv"
                            onChange={this.handleFileChange}
                        />
                    </ContainerBottom32px>
                    <ContainerBottom24px>
                        <ContainerLabelBottom8px>
                            <RadioButton
                                id="radiobuttonAsOf"
                                onChange={this.handleOptionChange}
                                value="AS_OF"
                                isSelected={this.state.isAsOfSelected}
                            >
                                {' '}
                                As of
                            </RadioButton>
                        </ContainerLabelBottom8px>
                        <Block>
                            <DatePicker
                                id="datepickerAsOf"
                                selected={this.state.asOfDate}
                                onChange={this.handleAsOfDateChange}
                                dateFormat={'MM/dd/yy hh:mm a'}
                                showTimeSelect={true}
                                disabled={!this.state.isAsOfSelected}
                            />
                        </Block>
                    </ContainerBottom24px>
                    <ContainerBottom24px>
                        <ContainerLabelBottom8px>
                            <RadioButton
                                id="radiobuttonCompare"
                                onChange={this.handleOptionChange}
                                value="COMPARE"
                                isSelected={!this.state.isAsOfSelected}
                            >
                                Compare
                            </RadioButton>
                        </ContainerLabelBottom8px>
                        <Block>
                            <DatePicker
                                id="datepickerCompare1"
                                selected={this.state.compareDate1}
                                onChange={this.handleCompareDate1Change}
                                dateFormat={'MM/dd/yy hh:mm a'}
                                showTimeSelect={true}
                                disabled={this.state.isAsOfSelected}
                            />
                            &nbsp; to &nbsp;
                            <DatePicker
                                id="datepickerCompare2"
                                selected={this.state.compareDate2}
                                onChange={this.handleCompareDate2Change}
                                dateFormat={'MM/dd/yy hh:mm a'}
                                showTimeSelect={true}
                                disabled={this.state.isAsOfSelected}
                            />
                        </Block>

                        <Button
                            type="button"
                            onClick={this.handleDownloadClick}
                            disabled={
                                this.state.upcs.length === 0 ||
                                (this.state.isAsOfSelected &&
                                    (this.state.asOfDate === null ||
                                        this.state.asOfDate === undefined)) ||
                                (!this.state.isAsOfSelected &&
                                    (this.state.compareDate1 === null ||
                                        this.state.compareDate1 === undefined ||
                                        this.state.compareDate2 === null ||
                                        this.state.compareDate2 === undefined))
                            }
                        >
                            Download
                        </Button>
                    </ContainerBottom24px>
                </AuditInputContainer>
            </CardPage>
        );
    }

    private handleAsOfDateChange = (date: Date): void => {
        this.setState({
            asOfDate: date,
        });
    };

    private handleCompareDate1Change = (date: Date): void => {
        this.setState({
            compareDate1: date,
        });
    };

    private handleCompareDate2Change = (date: Date): void => {
        this.setState({
            compareDate2: date,
        });
    };

    private handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!event.target.files) {
            return;
        }

        parseUpcs(event.target.files[0]).then((upcs) => {
            this.setState({ upcs: upcs });
        });
    };

    private handleOptionChange = (value: string): void => {
        this.setState({ isAsOfSelected: value === 'AS_OF' });
    };

    private handleDownloadClick = (): void => {
        const { upcs, asOfDate, compareDate1, compareDate2, isAsOfSelected } = this.state;
        if (isAsOfSelected) {
            apiService.getSnapshotForUpcsAtDate(upcs, asOfDate!);
        } else if (compareDate1 !== undefined && compareDate2 !== undefined) {
            apiService.getSnapshotForUpcsForTwoDates(upcs, compareDate1, compareDate2);
        }
    };
}
