import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Header1, NotificationType } from '@spins/amethyst';
import _ from 'lodash';
import * as yup from 'yup';
import { AttributeName } from '../../constants/attributeNames';
import {
    DynamicMigration,
    DynamicMigrationAction,
    DynamicMigrationTable,
    LookupValue,
    ProductHierarchyRecord,
} from '../../models';
import { apiService } from '../../services/ApiService';
import parseCsvFile from '../../utilities/FileUtils';
import { FileUpload, Loader, SimpleToast } from '../../components/';

// CSV rows spitball --
//
// action,subcategoryTag,attributeName,valueTag

const buildCsvRowsSchema = (hierarchy: ProductHierarchyRecord[], lookupValues: LookupValue[]) => {
    const attributeNames = _.uniq(lookupValues.map((lv) => lv.name as AttributeName));
    const valueTagsByAttributeName = _(lookupValues)
        .groupBy((lv) => lv.name)
        .mapValues((items) => items.map((lv) => lv.tag))
        .value();
    const subcategoryTags = hierarchy.map((h) => h.subcategoryTag);

    return yup
        .array(
            yup
                .object({
                    // don't worry about "update" action for now to keep things simple
                    action: yup
                        .mixed<DynamicMigrationAction>()
                        .oneOf([DynamicMigrationAction.add, DynamicMigrationAction.retire]),
                    subcategoryTag: yup.string().required().oneOf(subcategoryTags),
                    attributeName: yup.mixed<AttributeName>().oneOf(attributeNames),
                    valueTag: yup
                        .string()
                        .defined()
                        .when(
                            'attributeName',
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            (name, schema) => {
                                const currentTags = valueTagsByAttributeName[name];
                                return currentTags ? schema.oneOf(currentTags) : schema;
                            },
                        ),
                })
                .defined(),
        )
        .defined();
};

export interface DynamicMigrationToast {
    type: NotificationType;
    message: string;
}

interface DynamicMigrationCsvRow {
    action: DynamicMigrationAction;
    subcategoryTag: string;
    attributeName: AttributeName;
    valueTag: string;
}

export const DynamicMigrationCsvProtoPage: FC = () => {
    const [hierarchy, setHierarchy] = useState<ProductHierarchyRecord[]>([]);
    const [lookupValues, setLookupValues] = useState<LookupValue[]>([]);
    const [csvError, setCsvError] = useState<string>();
    const [csvData, setCsvData] = useState<DynamicMigrationCsvRow[]>([]);
    const [toastStatus, setToastStatus] = useState<DynamicMigrationToast[]>([]);

    const csvSchema = useMemo(() => buildCsvRowsSchema(hierarchy, lookupValues), [
        hierarchy,
        lookupValues,
    ]);

    const handleSave = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const dynamicMigrations: DynamicMigration[] = csvData.map((csv) => {
            switch (csv.action) {
                case DynamicMigrationAction.add:
                    return {
                        table: DynamicMigrationTable.SUBCATEGORY_ALLOWED_VALUES,
                        action: DynamicMigrationAction.add,
                        newValues: {
                            subcategoryTag: csv.subcategoryTag,
                            attributeName: csv.attributeName,
                            valueTag: csv.valueTag,
                        },
                    };
                case DynamicMigrationAction.retire:
                    return {
                        table: DynamicMigrationTable.SUBCATEGORY_ALLOWED_VALUES,
                        action: DynamicMigrationAction.retire,
                        oldValues: {
                            subcategoryTag: csv.subcategoryTag,
                            attributeName: csv.attributeName,
                            valueTag: csv.valueTag,
                        },
                    };
                default:
                    throw new Error('Update actions not yet supported through this interface');
            }
        });

        apiService
            .addDynamicMigrations(dynamicMigrations)
            .then(() => setToastStatus([{ type: NotificationType.Success, message: 'Success!' }]))
            .catch((error) =>
                setToastStatus([
                    { type: NotificationType.Error, message: error.response.data.toString() },
                ]),
            );
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        if (!file) {
            return;
        }

        parseCsvFile(file)
            .then((parsedFile) => {
                csvSchema.validateSync(parsedFile.data, { abortEarly: false });
                const data: DynamicMigrationCsvRow[] = parsedFile.data.map((datum: any) => {
                    const typedDatum = datum as {
                        action: string;
                        subcategoryTag: string;
                        attributeName: string;
                        valueTag: string;
                    };
                    return {
                        action:
                            DynamicMigrationAction[
                                typedDatum.action as keyof typeof DynamicMigrationAction
                            ],
                        subcategoryTag: typedDatum.subcategoryTag,
                        attributeName:
                            AttributeName[typedDatum.attributeName as keyof typeof AttributeName],
                        valueTag: typedDatum.valueTag,
                    };
                });
                setCsvData(data);
            })
            .catch((err) => {
                console.error(err);
                setCsvError(err.toString());
            });
    };

    useEffect(() => {
        apiService.getProductHierarchy().then(setHierarchy);
        apiService.getLookupValues().then(setLookupValues);
    }, []);

    return (
        <form style={{ margin: '32px' }} onSubmit={handleSave}>
            {toastStatus &&
                toastStatus.map(({ type, message }, i) => (
                    <SimpleToast key={i} type={type} message={message} />
                ))}
            <div>
                <Header1>Dynamic Migrations</Header1>
            </div>
            <Loader isLoading={hierarchy.length === 0 || lookupValues.length === 0}>
                <FileUpload handleFileChange={handleFileChange} error={csvError} />
            </Loader>
            <div>
                <Button
                    type={'submit'}
                    disabled={csvError !== null && csvError !== undefined && csvError!.length > 0}
                >
                    Upload
                </Button>
            </div>
        </form>
    );
};
