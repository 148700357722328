import { Palette } from '@spins/amethyst';
import styled from 'styled-components/macro';

// TODO: This would ideally be replaced by the Amethyst Input component
export const Input = styled.input<{ hasError?: boolean }>`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
    border-radius: 3px;
    border: 1px solid ${Palette.neutral[60].hex};
    font-size: 14px;
    height: 38px;
    outline: 0;
    padding: 0 16px;
    width: 100%;
    &:focus {
        border: 1px solid rgb(0, 115, 108);
        box-shadow: 0 0 0 0.0625rem rgb(0, 115, 108);
    }
    ${(props) =>
        props.hasError &&
        `
    border: 1px solid ${Palette.red[80].hex};
    box-shadow: 0 0 0 0.0625rem rgb(0, 115, 108);`}

    &:disabled {
        border-radius: 3px;
        border: 1px solid ${Palette.neutral[50].hex};
        background-color: ${Palette.neutral[15].hex};
    }
`;
