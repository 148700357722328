import { Row, FilterValue } from 'react-table';

export const selectFilter = <T extends object = {}>(
    rows: Row<T>[],
    columnId: string[],
    filteredItems: FilterValue,
): Row<T>[] => {
    const mapFilteredItems = filteredItems.map((fi: FilterValue) => fi.value);
    return rows.filter((r) => mapFilteredItems.includes(r.values[columnId[0]]));
};

export const globalFilter = (
    rows: Row<any>[],
    _columns: string,
    filterValue: string,
): Row<any>[] => {
    const desiredUpcs: string[] = filterValue.split(/,\n|\n,|\n|, |,/);
    if (desiredUpcs.length === 1 && desiredUpcs[0] === '') {
        return rows;
    }
    return rows.filter((row: Row<any>) => desiredUpcs.includes(row.values['upc']));
};
